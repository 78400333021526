import React from "react";
import {CircularProgress } from '@mui/material';
import Loading from './loading';

const ContentLoading = () => (
    <div className="pageView">
        <div className={"pageWrapper"}>
            <Loading/>
        </div>
    </div>

);

export default ContentLoading;
