import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { MyTextInput, MySelect, MyTextArea } from "../../../components/formik/FormikFields"; // Assuming the path is correct
import postJiraIssue from "../../../actions/postJiraIssue";

const validationSchema = Yup.object({
    name: Yup.string()
        .required("Name is required")
        .min(2, "Name must be at least 2 characters"),
    email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    phone: Yup.string()
        .required("Phone number is required")
        .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"),
    helpType: Yup.string().required("Please select the type of support you need"),
    description: Yup.string()
        .required("Description is required")
        .min(10, "Description must be at least 10 characters long")
});

const helpOptions = [
    { value: "Subscriptions", label: "Subscriptions" },
    { value: "Order", label: "Order" },
    { value: "Payment Method", label: "Payment Method" },
    { value: "Website Issue", label: "Website Issue" },
    { value: "Other", label: "Other" }
];

function AddSupportRequests(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const projectKey = process.env.REACT_APP_JIRA_PROJECT_KEY;
    const userId = props.id;
    const userEmail = props.details.email;
    const SummaryCancelRequest = `Support Request, for ${userEmail}-${userId}`;
    const jiraIssueType = props.jiraIssueType;
    const jiraSearchString = props.jiraSearchString;
    const firstName = props.details.first_name;
    const lastName = props.details.last_name;
    const usersName = firstName + " " + lastName;

    const { subscriptionId, helpType, description } = location.state || {};

    const initialValues = {
        name: usersName || "",
        email: userEmail || props.details?.email || "",
        phone: props.details?.billing.phone || "",
        helpType: helpType || "", // Pre-fill helpType from navigation state if available
        description: description || "" // Pre-fill description from navigation state if available
    };

    return (
        <div className="pageView">
            <div className="pageWrapper">
                <Typography variant="h4" className={"pageTitle"}>
                    <div>
                        <button className={"backButton"} onClick={() => navigate(-1)}>
                            <ArrowBack fontSize={"medium"} />{" "}
                            <span className={"backButtonText"}>Go Back</span>
                        </button>
                    </div>
                    Add Support Request
                </Typography>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        dispatch(postJiraIssue(
                            userId,
                            projectKey,
                            SummaryCancelRequest,
                            jiraIssueType,
                            jiraSearchString,
                            navigate,
                            values.name,
                            values.email,
                            values.phone,
                            values.helpType,
                            values.description
                        ));
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form className="formContainerSupportRequest">
                            <MyTextInput
                                label="Name"
                                name="name"
                                required
                                fieldName="Name"
                                description="Please enter your full name."
                            />
                            <MyTextInput
                                label="Email"
                                name="email"
                                type="email"
                                required
                                fieldName="Email"
                                description="Enter your email address."
                            />
                            <MyTextInput
                                label="Phone"
                                name="phone"
                                required
                                fieldName="Phone"
                                description="Enter your 10-digit phone number."
                            />
                            <MySelect
                                label="What do you want help with?"
                                name="helpType"
                                required
                                fieldName="Help Type"
                                description="Select the type of support you need."
                            >
                                <option value="">Select an option</option>
                                {helpOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </MySelect>
                            <MyTextArea
                                label="Support Request Description"
                                name="description"
                                required
                                rows={4}
                                fieldName="Description"
                                description="Provide more details about the issue or request."
                            />
                            <button type="submit" className={"wooButtons"}>
                                Submit Request
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default AddSupportRequests;