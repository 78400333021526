/**
 * Root App Functional Component to take Routes in the app
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import React, {useEffect, useState, useCallback} from "react";
// import "./styles/style.scss"
import AppWrapper from "./AppWrapper"
import { useAuth0 } from '@auth0/auth0-react';
import Loading from "./components/loading";
import ProtectedComponent from "./auth/protected-route";

export default function App(){

    const { isLoading, isAuthenticated, user } = useAuth0();

    const [queryParams, setQueryParams] = useState(null);

    const [userId, setUserId] = useState(0);
    const [isAdmin, SetIsAdmin] = useState(false);

    const processParams = useCallback((params) => {
        const newQueryParams = {};

        for (const [key, value] of params.entries()) {
            if (key === 'user_switched' || key === 'user_id' ) {
                newQueryParams[key] = value;
            }
        }

        if (newQueryParams.hasOwnProperty('user_switched') && newQueryParams.hasOwnProperty('user_id')) {
            setQueryParams(newQueryParams);
        }
    }, []);

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        processParams(params);

    }, [processParams]);

    useEffect(() => {
        if (queryParams !== null) {
            const jsonString = JSON.stringify(queryParams);
            const encodedString = btoa(jsonString);

            sessionStorage.setItem('switchToQueryParams', encodedString);
        }
    }, [queryParams]);

    useEffect(() => {
        if(user){
            const {name, email, picture ,sub} = user;
            const parts = sub.split("|");
            const usersId = parts[parts.length -1];
            const id = parseInt(usersId,10);
            setUserId(id);
            SetIsAdmin(sub.includes('administrator'));
        }
    }, [user]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div>
            <ProtectedComponent component={AppWrapper} userId={userId} isAdmin={isAdmin} queryParams={queryParams} />
        </div>
    )
}
