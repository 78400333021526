/**
 * getPaymentMethods Api is used to GET Saved Payment Methods List Details from WooCommerce for the user.
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import axios from 'axios'
import * as actions from './actionTypes'

/**
 * GET Payment Methods List details from WooCommerce REST API.
 * @endpoint "https://devc.l4sb.com/wp-json/wc/v3/payment-methods/:id  Middleware customization and documentation.
 *
 * @return JSON Payment Methods List Object .
 */
const getPaymentMethods = (id) => async (dispatch, getState) => {

  dispatch({ type: actions.GET_PAYMENT_METHODS_REQUEST })
  try {
    const response = await axios.get(
      '/paymentmethods', {
        params: {
          customerId: id
        }
      }
    )
    let getDefaultIndex;
    const { cc: newCreditCards, pp: newPaypalPayments } = response.data;
    newCreditCards && newCreditCards.map((item, index) => {
      const { is_default } = item;
      if (is_default) {
        getDefaultIndex = index;
      }
    });

    dispatch({ type: actions.GET_PAYMENT_METHODS_SUCCESS, payload: response.data})
  } catch (error) {
    dispatch({ type: actions.GET_PAYMENT_METHODS_FAILURE, error })
  }
}

export default getPaymentMethods;
