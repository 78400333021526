/**
 * getOrders Api is used to GET Orders List Details from WooCommerce
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import axios from 'axios'
import * as actions from './actionTypes'

/**
 * GET Orders List details from WooCommerce REST API.
 * @endpoint "/orders"  Middleware customization and documentation.
 * @param id Customer Login userID to get user specific details.
 *
 * @return JSON Orders List Object .
 */
const getOrders = (id) => async (dispatch, getState) => {

  dispatch({ type: actions.GET_ORDERS_REQUEST })
  try {
    const response = await axios.get(
      '/orderslist', {
        params: {
          customer: id
        }
      }
    )
    dispatch({ type: actions.GET_ORDERS_SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: actions.GET_ORDERS_FAILURE, error })
  }
}

export default getOrders;
