/**
 * editBillingAddress API is used to EDIT Billing Address
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */
import * as actions from './actionTypes'
import axios from 'axios'
import {toastr} from "react-redux-toastr";
import { toast, ToastContainer } from 'react-toastify';

/**
 * EDIT Billing Address details from WooCommerce REST API.
 *
 * @endpoint "/customers/billingAddress"  Middleware customization and documentation.
 * @param id Customer Login userID to get user specific details.
 * @param lastName
 * @param firstName
 * @param email
 * @param phone
 * @param company
 * @param address1
 * @param address2
 * @param city
 * @param state
 * @param postcode
 * @param country
 * @return JSON User Details Object .
 */
const editBillingAddress = (billingData,lastName, firstName, email, id, phone, company,
  address1, address2, city, state, postcode, country,navigate) => async (dispatch, getState) => {

  const data = {
    customerId: id,
    firstName: firstName,
    lastName: lastName,
    email: email,
    phone: phone,
    company: company,
    address1: address1,
    address2: address2,
    city: city,
    state: state,
    postcode: postcode,
    country: country
  }

  dispatch({ type: actions.EDIT_BILLING_ADDRESS_REQUEST })
  try {
    const response = await axios.put(
      '/customers/billingAddress', null ,{
        params: {
          customerId: id,
          firstName: firstName,
          lastName: lastName,
          email: email,
          phone: phone,
          company: company,
          address1: address1,
          address2: address2,
          city: city,
          state: state,
          postcode: postcode,
          country: country
        }
      }
    )
    dispatch({ type: actions.EDIT_BILLING_ADDRESS_SUCCESS, payload: response.data })

    const userDetails = response.data;
    const { billing } = userDetails;
    if(billingData && billing && billingData !== billing){
      // toastr.success('Successfully Changed', 'Updated Address Info.');
      toast.success('Successfully Updated Address Info.');
      navigate(-1);
    }else{
      // toastr.error('Failed to change Address, please try again!');
      toast.error('Failed to change Address, please try again!');
    }
  } catch (error) {
    dispatch({ type: actions.EDIT_BILLING_ADDRESS_FAILURE, error })
    // toastr.error('Failed to change Address, please try again!');
    toast.error('Failed to change Address, please try again!');
  }
}

export default editBillingAddress;