import "./order.css"
// import "../../styles/style.scss";
import * as React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {ArrowBack, Call, Email} from "@mui/icons-material";
import parse from 'html-react-parser';
import formatCurrency from "../../functions/formatCurrency";
import { Box, Typography } from '@mui/material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import VerifiedIcon from '@mui/icons-material/Verified';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CancelIcon from '@mui/icons-material/Cancel';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint,{ useReactToPrint } from 'react-to-print';
import { useRef } from 'react';

import {PrintOrderDetails4} from "../../printableOrders/printOrderDetails4";

import {MyExpansionChip,MyChip,getStatusIcon,statusIconMap,getStatusColor,statusColorMap,useStyles} from "../orderList/ordersList";

export const relatedSubs = (subList,id,parentExist,subs) => {
    const hasSubscription = parentExist.includes(true);

    const subStatusColorMap = {
        ACTIVE: '#c6e1c6',
        EXPIRED: '#bd94ae',
        PENDING: '#c6e1c6',
        ONHOLD: '#f8dda7',
        CANCELLED: '#e5e5e5'
    };

    const getSubStatusColor = (status) => {
        return subStatusColorMap[status] || 'black'; // return black as default color if status not in map
    };

    const subStatusIconMap = {
        ACTIVE: VerifiedIcon,
        PENDING: AccessTimeFilledIcon,
        EXPIRED: HighlightOffIcon,
        ONHOLD: RemoveCircleIcon,
        CANCELLED: CancelIcon,
    }

    const getSubStatusIcon = (status) => {
        return subStatusIconMap[status] || AcUnitIcon;
    }

    return subs !== 'N/A' ? (
        <div>
            <header>
                <h3 align={"left"}>Related Subscriptions</h3>
            </header>
            <table className={"wooDetailsTable"}>
                <thead>
                <tr>
                    <th className={"tableHeaderData"}>
                        ID
                    </th>
                    <th className={"tableHeaderData"}>
                        Service(s)
                    </th>
                    <th className={"tableHeaderData"}>
                        Status
                    </th>
                    <th className={"tableHeaderData"}>
                        Date Created
                    </th>
                    <th className={"tableHeaderData"}>
                        <span>&nbsp;</span>
                    </th>
                </tr>
                </thead>
                <tbody>
                {subList.map((item)=>{
                    const {parent_id, id: subId,line_items,status,date_created} = item;
                    const dateForSub = new Date(date_created.toString()).toLocaleDateString();

                    const relatedStatus = status.replace(/-/g, "").toUpperCase();
                    const subStatusColor = getSubStatusColor(relatedStatus);
                    const subIcon = getSubStatusIcon(relatedStatus);

                    return subs.includes(subId) ? (
                        <tr>
                            <td className={"tableBodyData"}>
                                <Link to={"/view-subscription/"+subId} style={{color:'#536889'}}><span className={"idNamelink"}>{subId}</span></Link>
                            </td>
                            <td className={"tableBodyData"}>
                                {line_items.map((e)=>{
                                    const {name} = e;
                                    return (
                                        <span>{name}<br/></span>
                                    )
                                })}
                            </td>
                            <td className={"tableBodyData"}>
                                <span className={"relatedSubStatusCapsule"}>
                                    <MyExpansionChip label={relatedStatus} icon={subIcon} color={subStatusColor}/>
                                </span>
                            </td>
                            <td className={"tableBodyData"}>
                                <span>{dateForSub}</span>
                            </td>
                            <td className={"tableBodyData"}>
                                <Link to={"/view-subscription/"+subId} style={{color:'#536889'}}>
                                    <button className={"wooButtons"}>View</button>
                                </Link>
                            </td>
                        </tr>
                    ) : (
                        <div hidden="hidden">

                        </div>
                    )
                })}
                </tbody>
            </table>
        </div>
    ) : (
        <div hidden="hidden">

        </div>
    )
};


export default function Order(props) {

    const orders  = props.orders;
    const subList = props.subList;
    let {orderId} = useParams();
    const orderIdentity = orderId;
    let navigate = useNavigate();

    const componentRef = useRef();
    const componentRef1 = useRef();
    const componentRef2 = useRef();
    const componentRef3 = useRef();
    const componentRef4 = useRef();

    const handlePrint4 = useReactToPrint({
        content: () => componentRef4.current,
        documentTitle: "Order Details of "+orderIdentity,
    });


    let ids = [];

    const settleLine = () =>{
        return orders.map((a) =>{

            const {id,total, total_tax, line_items, date_created, status,payment_method_title, customer_note ,relatedSubscriptions:subs,
                billing:{first_name: billingFirstName, last_name: billingLastName,company: billingCompany,
                    address_1: billingAdd1,address_2: billingAdd2, city: billingCity, state: billingState,
                    postcode: billingPostcode, email: billingEmail, phone: billingPhone}} = a;
            const orderTotal = Number(total.replace(/[^0-9\.]+/g,""));
            const Total = formatCurrency(orderTotal);

            const orderTax = Number(total_tax.replace(/[^0-9\.]+/g,""));
            const TotalTax = formatCurrency(orderTax);

            const orderSubTotal = (orderTotal - orderTax).toFixed(2);
            const subTotal = formatCurrency(orderSubTotal);

            let date = new Date(date_created.toString()).toLocaleDateString();

            const parentExist = subList.map((item)=>{
                const {parent_id} = item;
                let parent = false;
                if(parent_id === id){
                    parent = true;
                }
                return parent;
            });

            return id.toString() === orderIdentity.toString() ?
                (
                    <div>
                        <p className={"titleHelper"}>Order #
                            <mark>{id}</mark>{" "}
                            was placed on{" "}
                            <mark>{date}</mark>{" "}
                            and is currently{" "}
                            <mark>{status}</mark>
                            .</p>
                        <section className={"wooOrderDetails"}>
                            <table className={"wooDetailsTable"}>
                                <thead>
                                <tr>
                                    <th className={"tableHeaderData"}>Service</th>
                                    <th className={"tableHeaderDataTotal"}>Total</th>
                                </tr>
                                </thead>
                                {line_items.map((b)=>{
                                    const {name,quantity,meta_data,total:t} = b;
                                    const tTotal = formatCurrency(t);
                                    return (<>
                                        <tbody>
                                        <tr>
                                            <td className={"tableBodyData"}>
                                                <span><strong>{name}&nbsp;</strong></span>
                                                <strong>x&nbsp;{quantity}</strong>
                                                <ul className={"detailMetaDataList"}>
                                                    {meta_data.map((c)=>{
                                                        let {key,display_key, value} = c;

                                                        return key.charAt(0) !== "_" ? (
                                                            <li className={"metadataTitle"}>
                                                                <span>
                                                                    <strong>{display_key}</strong>
                                                                    <br/>
                                                                    {parse(value)}
                                                                    <b><br/><br/></b>
                                                                </span>
                                                            </li>
                                                        ) : (
                                                            <div hidden="hidden">
                                                                Sorry
                                                            </div>
                                                        )
                                                    })}
                                                </ul>
                                            </td>
                                            <td className={"totalTableData"}>
                                        <span>
                                            <bdi>
                                                {tTotal}
                                            </bdi>
                                        </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </>)
                                })}
                                <tfoot>
                                <tr>
                                    <th className={"tfootTh"} scope={"row"}>Subtotal:</th>
                                    <td className={"wooTableFootTd"}>
                                        {subTotal}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={"tfootTh"} scope={"row"}>NMGRT:</th>
                                    <td className={"wooTableFootTd"}>
                                        {TotalTax}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={"tfootTh"} scope={"row"}>Payment method:</th>
                                    <td className={"wooTableFootTd"}>
                                        {payment_method_title}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={"tfootTh"} scope={"row"}>Total:</th>
                                    <td className={"wooTableFootTd"}>
                                        {Total}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={"tfootTh"} scope={"row"}>Order Note:</th>
                                    <td className={"wooTableFootTd"}>
                                        {customer_note}
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                            {relatedSubs(subList,id,parentExist,subs)}
                        </section>
                        <section className={"wooOrderDetails"}>
                            {/*<Typography variant="h5" style={{fontWeight: 600}}>Billing Details</Typography><br/>*/}
                            <h3 align={"left"}>Billing Details</h3>
                            <address className={"orderBillingDetails"}>
                                {billingFirstName} {" "} {billingLastName}
                                <br/>
                                {billingCompany}
                                <br/>
                                {billingAdd1}
                                <br/>
                                {billingAdd2}
                                <br/>
                                {billingCity}, {" "} {billingState} {" "} {billingPostcode}
                                <br/>
                                <Call/>{billingPhone}
                                <br/>
                                <Email/>{billingEmail}
                            </address>
                        </section>
                    </div>
                ) : (
                    <div hidden="hidden">

                    </div>
                )
        })
    }

    useEffect(() => {
        orders.map((a)=>{
            return ids.push(a.id);
        });
        settleLine();
    });

    return (
        <div className={"pageView"}>
            <div className={"pageWrapper"}>
                <Typography
                    variant="h4"
                    className={"pageTitle"}
                >
                    <div>
                        <button className={"backButton"} onClick={()=> navigate(-1)}>
                            <ArrowBack fontSize={"medium"} />{" "}
                            <span className={"backButtonText"}>Go Back</span>
                        </button>
                    </div>
                    Order #{orderIdentity} Details {" "}

                    <button onClick={handlePrint4} className={"wooButtons"}><PrintIcon/> </button>
                </Typography>

                <div style={{display: "none"}}>
                    <PrintOrderDetails4 orderIdentity={orderIdentity} ref={componentRef4} />
                </div>

                {settleLine()}

            </div>
        </div>
    );
}