import React from "react"
import "./topbar.css";
// import "../../styles/style.scss";
import l4sbTopLogo from "../../logos/l4sb-300x165-1.png";
import AccountMenu from "../accountSettings/MyAccountSettings";
import SettingsAvatar from "../settingsAvatar/SettingsAvatar";

export default function Topbar(){
    const wp_site_url = process.env.REACT_APP_WP_SITE_URL;

    return (
        <div className={"topbar"}>
            <div className={"topbarWrapper"}>
                <div className={"topbarLeft"}>
                    <a href={wp_site_url}>
                        <img width="190" height="104.5" src={l4sbTopLogo} alt={""}/>
                    </a>
                </div>
                <div>
                    <span className={"myInMyAccount"}>Self Service {' '}</span><span className={"accountInMyAccount"}>Portal</span>
                </div>
                <div className={"topbarRight"}>
                    <SettingsAvatar/>
                </div>
            </div>
        </div>
    )
};

