import * as React from 'react';
import Button from '@mui/material/Button';
import {
    Box,
    Typography,
    Modal,
    Checkbox,
    Radio,
    RadioGroup,
    FormControlLabel,
    // ... any other MUI components you're using
} from '@mui/material';
import { NavLink} from "react-router-dom";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function MultiCheckboxModal({
                                               triggerButtonText = "Open",
                                               questionText = "Are you sure?",
                                               options = [], // array of options for checkboxes
                                               defaultSelected,
                                               confirmButtonText = "Yes",
                                               cancelButtonText = "Cancel",
                                               updateAllSubscriptionsText,
                                               addPaymentMethod,
                                               onConfirm
                                           }) {
    const [open, setOpen] = React.useState(false);
    const [selectedOptions, setSelectedOptions] = React.useState(() => {
        const initialState = {};
        options.forEach(option => {
            initialState[option] = defaultSelected === option;
        });
        return initialState;
    });

    const [updateAllSubscriptions, setUpdateAllSubscriptions] = React.useState(false);

    const handleUpdateAllSubscriptionsChange = (event) => {
        setUpdateAllSubscriptions(event.target.checked);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // const handleConfirmation = () => {
    //     if (onConfirm) onConfirm(selectedOptions);
    //     handleClose();
    // };
    const handleConfirmation = () => {
        if (onConfirm) {
            onConfirm(selectedOptions, updateAllSubscriptions ? "1" : "0"); // Pass "1" if checked, otherwise "0"
        }
        handleClose();
    };

    const handleRadioChange = (event) => {
        setSelectedOptions({ [event.target.value]: true });
    };

    const handleAddPaymentMethod = () => {

    };

    return (
        <div>
            <button onClick={handleOpen} className={"wooButtons"}>
                {triggerButtonText}
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {questionText}
                    </Typography>
                    {options.length > 0 && (
                        <RadioGroup
                            name="optionsGroup"
                            value={Object.keys(selectedOptions).find(option => selectedOptions[option])}
                            onChange={handleRadioChange}
                        >
                            {options.map((option) => (
                                <FormControlLabel
                                    value={option}
                                    control={<Radio />}
                                    label={<span style={{ fontSize: '0.8rem' }}>{option}</span>}
                                    key={option}
                                    style={{ marginLeft: '2px', marginRight: '0px', display: 'flex', justifyContent: 'center' }}
                                />
                            ))}
                        </RadioGroup>
                    )}
                    {updateAllSubscriptionsText && (
                        <div>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={updateAllSubscriptions}
                                        onChange={handleUpdateAllSubscriptionsChange}
                                        name="updateAllSubscriptions"
                                    />
                                }
                                label={<span style={{ fontSize: '0.8rem' }}>{updateAllSubscriptionsText}</span>}
                                style={{ display: 'flex', justifyContent: 'center' }}
                            />
                        </div>
                    )}
                    <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                        <button onClick={handleConfirmation} className={"wooButtons"}>
                            {confirmButtonText}
                        </button>
                        {addPaymentMethod && (
                            <NavLink to={'/payment-methods/add-payment-method'}>
                                <button className={"wooButtons"}>
                                Add New Payment Method
                                </button>
                            </NavLink>
                        )}
                        <button onClick={handleClose} className={"wooButtons"}>
                            {cancelButtonText}
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
