// FormikFields.js
import React, {useState} from 'react';
import "./formik.css";
import { useField } from 'formik';
import { RadioGroup, FormControlLabel, Radio, IconButton } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import InfoModal from '../modal/InfoModal';

export const questionMarkIconStyle = {
    marginLeft: 1, // Adjust spacing as needed
    verticalAlign: 'middle', // Aligns icon with text
    borderRadius: '0%', // Zero border radius
    padding: '0px !important',
    fontSize: '0.8rem !important'
    // Additional IconButton styles here...
};

export const questionMarkIconSx = {
    color: '#0d2959', // Icon color
    backgroundColor: '#f6bb46', // Background color
    borderRadius: '0%', // Zero border radius
    padding: '2px',
    '&:hover': {
        color: '#ffffff', // Icon color on hover
        backgroundColor: '#0d2959', // Background color on hover
    }
    // Additional styles for the icon itself
};

export const MyTextInput = ({ label, required, fieldName, description, link, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const onQuestionClick = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const formatInput = (e) => {
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1-');
        helpers.setValue(inputValue);
    };

    const handleChange = (e) => {
        if (props.name === 'company_applicant_id_1' || props.name === 'reporting_company_id' || props.name === 'company_applicant_id_2') {
            formatInput(e);
        } else {
            field.onChange(e);
        }
        if (props.onChange) {
            props.onChange(e);
        }
    };

    return (
        <div className="formFieldInput">
            <label className="formikLabel" htmlFor={props.id || props.name}>
                {label} {required && <span className="requiredAsterisk">*</span>}

                {(fieldName || description) && (
                    <IconButton
                        aria-label="help"
                        size="small"
                        onClick={onQuestionClick}
                        sx={questionMarkIconStyle}
                    >
                        <QuestionMarkIcon
                            fontSize="inherit"
                            sx={questionMarkIconSx}
                        />
                    </IconButton>
                )}
            </label>
            <input
                className={`formikInput ${hasError ? 'formikInput-error' : ''} ${props.readOnly ? 'readOnlyInput' : ''}`}
                {...field}
                {...props}
                onChange={handleChange}
            />
            {hasError && <div className="error">{meta.error}</div>}

            <InfoModal
                open={isModalOpen}
                handleClose={handleCloseModal}
                fieldName={fieldName}
                description={description}
                link={link}
            />
        </div>
    );
};

export const MyTextArea = ({ label, fieldName, description, link, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onQuestionClick = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <div className="formFieldInput">
            <label className="formikLabel" htmlFor={props.id || props.name}>
                {label}
                {(fieldName || description) && (
                    <IconButton
                        aria-label="help"
                        size="small"
                        onClick={onQuestionClick}
                        sx={questionMarkIconStyle}
                    >
                        <QuestionMarkIcon fontSize="inherit" sx={questionMarkIconSx} />
                    </IconButton>
                )}
            </label>
            <textarea className={`formikTextArea ${hasError ? 'formikInput-error' : ''}`} {...field} {...props} />
            {hasError && <div className="error">{meta.error}</div>}
            <InfoModal open={isModalOpen} handleClose={handleCloseModal} fieldName={fieldName} description={description} link={link} />
        </div>
    );
};


export const MyCheckbox = ({ label, fieldName, description, link, ...props }) => {
    const [field, meta] = useField({ ...props, type: 'checkbox' });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onQuestionClick = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <div className="formFieldCheckBoxInput">
            <label className="checkBoxLabel">
                {label}
                {(fieldName || description) && (
                    <IconButton
                        aria-label="help"
                        size="small"
                        onClick={onQuestionClick}
                        sx={questionMarkIconStyle}
                    >
                        <QuestionMarkIcon fontSize="inherit" sx={questionMarkIconSx} />
                    </IconButton>
                )}
            </label>
            <input type="checkbox" className="checkBoxInput" {...field} {...props} />
            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
            <InfoModal open={isModalOpen} handleClose={handleCloseModal} fieldName={fieldName} description={description} link={link} />
        </div>
    );
};


export const MySelect = ({ label, required, fieldName, description, link, ...props }) => {
    const [field, meta] = useField(props);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onQuestionClick = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <div className="formFieldInput">
            <label className="formikLabel" htmlFor={props.id || props.name}>
                {label} {required && <span className="requiredAsterisk">*</span>}
                {(fieldName || description) && (
                    <IconButton aria-label="help" size="small" onClick={onQuestionClick} sx={questionMarkIconStyle}>
                        <QuestionMarkIcon fontSize="inherit" sx={questionMarkIconSx} />
                    </IconButton>
                )}
            </label>
            <select {...field} {...props} className="selectInput" />
            {meta.touched && meta.error && <div className="error">{meta.error}</div>}
            <InfoModal open={isModalOpen} handleClose={handleCloseModal} fieldName={fieldName} description={description} link={link} />
        </div>
    );
};

export const FormikDateInput = ({ label, field, meta, fieldName, description, link, ...props }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onQuestionClick = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <div className="formFieldInput">
            <label className="formikLabel" htmlFor={field.name}>{label}</label>
            <input
                className={`formikInput ${meta.touched && meta.error ? 'formikInput-error' : ''}`}
                {...field}
                {...props}
                type="text"
            />
            {meta.touched && meta.error ? (<div className="error">{meta.error}</div>) : null}
            <InfoModal open={isModalOpen} handleClose={handleCloseModal} fieldName={fieldName} description={description} link={link} />
        </div>
    );
};


export const MyRadioGroup = ({ label, required, fieldName, description, options, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const hasError = meta.touched && meta.error;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const onQuestionClick = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const handleChange = (event) => {
        helpers.setValue(event.target.value);
        if (props.onChange) {
            props.onChange(event);
        }
    };

    return (
        <div className="formFieldInput">
            <label className="formikLabel" htmlFor={props.id || props.name}>
                {label} {required && <span className="requiredAsterisk">*</span>}
                {(fieldName || description) && (
                    <IconButton
                        aria-label="help"
                        size="small"
                        onClick={onQuestionClick}
                        sx={questionMarkIconStyle}
                    >
                        <QuestionMarkIcon
                            fontSize="inherit"
                            sx={questionMarkIconSx}
                        />
                    </IconButton>
                )}
            </label>
            <RadioGroup {...field} {...props} value={field.value} onChange={handleChange}>
                {options.map(option => (
                    <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
                ))}
            </RadioGroup>
            {hasError && <div className="error">{meta.error}</div>}
            <InfoModal
                open={isModalOpen}
                handleClose={handleCloseModal}
                fieldName={fieldName}
                description={description}
            />
        </div>
    );
};

// // FormikFields.js
// import React, {useState} from 'react';
// import "./formik.css";
// import { useField } from 'formik';
// import IconButton from '@mui/material/IconButton';
// import HelpCenterIcon from '@mui/icons-material/HelpCenter';
// import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
// import InfoModal from '../modal/InfoModal';
//
// <<<<<<< Updated upstream
// // export const MyTextInput = ({ label, required, ...props }) => {
// //     const [field, meta] = useField(props);
// //     const hasError = meta.touched && meta.error;
// //
// //     return (
// //         <div className="formFieldInput">
// //             <label className="formikLabel" htmlFor={props.id || props.name}>
// //                 {label} {required && <span className="requiredAsterisk">*</span>}
// //             </label>
// //             <input
// //                 className={`formikInput ${hasError ? 'formikInput-error' : ''}`}
// //                 {...field}
// //                 {...props}
// //             />
// //             {hasError && <div className="error">{meta.error}</div>}
// //         </div>
// //     );
// // };
//
// export const MyTextInput = ({ label, required, ...props }) => {
//     const [field, meta, helpers] = useField(props);
//     const hasError = meta.touched && meta.error;
//
//     // Function to format the input value
//     const formatInput = (e) => {
//         // Get the current input value
//         let inputValue = e.target.value;
//
//         // Remove all non-digits and then add dashes after every 4 digits
//         inputValue = inputValue.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1-');
//
//         // Update the field value using Formik's helper
//         helpers.setValue(inputValue);
//     };
// =======
// export const questionMarkIconStyle = {
//     marginLeft: 1, // Adjust spacing as needed
//     verticalAlign: 'middle', // Aligns icon with text
//     borderRadius: '0%', // Zero border radius
//     // Additional IconButton styles here...
// };
//
// export const questionMarkIconSx = {
//     color: '#0d2959', // Icon color
//     backgroundColor: '#f6bb46', // Background color
//     borderRadius: '0%', // Zero border radius
//     padding: '2px',
//     '&:hover': {
//         color: '#ffffff', // Icon color on hover
//         backgroundColor: '#0d2959', // Background color on hover
//     }
//     // Additional styles for the icon itself
// };
//
// export const MyTextInput = ({ label, required, fieldName, description, link, ...props }) => {
//     const [field, meta] = useField(props);
//     const hasError = meta.touched && meta.error;
//
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const onQuestionClick = () => setIsModalOpen(true);
//     const handleCloseModal = () => setIsModalOpen(false);
// >>>>>>> Stashed changes
//
//     return (
//         <div className="formFieldInput">
//             <label className="formikLabel" htmlFor={props.id || props.name}>
//                 {label} {required && <span className="requiredAsterisk">*</span>}
//
//                 {(fieldName || description) && (
//                     <IconButton
//                         aria-label="help"
//                         size="small"
//                         onClick={onQuestionClick}
//                         sx={questionMarkIconStyle}
//                     >
//                         <QuestionMarkIcon
//                             fontSize="inherit"
//                             sx={questionMarkIconSx}
//                         />
//                     </IconButton>
//                 )}
//
//             </label>
//             <input
//                 className={`formikInput ${hasError ? 'formikInput-error' : ''}`}
//                 {...field}
//                 {...props}
//                 onChange={props.name === 'company_applicant_id_1' || props.name === 'reporting_company_id' || props.name === 'company_applicant_id_2' ? formatInput : field.onChange}
//             />
//             {hasError && <div className="error">{meta.error}</div>}
//
//             <InfoModal
//                 open={isModalOpen}
//                 handleClose={handleCloseModal}
//                 fieldName={fieldName}
//                 description={description}
//                 link={link}
//             />
//         </div>
//     );
// };
//
// <<<<<<< Updated upstream
//
// export const MyTextArea = ({ label, ...props }) => {
// =======
// export const MyTextArea = ({ label, fieldName, description, link, ...props }) => {
// >>>>>>> Stashed changes
//     const [field, meta] = useField(props);
//     const hasError = meta.touched && meta.error;
//
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const onQuestionClick = () => setIsModalOpen(true);
//     const handleCloseModal = () => setIsModalOpen(false);
//
//     return (
//         <div className="formFieldInput">
//             <label className="formikLabel" htmlFor={props.id || props.name}>
//                 {label}
//                 {(fieldName || description) && (
//                     <IconButton aria-label="help" size="small" onClick={onQuestionClick} sx={questionMarkIconStyle}>
//                         <QuestionMarkIcon fontSize="inherit" sx={questionMarkIconSx} />
//                     </IconButton>
//                 )}
//             </label>
//             <textarea className={`formikTextArea ${hasError ? 'formikInput-error' : ''}`} {...field} {...props} />
//             {hasError && <div className="error">{meta.error}</div>}
//             <InfoModal open={isModalOpen} handleClose={handleCloseModal} fieldName={fieldName} description={description} link={link} />
//         </div>
//     );
// };
//
//
// export const MyCheckbox = ({ label, fieldName, description, link, ...props }) => {
//     const [field, meta] = useField({ ...props, type: 'checkbox' });
//
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const onQuestionClick = () => setIsModalOpen(true);
//     const handleCloseModal = () => setIsModalOpen(false);
//
//     return (
//         <div className="formFieldInput">
//             <label className="formikLabel">
//                 {label}
//                 {(fieldName || description) && (
//                     <IconButton aria-label="help" size="small" onClick={onQuestionClick} sx={questionMarkIconStyle}>
//                         <QuestionMarkIcon fontSize="inherit" sx={questionMarkIconSx} />
//                     </IconButton>
//                 )}
//             </label>
//             <input type="checkbox" className="checkBoxInput" {...field} {...props} />
//             {meta.touched && meta.error && <div className="error">{meta.error}</div>}
//             <InfoModal open={isModalOpen} handleClose={handleCloseModal} fieldName={fieldName} description={description} link={link} />
//         </div>
//     );
// };
//
//
// export const MySelect = ({ label, required, fieldName, description, link, ...props }) => {
//     const [field, meta] = useField(props);
//
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const onQuestionClick = () => setIsModalOpen(true);
//     const handleCloseModal = () => setIsModalOpen(false);
//
//     return (
//         <div className="formFieldInput">
//             <label className="formikLabel" htmlFor={props.id || props.name}>
//                 {label} {required && <span className="requiredAsterisk">*</span>}
//                 {(fieldName || description) && (
//                     <IconButton aria-label="help" size="small" onClick={onQuestionClick} sx={questionMarkIconStyle}>
//                         <QuestionMarkIcon fontSize="inherit" sx={questionMarkIconSx} />
//                     </IconButton>
//                 )}
//             </label>
//             <select {...field} {...props} className="selectInput" />
//             {meta.touched && meta.error && <div className="error">{meta.error}</div>}
//             <InfoModal open={isModalOpen} handleClose={handleCloseModal} fieldName={fieldName} description={description} link={link} />
//         </div>
//     );
// };
//
//
// export const FormikDateInput = ({ label, field, meta, fieldName, description, link, ...props }) => {
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const onQuestionClick = () => setIsModalOpen(true);
//     const handleCloseModal = () => setIsModalOpen(false);
//
//     return (
//         <div className="formFieldInput">
//             <label className="formikLabel" htmlFor={field.name}>
//                 {label}
//                 {(fieldName || description) && (
//                     <IconButton aria-label="help" size="small" onClick={onQuestionClick} sx={questionMarkIconStyle}>
//                         <QuestionMarkIcon fontSize="inherit" sx={questionMarkIconSx} />
//                     </IconButton>
//                 )}
//             </label>
//             <input className={`formikInput ${meta.touched && meta.error ? 'formikInput-error' : ''}`} {...field} {...props} type="text" />
//             {meta.touched && meta.error ? (<div className="error">{meta.error}</div>) : null}
//             <InfoModal open={isModalOpen} handleClose={handleCloseModal} fieldName={fieldName} description={description} link={link} />
//         </div>
//     );
// };
//
