/**
 * userMetaState Reducer is used to GET user Meta with Wp-Auth NPM package from DB
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */
import * as actions from '../actions/actionTypes'

const initState = {
  expandedSubs: [],
  expandedOrders: [],
  needHelp: {},
  companies: [],
  deleteStatusCode: null,
  loading: false,
  error: null
}

const userMetaState = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_HELP_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case actions.GET_HELP_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        needHelp: action.payload
      }
    case actions.GET_HELP_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.GET_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case actions.GET_COMPANIES_SUCCESS:
      const { companies, userId } = action.payload;
      const filteredCompanies = companies.filter(company => {
        if (!company.acf || !company.acf.clients) return false;
        // Normalize clients to always be an array, even if it's a single value
        const clients = Array.isArray(company.acf.clients) ? company.acf.clients : [company.acf.clients];
        return clients.includes(userId);
      });
      return {
        ...state,
        loading: false,
        companies: companies
      }
    case actions.GET_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.SET_ORDER_ROWS:
      return {
        ...state,
        expandedOrders: action.payload
      }
    case actions.SET_SUBSCRIPTIONS_ROWS:
      return {
        ...state,
        expandedSubs: action.payload
      }
    case actions.DELETE_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case actions.DELETE_COMPANIES_SUCCESS:
      const deletedCompanyId = action.payload;
      const updatedCompanies = state.companies.filter(company => company.id !== deletedCompanyId);
      return {
        ...state,
        loading: false,
        companies: updatedCompanies
      }
    case actions.DELETE_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.ADD_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case actions.ADD_COMPANIES_SUCCESS:
      const newCompany = action.payload;
      // Add the new company to the existing companies array
      const updateAddedCompanies = [...state.companies, newCompany];
      return {
        ...state,
        loading: false,
        companies: updateAddedCompanies
      }
    case actions.ADD_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default userMetaState
