// import "../../styles/style.scss";
import React, { useState} from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import { Box, Typography } from '@mui/material';

export default function MyProfile(props){

    const userDetailsLoad =  useSelector((state) => state.userDetailsState.loading);

    const myProfileDetails = (details) => {
        const { first_name, last_name, email} = details;

        return (
            <div>
                <div className={"firstName"}>
                    <span> First Name: </span>
                    <span className={"itemDisplay"}><strong>{" "}{first_name}</strong></span>
                    <br/>
                </div>
                <div className={"lastname"}>
                    <span> Last Name:</span>
                    <span className={"itemDisplay"}><strong>{" "}{last_name}</strong></span>
                    <br/><br/>
                </div>
                <div className={"displayName"}>
                    <span> Display Name: </span>
                    <span className={"itemDisplay"}><strong>{" "}{first_name+" "+last_name}</strong></span><br/><br/>
                </div>
                <div className={"emailAddress"}>
                    <span> Email Address: </span>
                    <span className={"itemDisplay"}><strong>{" "}{email}</strong></span>
                    <br/><br/>
                </div>
            </div>
        )
    }

    return  (
            <div className={"pageView"}>
                    <div className={"pageWrapper"}>
                        <Typography variant="h4" className={"pageTitle"}>My Profile</Typography>
                        {/*<h1 className={"pageTitle"}>My Profile</h1>*/}
                        {myProfileDetails(props.details)}
                    </div>
            </div>
    )
}

