/**
 * addCompany Api is used to ADD a Company from custom post type on WordPress
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import axios from 'axios';
import * as actions from './actionTypes';
import {toastr} from "react-redux-toastr";
import { toast, ToastContainer } from 'react-toastify';
import getCompanies from "./getCompanies";
import {getUserDetails} from './getUserDetails';

/**
 * ADD a company from Wordpress Custom Post Type - Companies.
 * @endpoint "/companieslist"  Middleware customization and documentation.
 * @param userId Customer Login userID to get user specific details.
 * @param companyId CompanyId to get user specific details.
 * @return Response correct status if successful or not .
 */
const addCompanyCpt = (userId, nonce, title,company_type, state_of_domestication, reporting_company_id, boi_reporting_date, company_formation_date, company_applicant_id_1, company_applicant_id_2, base_address, mailing_address_same_as_address, mailing_address, beneficial_owners, companyId, navigate) => async (dispatch, getState) => {
    dispatch({ type: actions.ADD_COMPANIES_REQUEST });

    const companyData = {
        userId: userId,
        nonce: nonce,
        title: title,
        company_type: company_type,
        state_of_domestication: state_of_domestication,
        reporting_company_id: reporting_company_id,
        boi_reporting_date: boi_reporting_date,
        company_formation_date: company_formation_date,
        company_applicant_id_1: company_applicant_id_1,
        company_applicant_id_2: company_applicant_id_2,
        base_address: base_address,
        mailing_address_same_as_address: mailing_address_same_as_address,
        mailing_address: mailing_address,
        beneficial_owners: beneficial_owners,
        companyId: companyId
    };

    try {
        const response = await axios.post('/companieslist', {
            userId: userId,
            nonce: nonce,
            title: title,
            company_type: company_type,
            state_of_domestication: state_of_domestication,
            reporting_company_id: reporting_company_id,
            boi_reporting_date: boi_reporting_date,
            company_formation_date: company_formation_date,
            company_applicant_id_1: company_applicant_id_1,
            company_applicant_id_2: company_applicant_id_2,
            base_address: base_address,
            mailing_address_same_as_address: mailing_address_same_as_address,
            mailing_address: mailing_address,
            beneficial_owners: beneficial_owners,
            companyId: companyId
        });

        const addedCompany = response.data;

        dispatch({ type: actions.ADD_COMPANIES_SUCCESS, payload: addedCompany });

        if (addedCompany) {
            toast.success('Successfully Updated Company!');
            dispatch(getCompanies(userId));
            dispatch(getUserDetails(userId));
            //navigate('/companies');
        } else {
            toast.error('Failed to Update a Company. Please come back later!');
        }
    } catch (error) {
        dispatch({ type: actions.ADD_COMPANIES_FAILURE, error });
        toast.error('Failed to Update a Company. Please try again!');
    }
};


export default addCompanyCpt;
