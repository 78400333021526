import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({ children }) => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

    const navigate = useNavigate();

    const onRedirectCallback = (appState) => {
        // navigate(appState?.returnTo || window.location.pathname);

        // Retrieve the preserved query parameters from the appState
        const queryParams = appState?.queryParams || '';

        // Navigate to the desired route while appending the preserved query parameters
        const returnTo = appState?.returnTo || window.location.pathname;
        const navigateTo = queryParams ? `${returnTo}?${queryParams}` : returnTo;
        navigate(navigateTo);
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience={audience}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
