// import "../../styles/style.scss";
import "./needHelp.css";
import {Link} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

export default function NeedHelp(props){
    const wp_site_url = process.env.REACT_APP_WP_SITE_URL;
    const needHelpData = props.needHelp;

    const [helpData, setHelpData] = useState('');

    useEffect(() => {
        const content = needHelpData[0].content.rendered;
        setHelpData(content);
    }, [needHelpData]);

    return (
        <div className={"pageView"}>
            <div className="helpPageWrapper">
                {/*<h1>Help Page</h1>*/}
                <Typography variant="h4" style={{fontWeight: 600}}>Help Page</Typography>
                <div dangerouslySetInnerHTML={{ __html: helpData }} />
            </div>
        </div>)
}
