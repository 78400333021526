/**
 * getSwitchBackUrl Api is used to GET Switch Back Old User from WordPress
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import axios from 'axios'
import * as actions from './actionTypes'

/**
 * GET Subscriptions List details from WooCommerce REST API.
 * @endpoint "/subscriptions"  Middleware customization and documentation.
 * @param id Customer Login userID to get user specific details.
 *
 * @return JSON Subscriptions List Object .
 */
const getSwitchBackUrl = (id, userId) => async (dispatch, getState) => {

    dispatch({ type: actions.GET_SWITCH_BACK_URL_REQUEST })
    try {
        const response = await axios.get(
            '/switchBackUrl', {
                params: {
                    oldUser: id,
                    newUser: userId
                }
            }
        )

        dispatch({ type: actions.GET_SWITCH_BACK_URL_SUCCESS, payload: response.data })
    } catch (error) {
        dispatch({ type: actions.GET_SWITCH_BACK_URL_FAILURE, error })
    }
}

export default getSwitchBackUrl
