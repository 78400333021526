import "./profileChange.css"
// import "../../styles/style.scss";
import * as React from 'react';
import Profile from "../../components/formik/Profile";
import { Box, Typography } from '@mui/material';

export default function ProfileChange(props) {

    const id = props.id;
    const {first_name: firstName,last_name: lastName,email} = props.details

    const userData = {
        firstName,
        lastName,
        email
    }

    return (
        <div className={"pageView"}>
            <div className={"pageWrapper"}>
                <Typography variant="h4" className={"pageTitle"}></Typography>
                {/*<h1 className={"pageTitle"}>My Profile</h1>*/}
                <Profile id={id} userData={userData}/>
            </div>
        </div>
    );
}


