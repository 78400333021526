import React, {useEffect, useState, useCallback} from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {getCardWithLatestExpiry} from "./getCardWithLatestExpiry";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function DeleteCardFlow({
                                           cards = [], // List of cards to display
                                           onConfirm,
                                           onClose
                                       }) {
    const [selectedCard, setSelectedCard] = useState(null);


    useEffect(() => {
        const cardWithLatestExpiry = getCardWithLatestExpiry(cards);
        if (cardWithLatestExpiry) {
            setSelectedCard(cardWithLatestExpiry.id);
        }
    }, [cards]);

    return (
        <Modal
            open={true}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    You need to delete a card before adding one. Select a card to delete:
                </Typography>
                {cards.map((card) => (
                    <FormControlLabel
                        style={{ marginLeft: '2px', marginRight: '0px', display: 'flex', justifyContent: 'center' }}
                        key={card.id}
                        control={
                            <Checkbox
                                checked={selectedCard === card.id}
                                onChange={() => setSelectedCard(card.id)}
                                name={card.id.toString()}
                            />
                        }
                        label={<span style={{ fontSize: '0.8rem' }}>{card.displayName}</span>} // Assuming each card has a displayName for users to recognize
                    />
                ))}
                <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-between' }}>
                    <button onClick={() => onConfirm(selectedCard)} className={"wooButtons"}>
                        Delete Selected Card
                    </button>
                    <button onClick={onClose} className={"wooButtons"}>
                        Cancel
                    </button>
                </div>
            </Box>
        </Modal>
    );
}
