// import "../../styles/style.scss";
import React from 'react';
import ShippingAddressForm from "../../components/formik/ShippingAddressForm";
import { Box, Typography } from '@mui/material';

export default function ShippingAddress(props){

    const id = props.id;

    const {shipping : { first_name :firstName, last_name: lastName,company,address_1: address1,
        address_2: address2,city, state, postcode,country }} = props.details;

    const userShippingData = {
        firstName,
        lastName,
        company,
        address1,
        address2,
        city,
        state,
        postcode,
        country
    }

    return (
        <div className={"pageView"}>
            <div className={"pageWrapper"}>
                <Typography variant="h4" className={"pageTitle"}>Edit Shipping Address</Typography>
                {/*<h1 className={"pageTitle"}> Edit Shipping Address</h1>*/}
                <div className={"formIO"}>
                    <ShippingAddressForm id={id} shippingData={userShippingData}/>
                </div>
            </div>
        </div>
    )
}