import "./address.css";
// import "../../styles/style.scss"
import {Link} from "react-router-dom";
import { Divider} from "@mui/material";
import React from "react";
import {useSelector} from "react-redux";
import { Box, Typography } from '@mui/material';

export default function Address(props){

    const userBillingAddress = (billing) =>{
        const {  billing : {first_name, last_name, address_1, address_2, city, company, country, postcode, state}} = billing;
            return  (
                <div>
                    <address className={"addressContent"}>
                        <br/>
                        {first_name + " "+ last_name}
                        <br/>
                        {company}
                        <br/>
                        {address_1 }
                        <br/>
                        {address_2}
                        <br/>
                        {city+ ", " +state+' - '+postcode}
                        <br/>
                        {country}
                    </address>
                </div>
            )
    }

    return props.details.first_name ? (
        <div className={"pageView"}>
            <div className={"pageWrapper"}>
                <Typography variant="h4" className="pageTitle">My Address</Typography>
                <div>
                    <div className={"addressPage"}>
                        <p className={"titleHelper"}> The following addresses will be used for billing on the checkout page by default</p>
                        <header className={"woocommerce title"}>
                            <h3 className={"addressHeader"}>Billing address</h3>
                        </header>
                        {userBillingAddress(props.details)}
                        <br/>
                        <br/>
                        <Link to={"/edit-address/billing"}><button className={"wooButtons"}>Edit</button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
        : (
            <div className={"pageView"}>
                <div className={"pageWrapper"}>
                    <Typography variant="h4" className="pageTitle">My Address</Typography>
                    <div>
                        <div className={"addressPage"}>
                            <p className={"titleHelper"}> The following addresses will be used for billing on the checkout page by default</p>
                            <header className={"woocommerce title"}>
                                <Typography variant="h5" className={"addressHeader"}></Typography>
                                {/*<h3 className={"addressHeader"}>Billing address</h3>*/}
                                <Link to={"/edit-address/billing"}>Edit</Link>
                            </header>

                        </div>
                    </div>

                </div>
            </div>
        )
    }