import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {BrowserRouter} from "react-router-dom"
import store, {Persistor} from "./reducers";
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from "react-redux";
import ReduxToastr from 'react-redux-toastr'
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Importing createTheme and ThemeProvider
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeOptions, themeButtonOptions } from './themeOptions'; // assuming themeOptions is exported from themeOptions.js
import { GlobalCss } from './GlobalCss';

// Create a theme instance.
const theme = createTheme({
    ...themeOptions, // spread the themeOptions object here
    spacing: 8, // this is your global baseline
});

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <PersistGate loading={null} persistor={Persistor}>
                <div>
                    <Auth0ProviderWithHistory>
                        {/* Wrapping the App with ThemeProvider */}
                        <React.StrictMode>
                            <ThemeProvider theme={theme}>
                                <GlobalCss />
                                <App/>
                            </ThemeProvider>
                        </React.StrictMode>
                    </Auth0ProviderWithHistory>
                    {/*<ReduxToastr*/}
                    {/*    timeOut={4000}*/}
                    {/*    preventDuplicates*/}
                    {/*    getState={(state) => state.toastr}*/}
                    {/*    transitionIn="fadeIn"*/}
                    {/*    transitionOut="fadeOut"*/}
                    {/*    closeOnToastrClick*/}
                    {/*/>*/}
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={true}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                    />
                </div>
            </PersistGate>
        </Provider>
    </BrowserRouter>,
    document.getElementById("root"));