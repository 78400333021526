import { ThemeOptions } from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
    typography: {
        fontFamily: '"proxima-nova", sans-serif',
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontSize: '3rem',
            lineHeight: 1.2,
            marginBottom: 'var(--spacing-m)',
        },
        h2: {
            fontSize: '1rem',
            lineHeight: 1.2,
            marginBottom: 'var(--spacing-s)',
        },
        h3: {
            fontSize: '1.875rem',
            lineHeight: 1.2,
            marginBottom: '14px',
        },
        h4: {
            fontSize: '1.5rem',
            lineHeight: 1.2,
            fontWeight: 600,
            marginBottom: '24px',
        },
        h5: {
            fontSize: '1.25rem',
            lineHeight: 1.2,
            marginBottom: 'var(--spacing-xs)',
        },
        h6: {
            fontSize: '1.125rem',
            lineHeight: 1.2,
            marginBottom: 'var(--spacing-xs)',
        },
        body1: {
            fontSize: '1rem',
            lineHeight: 1.5,
        },
        body2: {
            fontSize: '1rem',
            lineHeight: 1.45,
        },
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '1.4rem',
                    color: '#536889',
                    fontFamily: 'var(--font-family-primary)',
                    fontWeight: 'var(--font-weight-regular)',
                    lineHeight: 'var(--line-height-body-desktop)',
                    fontStyle: 'normal',
                    textAlign: 'center',
                    verticalAlign: 'top',
                    paddingTop: '10px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1.4rem',
                    color: '#536889',
                    fontFamily: 'var(--font-family-primary)',
                    fontWeight: 'var(--font-weight-regular)',
                    lineHeight: 'var(--line-height-body-desktop)',
                    fontStyle: 'normal',
                },
            },
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    fontSize: '1.1rem',
                    color: '#536889',
                    fontFamily: 'var(--font-family-primary)',
                    fontWeight: 'var(--font-weight-regular)',
                    lineHeight: 'var(--line-height-body-desktop)',
                    fontStyle: 'normal',
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    fontSize: '1.1rem',
                    color: '#536889',
                    fontFamily: 'var(--font-family-primary)',
                    fontWeight: 'var(--font-weight-regular)',
                    lineHeight: 'var(--line-height-body-desktop)',
                    fontStyle: 'normal',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    // marginLeft: '20px',
                    '&:hover':{
                        backgroundColor: 'initial'
                    }
                },
                colorPrimary: {
                    '&:hover': {
                        backgroundColor: 'initial',
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    margin: '0 0 0px -15px',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation4: {
                    boxShadow: '0px 0px 1px 1px rgb(0 0 0 / 21%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 1px 1px 1px 1px rgb(0 0 0 / 11%)',
                },
                elevation1: {
                    boxShadow: 'none',
                },
            },
        },
    },
};
