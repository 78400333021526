/**
 * subscriptionState Reducer is used to store all Subscriptions List Details
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import * as actions from '../actions/actionTypes'

const initState = {
  subscriptions: [
    {
      "id": 0,
      "parent_id": 0,
      "status": "",
      "currency": "",
      "version": "",
      "prices_include_tax": false,
      "date_created": "",
      "date_modified": "",
      "discount_total": "0.00",
      "discount_tax": "0.00",
      "shipping_total": "0.00",
      "shipping_tax": "0.00",
      "cart_tax": "0.00",
      "total": "00.00",
      "total_tax": "0.00",
      "customer_id": 0,
      "order_key": "",
      "billing": {
        "first_name": "",
        "last_name": "",
        "company": "",
        "address_1": "",
        "address_2": "",
        "city": "",
        "state": "",
        "postcode": "",
        "country": "",
        "email": "",
        "phone": ""
      },
      "shipping": {
        "first_name": "",
        "last_name": "",
        "company": "",
        "address_1": "",
        "address_2": "",
        "city": "",
        "state": "",
        "postcode": "",
        "country": "",
        "phone": ""
      },
      "payment_method": "",
      "payment_method_title": "",
      "customer_ip_address": "",
      "customer_user_agent": "",
      "created_via": "",
      "customer_note": "",
      "date_completed": null,
      "date_paid": "",
      "number": "",
      "meta_data": [
        {
          "id": 0,
          "key": "",
          "value": ""
        }
      ],
      "line_items": [
        {
          "id": 0,
          "name": "",
          "product_id": 0,
          "variation_id": 0,
          "quantity": 0,
          "tax_class": "",
          "subtotal": "00.00",
          "subtotal_tax": "0.00",
          "total": "0.00",
          "total_tax": "0.00",
          "taxes": [
            {
              "id": 0,
              "total": "",
              "subtotal": ""
            }
          ],
          "meta_data": [],
          "sku": "",
          "price": 0,
          "image": {
            "id": 0,
            "src": ""
          },
          "parent_name": ""
        }
      ],
      "tax_lines": [
        {
          "id": 0,
          "rate_code": "",
          "rate_id": 0,
          "label": "",
          "compound": true,
          "tax_total": "0.00",
          "shipping_tax_total": "0.00",
          "rate_percent": 10,
          "meta_data": []
        }
      ],
      "shipping_lines": [],
      "fee_lines": [],
      "coupon_lines": [],
      "payment_url": "https://devc.l4sb.com/checkout/order-pay/",
      "is_editable": false,
      "needs_payment": false,
      "needs_processing": true,
      "date_created_gmt": "",
      "date_modified_gmt": "",
      "date_completed_gmt": null,
      "date_paid_gmt": "",
      "actions": {
        "change_payment_method": {
          "url": "https://devc.l4sb.com/checkout/order-pay/",
          "name": "Change payment"
        }
      },
      "customerProfileId": "",
      "relatedOrders": {},
      "billing_period": "",
      "billing_interval": "",
      "start_date_gmt": "",
      "trial_end_date_gmt": "",
      "next_payment_date_gmt": "",
      "payment_retry_date_gmt": "",
      "last_payment_date_gmt": "",
      "cancelled_date_gmt": "",
      "end_date_gmt": "",
      "resubscribed_from": "",
      "resubscribed_subscription": "",
      "removed_line_items": [],
      "_links": {
        "self": [
          {
            "href": "https://example.com/wp-json/wc/v3/subscriptions/1313"
          }
        ],
        "collection": [
          {
            "href": "https://example.com/wp-json/wc/v3/subscriptions"
          }
        ],
        "customer": [
          {
            "href": "https://example.com/wp-json/wc/v3/customers/1"
          }
        ]
      }
    },
    {
      "id": 0,
      "parent_id": 0,
      "status": "",
      "currency": "",
      "version": "",
      "prices_include_tax": false,
      "date_created": "",
      "date_modified": "",
      "discount_total": "0.00",
      "discount_tax": "0.00",
      "shipping_total": "0.00",
      "shipping_tax": "0.00",
      "cart_tax": "0.00",
      "total": "00.00",
      "total_tax": "0.00",
      "customer_id": 0,
      "order_key": "",
      "billing": {
        "first_name": "",
        "last_name": "",
        "company": "",
        "address_1": "",
        "address_2": "",
        "city": "",
        "state": "",
        "postcode": "",
        "country": "",
        "email": "",
        "phone": ""
      },
      "shipping": {
        "first_name": "",
        "last_name": "",
        "company": "",
        "address_1": "",
        "address_2": "",
        "city": "",
        "state": "",
        "postcode": "",
        "country": "",
        "phone": ""
      },
      "payment_method": "",
      "payment_method_title": "",
      "customer_ip_address": "",
      "customer_user_agent": "",
      "created_via": "",
      "customer_note": "",
      "date_completed": null,
      "date_paid": "",
      "number": "",
      "meta_data": [
        {
          "id": 0,
          "key": "",
          "value": ""
        }
      ],
      "line_items": [
        {
          "id": 0,
          "name": "",
          "product_id": 0,
          "variation_id": 0,
          "quantity": 0,
          "tax_class": "",
          "subtotal": "00.00",
          "subtotal_tax": "0.00",
          "total": "0.00",
          "total_tax": "0.00",
          "taxes": [
            {
              "id": 0,
              "total": "",
              "subtotal": ""
            }
          ],
          "meta_data": [],
          "sku": "",
          "price": 0,
          "image": {
            "id": 0,
            "src": ""
          },
          "parent_name": ""
        }
      ],
      "tax_lines": [
        {
          "id": 0,
          "rate_code": "",
          "rate_id": 0,
          "label": "",
          "compound": true,
          "tax_total": "0.00",
          "shipping_tax_total": "0.00",
          "rate_percent": 10,
          "meta_data": []
        }
      ],
      "shipping_lines": [],
      "fee_lines": [],
      "coupon_lines": [],
      "payment_url": "https://devc.l4sb.com/checkout/order-pay/",
      "is_editable": false,
      "needs_payment": false,
      "needs_processing": true,
      "date_created_gmt": "",
      "date_modified_gmt": "",
      "date_completed_gmt": null,
      "date_paid_gmt": "",
      "actions": {
        "change_payment_method": {
          "url": "https://devc.l4sb.com/checkout/order-pay/",
          "name": "Change payment"
        }
      },
      "customerProfileId": "",
      "relatedOrders": {},
      "billing_period": "",
      "billing_interval": "",
      "start_date_gmt": "",
      "trial_end_date_gmt": "",
      "next_payment_date_gmt": "",
      "payment_retry_date_gmt": "",
      "last_payment_date_gmt": "",
      "cancelled_date_gmt": "",
      "end_date_gmt": "",
      "resubscribed_from": "",
      "resubscribed_subscription": "",
      "removed_line_items": [],
      "_links": {
        "self": [
          {
            "href": "https://example.com/wp-json/wc/v3/subscriptions/1313"
          }
        ],
        "collection": [
          {
            "href": "https://example.com/wp-json/wc/v3/subscriptions"
          }
        ],
        "customer": [
          {
            "href": "https://example.com/wp-json/wc/v3/customers/1"
          }
        ]
      }
    },{
      "id": 0,
      "parent_id": 0,
      "status": "",
      "currency": "",
      "version": "",
      "prices_include_tax": false,
      "date_created": "",
      "date_modified": "",
      "discount_total": "0.00",
      "discount_tax": "0.00",
      "shipping_total": "0.00",
      "shipping_tax": "0.00",
      "cart_tax": "0.00",
      "total": "00.00",
      "total_tax": "0.00",
      "customer_id": 0,
      "order_key": "",
      "billing": {
        "first_name": "",
        "last_name": "",
        "company": "",
        "address_1": "",
        "address_2": "",
        "city": "",
        "state": "",
        "postcode": "",
        "country": "",
        "email": "",
        "phone": ""
      },
      "shipping": {
        "first_name": "",
        "last_name": "",
        "company": "",
        "address_1": "",
        "address_2": "",
        "city": "",
        "state": "",
        "postcode": "",
        "country": "",
        "phone": ""
      },
      "payment_method": "",
      "payment_method_title": "",
      "customer_ip_address": "",
      "customer_user_agent": "",
      "created_via": "",
      "customer_note": "",
      "date_completed": null,
      "date_paid": "",
      "number": "",
      "meta_data": [
        {
          "id": 0,
          "key": "",
          "value": ""
        }
      ],
      "line_items": [
        {
          "id": 0,
          "name": "",
          "product_id": 0,
          "variation_id": 0,
          "quantity": 0,
          "tax_class": "",
          "subtotal": "00.00",
          "subtotal_tax": "0.00",
          "total": "0.00",
          "total_tax": "0.00",
          "taxes": [
            {
              "id": 0,
              "total": "",
              "subtotal": ""
            }
          ],
          "meta_data": [],
          "sku": "",
          "price": 0,
          "image": {
            "id": 0,
            "src": ""
          },
          "parent_name": ""
        }
      ],
      "tax_lines": [
        {
          "id": 0,
          "rate_code": "",
          "rate_id": 0,
          "label": "",
          "compound": true,
          "tax_total": "0.00",
          "shipping_tax_total": "0.00",
          "rate_percent": 10,
          "meta_data": []
        }
      ],
      "shipping_lines": [],
      "fee_lines": [],
      "coupon_lines": [],
      "payment_url": "https://devc.l4sb.com/checkout/order-pay/",
      "is_editable": false,
      "needs_payment": false,
      "needs_processing": true,
      "date_created_gmt": "",
      "date_modified_gmt": "",
      "date_completed_gmt": null,
      "date_paid_gmt": "",
      "actions": {
        "change_payment_method": {
          "url": "https://devc.l4sb.com/checkout/order-pay/",
          "name": "Change payment"
        }
      },
      "customerProfileId": "",
      "relatedOrders": {},
      "billing_period": "",
      "billing_interval": "",
      "start_date_gmt": "",
      "trial_end_date_gmt": "",
      "next_payment_date_gmt": "",
      "payment_retry_date_gmt": "",
      "last_payment_date_gmt": "",
      "cancelled_date_gmt": "",
      "end_date_gmt": "",
      "resubscribed_from": "",
      "resubscribed_subscription": "",
      "removed_line_items": [],
      "_links": {
        "self": [
          {
            "href": "https://example.com/wp-json/wc/v3/subscriptions/1313"
          }
        ],
        "collection": [
          {
            "href": "https://example.com/wp-json/wc/v3/subscriptions"
          }
        ],
        "customer": [
          {
            "href": "https://example.com/wp-json/wc/v3/customers/1"
          }
        ]
      }
    }
  ],
  loading: false,
  error: null,
  changePaymentMethodURL:"N/A",
}

const sanitizeUrl = (url) => {
  const decodedUrl = decodeURIComponent(url);
  const [baseUrl, queryString] = decodedUrl.split("?");
  const fixedQueryString = queryString ? queryString.replace(/&amp;/g, "&") : "";

  // Check if URL starts with "https://"
  if (baseUrl.startsWith("https://")) {
    return fixedQueryString ? `${baseUrl}?${fixedQueryString}` : baseUrl;
  }
  // Check if URL starts with "/wp-json/"
  else if (baseUrl.startsWith("/wp-json/")) {
    const wordpressDomain = process.env.REACT_APP_WP_SITE_URL;

    return fixedQueryString ? `${wordpressDomain}?${fixedQueryString}` : `${wordpressDomain}${baseUrl}`;
  }
  // Return the original URL if it doesn't match the conditions
  else {
    return decodedUrl;
  }
};

/**
 * Function to process the subscriptions array and update the changePaymentMethodURL.
 * @param {Array} subscriptions - The array of subscription objects.
 * @return {string} - The sanitized URL for change_payment_method or "N/A" if not found.
 */
function updateChangePaymentMethodURL(subscriptions) {
  // Default value for changePaymentMethodURL
  let changePaymentMethodURL = "N/A";

  // Iterate through the subscriptions to find the change_payment_method URL
  for (let subscription of subscriptions) {
    if (subscription.actions && subscription.actions.change_payment_method && subscription.actions.change_payment_method.url) {
      changePaymentMethodURL = sanitizeUrl(subscription.actions.change_payment_method.url);
      break; // Exit the loop once the first URL is found
    }
  }

  return changePaymentMethodURL;
}

// Example usage:
// On receiving the subscriptions (e.g., in the reducer's GET_SUBSCRIPTION_SUCCESS case):
// changePaymentMethodURL = updateChangePaymentMethodURL(receivedSubscriptions);


const subscriptionsState = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case actions.GET_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptions: action.payload,
        changePaymentMethodURL: updateChangePaymentMethodURL(action.payload),
      }
    case actions.GET_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default subscriptionsState
