import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import getMakeDefault from "../actions/getMakeDefault";

export const makeDefaultOnRevalidate = async (creditCards, expiry, lastFourDigits,dispatch,site_url, id) => {

    // Loop through the creditCards array
    for (let card of creditCards) {
        if (card.expires === expiry && card.method.last4 === lastFourDigits && card.method.gateway === "authorize_net_cim_credit_card") {
            // Check if the default action exists
            if (card.actions && card.actions.default) {
                const defaultURL = card.actions.default.url;

                // Making the dispatch request
                await dispatch(getMakeDefault(id, site_url + '/my-account' + defaultURL));

                break;  // Break out of the loop once a match is found and the dispatch is done
            }
        }
    }
}

