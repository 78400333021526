/**
 * getUserDetails Api is used to GET customer Details from WooCommerce
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import axios from 'axios'
import * as actions from './actionTypes'

/**
 * GET customer details from WooCommerce REST API.
 * @endpoint "/customers/:customerID"  Middleware customization and documentation.
 * @param id Customer Login userID to get user specific details.
 *
 * @return JSON Customer Details Object .
 */
export const getUserDetails = (id) => async (dispatch, getState) => {


  dispatch({ type: actions.GET_USER_DETAILS_REQUEST })
  try {
    const response = await axios.get(
        '/customers',{
          params: {
            customerId: id,
          }
        })
    dispatch({ type: actions.GET_USER_DETAILS_SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: actions.GET_USER_DETAILS_FAILURE, error })
  }
}

/**
 * EDIT customer details from WooCommerce REST API.
 * @endpoint "/customers/:customerID"  Middleware customization and documentation.
 * @param id Customer Login userID to get user specific details.
 * @param firstName
 * @param lastName
 * @param emailId
 * @return JSON Customer Details Object .
 */
export const editUserDetails = (id, firstName, lastName, emailId) => async (dispatch, getState) => {
  dispatch({ type: actions.EDIT_CUSTOMER_REQUEST })
  try {
    const response = await axios.put(
      '/customers', null, {
        params: {
          customerId: id,
          first_name: firstName,
          last_name: lastName,
          email: emailId
        }
      }
    )
    dispatch({ type: actions.EDIT_CUSTOMER_SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: actions.EDIT_CUSTOMER_FAILURE, error })
  }
}
