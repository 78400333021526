import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Typography, Divider, Tooltip, IconButton } from '@mui/material';
import { ArrowBack } from "@mui/icons-material";
import { Formik, Form, FieldArray } from 'formik';
import { MyTextInput, MyCheckbox, MySelect, MyTextArea, MyRadioGroup, questionMarkIconStyle, questionMarkIconSx } from "../../components/formik/FormikFields";
import "./companies.css";
import FormikMuiDatePicker from '../../components/FormikMuiDatePicker/FormikMuiDatePicker';
import dayjs from 'dayjs';
import {
    StyledArrowDropDownCircleIcon,
    companyTypes,
    IDTypes,
    AddCompanySchema,
    initialOwner,
    removeOwner,
    toggleOwnerVisibility,
    handleAddOwner,
    handleChange
} from "./CompanyFormUtils";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SaveIcon from '@mui/icons-material/Save';
import addCompanyCpt from "../../actions/addCompanyCpt";
import Autocomplete from 'react-google-autocomplete';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import InfoModal from '../../components/modal/InfoModal';

const AddCompanies = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userId = props.id;
    const companiesListData = props.companies;
    const userDetails = props.details;

    const googlePlacesApiKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

    const [nonce, setNonce] = useState(userDetails?._wpnonce || null);
    const [ownersVisibility, setOwnersVisibility] = useState({});
    const [showCustomTypeField, setShowCustomTypeField] = useState(false);
    const [addressInputValue, setAddressInputValue] = useState('');
    const [savedVirtualMailboxNumber, setSavedVirtualMailboxNumber] = useState('');
    const [mailingAddressInputValue, setMailingAddressInputValue] = useState('');
    const [beneficialOwnersAddresses, setBeneficialOwnersAddresses] = useState([]);
    const [beneficialOwnersMailingAddresses, setBeneficialOwnersMailingAddresses] = useState([]);

    const companyId = 0;

    const [isAddressLine1ModalOpen, setIsAddressLine1ModalOpen] = useState(false);
    const openAddressLine1Modal = () => setIsAddressLine1ModalOpen(true);
    const closeAddressLine1Modal = () => setIsAddressLine1ModalOpen(false);

    const [isAddressLine2ModalOpen, setIsAddressLine2ModalOpen] = useState(false);
    const openAddressLine2Modal = () => setIsAddressLine2ModalOpen(true);
    const closeAddressLine2Modal = () => setIsAddressLine2ModalOpen(false);

    const [isMailingAddressLine1ModalOpen, setIsMailingAddressLine1ModalOpen] = useState(false);
    const openMailingAddressLine1Modal = () => setIsMailingAddressLine1ModalOpen(true);
    const closeMailingAddressLine1Modal = () => setIsMailingAddressLine1ModalOpen(false);

    const [isMailingAddressLine2ModalOpen, setIsMailingAddressLine2ModalOpen] = useState(false);
    const openMailingAddressLine2Modal = () => setIsMailingAddressLine2ModalOpen(true);
    const closeMailingAddressLine2Modal = () => setIsMailingAddressLine2ModalOpen(false);

    const [isAddOwnerModalOpen, setIsAddOwnerModalOpen] = useState(false);
    const openAddOwnerModal = () => setIsAddOwnerModalOpen(true);
    const closeAddOwnerModal = () => setIsAddOwnerModalOpen(false);

    const [addressType, setAddressType] = useState('physical');

    useEffect(() => {
        if (userDetails && userDetails._wpnonce) {
            setNonce(userDetails._wpnonce);
        }
    }, [userDetails]);

    const handleSubmit = (values, { setSubmitting }) => {
        let finalValues = { ...values };
        const { title, company_type, state_of_domestication, reporting_company_id, boi_reporting_id, company_formation_date, company_applicant_id_1, company_applicant_id_2, base_address, mailing_address_same_as_address, mailing_address, beneficial_owners } = finalValues;
        dispatch(addCompanyCpt(userId, nonce, title, company_type, state_of_domestication, reporting_company_id, boi_reporting_id, company_formation_date, company_applicant_id_1, company_applicant_id_2, base_address, mailing_address_same_as_address, mailing_address, beneficial_owners, companyId, navigate));
        setSubmitting(false);
    };

    const handleAddressSelect = (place, setFieldValue, index, isMailing = false) => {
        const addressComponents = place.address_components;
        const streetNumber = addressComponents.find(component => component.types.includes("street_number"))?.long_name || "";
        const route = addressComponents.find(component => component.types.includes("route"))?.long_name || "";
        const formattedAddress = `${streetNumber} ${route}`;
        const secondaryAddress = addressComponents.find(component => component.types.includes("subpremise"))?.long_name || "";

        const addressPath = isMailing ? `beneficial_owners.${index}.mailing_address.` : `beneficial_owners.${index}.address.`;

        setFieldValue(addressPath + "address_1", formattedAddress);
        if (secondaryAddress) {
            setFieldValue(addressPath + "address_2", secondaryAddress);
        }

        setFieldValue(addressPath + "city", addressComponents.find(component => component.types.includes("locality"))?.long_name || "");
        setFieldValue(addressPath + "state", addressComponents.find(component => component.types.includes("administrative_area_level_1"))?.short_name || "");
        setFieldValue(addressPath + "zip_code", addressComponents.find(component => component.types.includes("postal_code"))?.long_name || "");
        setFieldValue(addressPath + "country", addressComponents.find(component => component.types.includes("country"))?.long_name || "");

        if (isMailing) {
            const updatedMailingAddresses = [...beneficialOwnersMailingAddresses];
            updatedMailingAddresses[index] = formattedAddress;
            setBeneficialOwnersMailingAddresses(updatedMailingAddresses);
        } else {
            const updatedAddresses = [...beneficialOwnersAddresses];
            updatedAddresses[index] = formattedAddress;
            setBeneficialOwnersAddresses(updatedAddresses);
        }
    };

    const handleAddressTypeChange = (value, setFieldValue) => {

        if (value === 'physical') {
            setSavedVirtualMailboxNumber(formik.values.virtual_mailbox_number);
            setFieldValue('virtual_mailbox_number', '');
        } else if (value === 'virtual') {
            setFieldValue('virtual_mailbox_number', savedVirtualMailboxNumber);
            setFieldValue('base_address.address_1', '');
            setFieldValue('base_address.address_2', '');
            setFieldValue('base_address.city', '');
            setFieldValue('base_address.state', '');
            setFieldValue('base_address.zip_code', '');
            setFieldValue('base_address.country', 'United States of America');
        }
        setAddressType(value);
    };

    const handleVirtualMailboxChange = (event, setFieldValue) => {
        const mailboxNumber = event.target.value;
        setFieldValue('virtual_mailbox_number', mailboxNumber);

        let address = '';
        if (mailboxNumber >= 0 && mailboxNumber <= 1000) {
            address = `317 Commercial St. NE Ste A PMB ${mailboxNumber}, Albuquerque, NM 87102`;
        } else if (mailboxNumber >= 1001 && mailboxNumber <= 2999) {
            address = `320 Gold Ave SW Ste 620 PMB ${mailboxNumber}, Albuquerque, NM 87102`;
        } else if (mailboxNumber >= 3000) {
            address = `6801 Jefferson St. NE Ste 150 PMB ${mailboxNumber}, Albuquerque, NM 87109`;
        }

        if (address) {
            setFieldValue('base_address.address_1', address.split(', ')[0]);
            setFieldValue('base_address.city', address.split(', ')[1]);
            setFieldValue('base_address.state', address.split(', ')[2].split(' ')[0]);
            setFieldValue('base_address.zip_code', address.split(', ')[2].split(' ')[1]);
            setFieldValue('base_address.country', 'United States of America');
            setFieldValue('mailing_address_same_as_address', true);
        }
    };

    return (
        <div className={"pageView"}>
            <div className={"pageWrapper"}>
                <Typography variant="h4" className={"pageTitle"}>
                    <div>
                        <button className={"backButton"} onClick={() => navigate(-1)}>
                            <ArrowBack fontSize={"medium"} />{" "}
                            <span className={"backButtonText"}>Go Back</span>
                        </button>
                    </div>
                    Add a Company
                </Typography>
                <Formik
                    initialValues={{
                        title: '',
                        company_type: '',
                        custom_company_type: '',
                        state_of_domestication: '',
                        reporting_company_id: '',
                        boi_reporting_date: '',
                        company_formation_date: '',
                        company_applicant_id_1: '',
                        company_applicant_id_2: '',
                        address_type: 'physical',
                        base_address: {
                            address_1: '',
                            address_2: '',
                            city: '',
                            state: '',
                            zip_code: '',
                            country: 'United States of America',
                        },
                        virtual_mailbox_number: '',
                        mailing_address: {
                            address_1: '',
                            address_2: '',
                            city: '',
                            state: '',
                            zip_code: '',
                            country: '',
                        },
                        mailing_address_same_as_address: true,
                        beneficial_owners: [],
                    }}
                    validationSchema={AddCompanySchema}
                    onSubmit={handleSubmit}
                    validateOnChange={false}
                    validateOnBlur={true}
                >
                    {(formik) => (
                        <Form className="formContainer">
                            <div className="companyColumns">
                                <div className="column">
                                    <MyTextInput label="Company Name" name="title" type="text" placeholder="Company Name" required fieldName="Company Name" description="This is the legal name of the company or other entity you’re adding (e.g., “Bob’s Tires, LLC”)."/>
                                    <MySelect label="Company Type" name="company_type" required fieldName='Company Type' description='This is the type of company or other entity you’re adding.' onChange={(e) => handleChange(formik.setFieldValue, showCustomTypeField, setShowCustomTypeField, e.target.value, 'company_type')}>
                                        <option value="">Select Company Type</option>
                                        {companyTypes.map(type => (
                                            <option value={type.value} key={type.value}>
                                                {type.label}
                                            </option>
                                        ))}
                                    </MySelect>

                                    <MyTextInput label="State of Formation" name="state_of_domestication" type="text" placeholder="State of Formation" required fieldName="State of Formation" description="This is the state in which your company or other entity was formed and primarily exists. For example, if you formed a Delaware corporation and registered it as a foreign entity in Wyoming, Florida, and Michigan, you’d select Delaware as the State of Domestication."/>
                                    <MyTextInput label="Reporting Company FinCEN ID" name="reporting_company_id" type="text" placeholder="0000-0000-0000" fieldName="Reporting Company FinCEN ID" description="This is the Financial Crimes Enforcement Network (FinCEN) identification number for the company. If you don’t have this and are using our CTA BOI Reporting Services, we’ll obtain this number for you."/>

                                    <FormikMuiDatePicker
                                        name={`boi_reporting_date`}
                                        label="BOIR Date"
                                        fieldName="BOIR Date"
                                        description="The exact date on which the Beneficial Ownership Information for the company was reported."
                                        maxDate={dayjs().subtract(0, 'day')}
                                    />

                                    <FormikMuiDatePicker
                                        name={`company_formation_date`}
                                        label="Company Formation Date"
                                        fieldName="Company Formation Date"
                                        description="The date on which the company was registered/formed in the particular state of formation/domestication."
                                    />

                                    <MyTextInput label="Company Applicant ID 1" name="company_applicant_id_1" type="text" placeholder="0000-0000-0000" fieldName="Company Applicant ID 1" description="This is the Financial Crimes Enforcement Network (FinCen) identification number for the primary party that filed your initial CTA BOI report."/>
                                    <MyTextInput label="Company Applicant ID 2" name="company_applicant_id_2" type="text" placeholder="0000-0000-0000" fieldName="Company Applicant ID 2" description="This is the Financial Crimes Enforcement Network (FinCen) identification number for the secondary party that filed your initial CTA BOI report."/>

                                    <MyRadioGroup
                                        label="Select Address Type"
                                        name="address_type"
                                        required
                                        fieldName="Address Type"
                                        description="Select whether to use a physical address or an L4SB Virtual Mailbox."
                                        options={[
                                            { value: 'physical', label: 'Use Physical Address' },
                                            { value: 'virtual', label: 'Use L4SB Virtual Mailbox' }
                                        ]}
                                        value={formik.values.address_type}
                                        onChange={(e) => handleAddressTypeChange(e.target.value, formik.setFieldValue)}
                                    />

                                    {formik.values.address_type === 'physical' ? (
                                        <div className="addressBox">
                                            <div className="addressBoxTitle">Principal Address</div>
                                            <Divider/>
                                            <br/>

                                            <div className="formFieldInput">
                                                <label className="formikLabel" htmlFor="address_1">
                                                    Address Line 1 <span style={{ color: 'red' }}>*</span>
                                                    <IconButton
                                                        aria-label="help"
                                                        size="small"
                                                        onClick={openAddressLine1Modal}
                                                        sx={questionMarkIconStyle}
                                                    >
                                                        <QuestionMarkIcon fontSize="inherit" sx={questionMarkIconSx} />
                                                    </IconButton>
                                                </label>
                                                <Autocomplete
                                                    apiKey={googlePlacesApiKey}
                                                    className={`formikInput`}
                                                    onChange={(event) => {
                                                        setAddressInputValue(event.target.value);
                                                        formik.setFieldValue("base_address.address_1", event.target.value);
                                                        formik.setFieldTouched("base_address.address_1", true);
                                                    }}
                                                    onPlaceSelected={(place) => {
                                                        const addressComponents = place.address_components;
                                                        const streetNumber = addressComponents.find(component => component.types.includes("street_number"))?.long_name || "";
                                                        const route = addressComponents.find(component => component.types.includes("route"))?.long_name || "";
                                                        const formattedAddress = `${streetNumber} ${route}`;
                                                        const secondaryAddress = addressComponents.find(component => component.types.includes("subpremise"))?.long_name || "";
                                                        formik.setFieldValue("base_address.address_1", formattedAddress);
                                                        setAddressInputValue(formattedAddress);

                                                        if (secondaryAddress) {
                                                            formik.setFieldValue("base_address.address_2", secondaryAddress);
                                                        }

                                                        formik.setFieldValue("base_address.city", addressComponents.find(component => component.types.includes("locality"))?.long_name || "");
                                                        formik.setFieldValue("base_address.state", addressComponents.find(component => component.types.includes("administrative_area_level_1"))?.short_name || "");
                                                        formik.setFieldValue("base_address.zip_code", addressComponents.find(component => component.types.includes("postal_code"))?.long_name || "");
                                                        formik.setFieldValue("base_address.country", addressComponents.find(component => component.types.includes("country"))?.long_name || "");
                                                        formik.setFieldTouched("base_address.address_1", true);
                                                    }}
                                                    options={{
                                                        types: ['address'],
                                                        componentRestrictions: { country: "us" },
                                                    }}
                                                    defaultValue={formik.values.base_address.address_1}
                                                    value={addressInputValue}
                                                    placeholder="Address Line 1"
                                                    name="base_address.address_1"
                                                    type="input"
                                                />
                                                {formik.touched.base_address?.address_1 && formik.errors.base_address?.address_1 && (
                                                    <div className="error">{formik.errors.base_address.address_1}</div>
                                                )}
                                            </div>

                                            <InfoModal
                                                open={isAddressLine1ModalOpen}
                                                handleClose={closeAddressLine1Modal}
                                                fieldName="Address Line 1"
                                                description="This is the primary physical/street address of the company or other entity."
                                            />

                                            <MyTextInput label="Address Line 2" name="base_address.address_2" type="text" placeholder="Address Line 2" fieldName="Address Line 2" description="This is the secondary address (e.g., Suite number)."/>
                                            <MyTextInput label="City" name="base_address.city" type="text" placeholder="City" required fieldName="City" description="This is the city, town, or other locality in which the address is located."/>
                                            <MyTextInput label="State" name="base_address.state" type="text" placeholder="State" required fieldName="State" description="This is the state in which the address is located."/>
                                            <MyTextInput label="Zip Code" name="base_address.zip_code" type="text" placeholder="Zip Code" required fieldName="Zip Code" description="This is the Zip or postal code of the company address."/>
                                            <MyTextInput label="Country" name="base_address.country" type="text" placeholder="Country" required value="United States of America" readOnly fieldName="Country" description="This is the country in which the address is located."/>
                                        </div>
                                    ) : (
                                        <MyTextInput
                                            label="L4SB Virtual Mailbox Number"
                                            name="virtual_mailbox_number"
                                            type="text"
                                            placeholder="Enter Virtual Mailbox Number"
                                            fieldName="L4SB Virtual Mailbox Number"
                                            description="Enter the L4SB Virtual Mailbox number if you know it."
                                            onChange={(e) => handleVirtualMailboxChange(e, formik.setFieldValue)}
                                        />
                                    )}

                                    {formik.values.address_type === 'physical' && (
                                        <>
                                            <MyCheckbox name="mailing_address_same_as_address" label="Mailing Address Same as Company Address" fieldName="Mailing Address Same as Company Address" description="If checked, we’ll treat the Company Address as the Company’s Mailing Address. Do not check this box if you have a separate address you want to use, including an L4SB Virtual Mailbox or other virtual mailbox/office service."/>

                                            {!formik.values.mailing_address_same_as_address && (
                                                <div className="addressBox">
                                                    <div className="addressBoxTitle">Company Mailing Address</div>
                                                    <Divider/>
                                                    <br/>

                                                    <div className="formFieldInput">
                                                        <label className="formikLabel" htmlFor="mailing_address_1">
                                                            Address Line 1 <span style={{ color: 'red' }}>*</span>
                                                            <IconButton
                                                                aria-label="help"
                                                                size="small"
                                                                onClick={openMailingAddressLine1Modal}
                                                                sx={questionMarkIconStyle}
                                                            >
                                                                <QuestionMarkIcon fontSize="inherit" sx={questionMarkIconSx} />
                                                            </IconButton>
                                                        </label>
                                                        <Autocomplete
                                                            apiKey={googlePlacesApiKey}
                                                            className={`formikInput`}
                                                            onChange={(event) => {
                                                                setMailingAddressInputValue(event.target.value);
                                                                formik.setFieldValue("mailing_address.address_1", event.target.value);
                                                                formik.setFieldTouched("mailing_address.address_1", true);
                                                            }}
                                                            onPlaceSelected={(place) => {
                                                                const addressComponents = place.address_components;
                                                                const streetNumber = addressComponents.find(component => component.types.includes("street_number"))?.long_name || "";
                                                                const route = addressComponents.find(component => component.types.includes("route"))?.long_name || "";
                                                                const formattedAddress = `${streetNumber} ${route}`;
                                                                const secondaryAddress = addressComponents.find(component => component.types.includes("subpremise"))?.long_name || "";
                                                                formik.setFieldValue("mailing_address.address_1", formattedAddress);
                                                                setMailingAddressInputValue(formattedAddress);

                                                                if (secondaryAddress) {
                                                                    formik.setFieldValue("mailing_address.address_2", secondaryAddress);
                                                                }

                                                                formik.setFieldValue("mailing_address.city", addressComponents.find(component => component.types.includes("locality"))?.long_name || "");
                                                                formik.setFieldValue("mailing_address.state", addressComponents.find(component => component.types.includes("administrative_area_level_1"))?.short_name || "");
                                                                formik.setFieldValue("mailing_address.zip_code", addressComponents.find(component => component.types.includes("postal_code"))?.long_name || "");
                                                                formik.setFieldValue("mailing_address.country", addressComponents.find(component => component.types.includes("country"))?.long_name || "");
                                                                formik.setFieldTouched("mailing_address.address_1", true);
                                                            }}
                                                            options={{
                                                                types: ['address'],
                                                            }}
                                                            defaultValue={formik.values.mailing_address.address_1}
                                                            value={mailingAddressInputValue}
                                                            placeholder="Address Line 1"
                                                            name="mailing_address.address_1"
                                                            type="input"
                                                        />
                                                        {formik.touched.mailing_address?.address_1 && formik.errors.mailing_address?.address_1 && (
                                                            <div className="error">{formik.errors.mailing_address.address_1}</div>
                                                        )}
                                                    </div>

                                                    <InfoModal
                                                        open={isMailingAddressLine1ModalOpen}
                                                        handleClose={closeMailingAddressLine1Modal}
                                                        fieldName="Address Line 1"
                                                        description="This is the primary mailing address of the company or other entity."
                                                    />

                                                    <MyTextInput label="Address Line 2" name="mailing_address.address_2" type="text" placeholder="Address Line 2" fieldName="Address Line 2" description="This is the secondary address (e.g., Suite number, PMB number)."/>
                                                    <MyTextInput label="City" name="mailing_address.city" type="text" placeholder="City" required fieldName="City" description="This is the city, town, or other locality in which the address is located."/>
                                                    <MyTextInput label="State" name="mailing_address.state" type="text" placeholder="State" required fieldName="State" description="This is the state in which the address is located."/>
                                                    <MyTextInput label="Zip Code" name="mailing_address.zip_code" type="text" placeholder="Zip Code" required fieldName="Zip Code" description="This is the Zip or postal code of the company address."/>
                                                    <MyTextInput label="Country" name="mailing_address.country" type="text" placeholder="Country" required fieldName="Country" description="This is the country in which the address is located."/>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                <div className="column">
                                    <FieldArray name="beneficial_owners">
                                        {({ insert, remove, push }) => (
                                            <>
                                                {formik.values.beneficial_owners.map((owner, index) => (
                                                    <div style={{
                                                        border: '1px solid #ccc',
                                                        padding: '10px',
                                                        marginBottom: '10px',
                                                        borderRadius: '5px'
                                                    }}>
                                                        <div key={index}>
                                                            <div className="beneficialOwnerName">
                                                                <Tooltip title={ownersVisibility[index] ? "Hide" : "Expand"} placement="top">
                                                                    <IconButton>
                                                                        <StyledArrowDropDownCircleIcon
                                                                            onClick={() => toggleOwnerVisibility(ownersVisibility, setOwnersVisibility, index)}
                                                                            style={{
                                                                                transform: ownersVisibility[index] ? 'rotate(180deg)' : 'rotate(0deg)',
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <span>Beneficial Owner {index + 1} Information</span>
                                                                {removeOwner(remove, index)}
                                                            </div>
                                                            {ownersVisibility[index] && (
                                                                <>
                                                                    <Divider/>
                                                                    <br/>
                                                                    <MyTextInput label={`First Name`} name={`beneficial_owners.${index}.first_name`} type="text" placeholder="Owner's First Name" required fieldName="First Name" description="The first name of the beneficial owner."/>
                                                                    <MyTextInput label={`Last Name`} name={`beneficial_owners.${index}.last_name`} type="text" placeholder="Owner's Last Name" required fieldName="Last Name" description="The last name of the beneficial owner."/>
                                                                    <MyTextInput label="Email" name={`beneficial_owners.${index}.email_id`} type="email" placeholder="Email" required fieldName="Email" description="The email address of the beneficial owner."/>
                                                                    <MyTextInput label="Phone Number" name={`beneficial_owners.${index}.phone_number`} type="text" placeholder="Phone Number" required fieldName="Phone Number" description="The contact number of the beneficial owner."/>
                                                                    <MyTextInput label="FinCEN ID" name={`beneficial_owners.${index}.beneficial_owners_fincen_id`} type="text" placeholder="FinCEN ID" fieldName="FinCEN ID" description="The FinCEN identification number for the beneficial owner, if one has already been obtained for them."/>
                                                                    <MyTextInput label="Percentage Owned" name={`beneficial_owners.${index}.percentage_owned`} type="text" placeholder="Percentage Owned" fieldName="Percentage Owned" description="The percentage of the company owned by this owner." />
                                                                    <FormikMuiDatePicker
                                                                        name={`beneficial_owners.${index}.date_of_ownership`}
                                                                        label="Date of Ownership"
                                                                        fieldName="Date of Ownership"
                                                                        description="The date when the beneficial owner acquired their interests in the company."
                                                                        maxDate={dayjs().subtract(1, 'day')}
                                                                    />

                                                                    <MyTextInput label="Last 4 SSN/ITIN" name={`beneficial_owners.${index}.last_4_ssn_or_itin`} type="text" placeholder="Last 4 SSN/ITIN" fieldName="Last 4 SSN/ITIN" description="The last four digits of the beneficial owner’s Social Security Number (SSN) or Individual Taxpayer Identification Number (ITIN)."/>
                                                                    <FormikMuiDatePicker
                                                                        name={`beneficial_owners.${index}.expiry_date_of_boi_id`}
                                                                        label="ID Expiry Date"
                                                                        fieldName="ID Expiry Date"
                                                                        description="The date on which the ID expires or ceases to be valid. To add an ID here, the ID cannot already be expired."
                                                                        minDate={dayjs().add(1, 'day')}
                                                                    />

                                                                    <MySelect label="ID Type" name={`beneficial_owners.${index}.type_of_boi_id`} type="text" placeholder="Type of ID" fieldName='ID Type' description='The type of identification document provided by the beneficial owner for filing the Beneficial Ownership Report.' onChange={(e) => handleChange(formik.setFieldValue, showCustomTypeField, setShowCustomTypeField, e.target.value, `beneficial_owners.${index}.type_of_boi_id`)}>
                                                                        <option value="">Select ID Type</option>
                                                                        {IDTypes.map(type => (
                                                                            <option value={type.value} key={type.value}>
                                                                                {type.label}
                                                                            </option>
                                                                        ))}
                                                                    </MySelect>

                                                                    <MyTextArea label="Additional Info" name={`beneficial_owners.${index}.additional_info`} type="text" placeholder="Additional Info" rows={3} fieldName="Additional Info" description="Please provide any additional information you’d like our records to show about this Beneficial Owner. For example, you might mention that they’re a nonresident alien (defined for tax purposes as neither a citizen nor a lawful permanent resident of the United States), which may impact the company’s ability to make certain tax elections."/>

                                                                    <div className="addressBox">
                                                                        <div className="addressBoxTitle">Address</div>
                                                                        <Divider/>
                                                                        <br/>

                                                                        <div className="formFieldInput">
                                                                            <label className="formikLabel" htmlFor={`beneficial_owners.${index}.address.address_1`}>
                                                                                Address Line 1 <span style={{ color: 'red' }}>*</span>
                                                                                <IconButton
                                                                                    aria-label="help"
                                                                                    size="small"
                                                                                    onClick={openAddressLine2Modal}
                                                                                    sx={questionMarkIconStyle}
                                                                                >
                                                                                    <QuestionMarkIcon fontSize="inherit" sx={questionMarkIconSx} />
                                                                                </IconButton>
                                                                            </label>
                                                                            <Autocomplete
                                                                                apiKey={googlePlacesApiKey}
                                                                                className={`formikInput`}
                                                                                onChange={(event) => {
                                                                                    const updatedAddresses = [...beneficialOwnersAddresses];
                                                                                    updatedAddresses[index] = event.target.value;
                                                                                    setBeneficialOwnersAddresses(updatedAddresses);
                                                                                    formik.setFieldValue(`beneficial_owners.${index}.address.address_1`, event.target.value);
                                                                                    formik.setFieldTouched(`beneficial_owners.${index}.address.address_1`, true);
                                                                                }}
                                                                                onPlaceSelected={(place) => {
                                                                                    handleAddressSelect(place, formik.setFieldValue, index);
                                                                                    formik.setFieldTouched(`beneficial_owners.${index}.address.address_1`, true, false);

                                                                                    const addressComponents = place.address_components;
                                                                                    const streetNumber = addressComponents.find(component => component.types.includes("street_number"))?.long_name || "";
                                                                                    const route = addressComponents.find(component => component.types.includes("route"))?.long_name || "";
                                                                                    const formattedAddress = `${streetNumber} ${route}`;

                                                                                    const updatedAddresses = [...beneficialOwnersAddresses];
                                                                                    updatedAddresses[index] = formattedAddress;
                                                                                    setBeneficialOwnersAddresses(updatedAddresses);
                                                                                }}
                                                                                options={{
                                                                                    types: ['address'],
                                                                                }}
                                                                                defaultValue={owner?.address?.address_1 || ''}
                                                                                placeholder="Address Line 1"
                                                                                name={`beneficial_owners.${index}.address.address_1`}
                                                                                value={beneficialOwnersAddresses[index] || ''}
                                                                            />
                                                                            {formik.touched.beneficial_owners?.[index]?.address?.address_1 && formik.errors.beneficial_owners?.[index]?.address?.address_1 && (
                                                                                <div className="error">{formik.errors.beneficial_owners[index].address.address_1}</div>
                                                                            )}
                                                                        </div>

                                                                        <InfoModal
                                                                            open={isAddressLine2ModalOpen}
                                                                            handleClose={closeAddressLine2Modal}
                                                                            fieldName="Address Line 1"
                                                                            description="This is the primary physical/street address of the beneficial owner."
                                                                        />

                                                                        <MyTextInput label="Address Line 2" name={`beneficial_owners.${index}.address.address_2`} type="text" placeholder="Address Line 2" fieldName="Address Line 2" description="This is the secondary address (e.g., Suite number)."/>
                                                                        <MyTextInput label="City" name={`beneficial_owners.${index}.address.city`} type="text" placeholder="City" required fieldName="City" description="This is the city, town, or other locality in which the address is located."/>
                                                                        <MyTextInput label="State" name={`beneficial_owners.${index}.address.state`} type="text" placeholder="State" required fieldName="State" description="This is the state in which the address is located."/>
                                                                        <MyTextInput label="Zip Code" name={`beneficial_owners.${index}.address.zip_code`} type="text" placeholder="Zip Code" required fieldName="Zip Code" description="This is the Zip or postal code of the company address."/>
                                                                        <MyTextInput label="Country" name={`beneficial_owners.${index}.address.country`} type="text" placeholder="Country" required fieldName="Country" description="This is the country in which the address is located."/>
                                                                    </div>

                                                                    <MyCheckbox name={`beneficial_owners.${index}.mailing_address_same_as_address`} label="Mailing Address Same as Address" fieldName="Mailing Address Same as Address" description="If checked, we’ll treat the beneficial owner’s primary address as their mailing address. Do not check this box if you have a separate address you want to use."/>

                                                                    {!formik.values.beneficial_owners[index].mailing_address_same_as_address && (
                                                                        <div className="addressBox">
                                                                            <div className="addressBoxTitle">Mailing Address</div>
                                                                            <Divider/>
                                                                            <br/>

                                                                            <div className="formFieldInput">
                                                                                <label className="formikLabel" htmlFor={`beneficial_owners.${index}.mailing_address.address_1`}>
                                                                                    Address Line 1 <span style={{ color: 'red' }}>*</span>
                                                                                    <IconButton
                                                                                        aria-label="help"
                                                                                        size="small"
                                                                                        onClick={openMailingAddressLine2Modal}
                                                                                        sx={questionMarkIconStyle}
                                                                                    >
                                                                                        <QuestionMarkIcon fontSize="inherit" sx={questionMarkIconSx} />
                                                                                    </IconButton>
                                                                                </label>
                                                                                <Autocomplete
                                                                                    apiKey={googlePlacesApiKey}
                                                                                    className={`formikInput`}
                                                                                    onChange={(event) => {
                                                                                        const updatedMailingAddresses = [...beneficialOwnersMailingAddresses];
                                                                                        updatedMailingAddresses[index] = event.target.value;
                                                                                        setBeneficialOwnersMailingAddresses(updatedMailingAddresses);
                                                                                        formik.setFieldValue(`beneficial_owners.${index}.mailing_address.address_1`, event.target.value);
                                                                                        formik.setFieldTouched(`beneficial_owners.${index}.mailing_address.address_1`, true);
                                                                                    }}
                                                                                    onPlaceSelected={(place) => {
                                                                                        handleAddressSelect(place, formik.setFieldValue, index, true);
                                                                                        formik.setFieldTouched(`beneficial_owners.${index}.mailing_address.address_1`, true, false);

                                                                                        const addressComponents = place.address_components;
                                                                                        const streetNumber = addressComponents.find(component => component.types.includes("street_number"))?.long_name || "";
                                                                                        const route = addressComponents.find(component => component.types.includes("route"))?.long_name || "";
                                                                                        const formattedAddress = `${streetNumber} ${route}`;

                                                                                        const updatedMailingAddresses = [...beneficialOwnersMailingAddresses];
                                                                                        updatedMailingAddresses[index] = formattedAddress;
                                                                                        setBeneficialOwnersMailingAddresses(updatedMailingAddresses);
                                                                                    }}
                                                                                    options={{
                                                                                        types: ['address'],
                                                                                    }}
                                                                                    defaultValue={owner.mailing_address.address_1}
                                                                                    placeholder="Mailing Address Line 1"
                                                                                    name={`beneficial_owners.${index}.mailing_address.address_1`}
                                                                                    value={beneficialOwnersMailingAddresses[index] || ''}
                                                                                />
                                                                                {formik.touched.beneficial_owners?.[index]?.mailing_address?.address_1 && formik.errors.beneficial_owners?.[index]?.mailing_address?.address_1 && (
                                                                                    <div className="error">{formik.errors.beneficial_owners[index].mailing_address.address_1}</div>
                                                                                )}
                                                                            </div>

                                                                            <InfoModal
                                                                                open={isMailingAddressLine2ModalOpen}
                                                                                handleClose={closeMailingAddressLine2Modal}
                                                                                fieldName="Address Line 1"
                                                                                description="This is the primary mailing address of the beneficial owner."
                                                                            />

                                                                            <MyTextInput label="Address Line 2" name={`beneficial_owners.${index}.mailing_address.address_2`} type="text" placeholder="Address Line 2" fieldName="Address Line 2" description="This is the primary mailing address of the beneficial owner."/>
                                                                            <MyTextInput label="City" name={`beneficial_owners.${index}.mailing_address.city`} type="text" placeholder="City" required fieldName="City" description="This is the city, town, or other locality in which the address is located."/>
                                                                            <MyTextInput label="State" name={`beneficial_owners.${index}.mailing_address.state`} type="text" placeholder="State" required fieldName="State" description="This is the state in which the address is located."/>
                                                                            <MyTextInput label="Zip Code" name={`beneficial_owners.${index}.mailing_address.zip_code`} type="text" placeholder="Zip Code" required fieldName="Zip Code" description="This is the Zip or postal code of the company address."/>
                                                                            <MyTextInput label="Country" name={`beneficial_owners.${index}.mailing_address.country`} type="text" placeholder="Country" required fieldName="Country" description="This is the country in which the address is located."/>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="buttonContainer">
                                                    <button type="button" style={{ marginLeft: '10px' }} className="wooButtons" onClick={() => handleAddOwner(push, ownersVisibility, setOwnersVisibility)}>
                                                        <span className="AddBOIButton">
                                                            <PersonAddIcon className="icon"/>
                                                            <span className="buttonText">
                                                                {formik.values.beneficial_owners.length === 0 ? 'Add Beneficial Owner' : 'Add Another Beneficial Owner'}
                                                            </span>
                                                        </span>
                                                    </button>
                                                    <IconButton aria-label="help" size="small" onClick={openAddOwnerModal} sx={questionMarkIconStyle}>
                                                        <QuestionMarkIcon fontSize="inherit" sx={questionMarkIconSx} />
                                                    </IconButton>
                                                </div>

                                                <InfoModal
                                                    open={isAddOwnerModalOpen}
                                                    handleClose={closeAddOwnerModal}
                                                    fieldName="Add Beneficial Owner"
                                                    description={
                                                        <div>
                                                            <p>
                                                                This section will allow you to add a Beneficial Owner of the Company/other entity.
                                                                This term has a very specific meaning and relates to the Corporate Transparency Act which took effect at the beginning of 2024.
                                                                If you’re not familiar with the Act and the new legal requirements it imposes on business owners,{" "}
                                                                <a href="https://www.l4sb.com/blog/starting-1-1-2024-small-business-owners-must-start-reporting-to-fincen-or-face-fines/" target="_blank" rel="noopener noreferrer">we recommend reading our Blog for more information.</a>
                                                            </p>
                                                            <p>
                                                                If you’re unsure of who the Beneficial Owners of your company or other entity are, we’ve created a helpful tool to help you figure that out. We suggest you {" "}
                                                                <a href="https://docs.l4sb.com/start/CTABOIReporting/" target="_blank" rel="noopener noreferrer">run through the tool</a> and then list all of the Beneficial Owners here.
                                                            </p>
                                                        </div>
                                                    }
                                                />
                                            </>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>
                            <br/>
                            <br/>
                            <div className="buttonContainer">
                                <button type="submit" className={"wooButtons"} style={{ width: 'fit-content' }} disabled={formik.isSubmitting}>
                                    <span className="AddBOIButton">
                                        <SaveIcon className="icon"/>
                                        <span className="buttonText">
                                            Save Company
                                        </span>
                                    </span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default AddCompanies;
