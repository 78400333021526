import "./billingAddress.css"
// import "../../styles/style.scss";
import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import BillingAddressForm from "../../components/formik/BillingAddressForm";
import { Box, Typography } from '@mui/material';

export default function BillingAddress(props){

    const id =  props.id;
    const userDetails = useSelector((state) => state.userDetailsState.userDetails);

    const {billing : { first_name :firstName, last_name: lastName,company,address_1: address1,
        address_2: address2,city, state, postcode,country,phone,email }} = userDetails;

    const userBillingData = {
        firstName,
        lastName,
        company,
        address1,
        address2,
        city,
        state,
        postcode,
        country,
        phone,
        email
    }

    return (
        <div className={"pageView"}>
            <div className={"pageWrapper"}>
                <Typography variant="h4" className={"pageTitle"}></Typography>
                <div className={"formIO"}>
                    <BillingAddressForm id={id} billingData={userBillingData}/>
                </div>
            </div>
        </div>
    )
}