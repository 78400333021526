/**
 * Payment Methods State Reducer is used to store all Saved payment methods List
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import * as actions from '../actions/actionTypes'
import {useState} from "react";

const initState = {
  paymentMethods: [],
  addedCards:[],
  loading: false,
  loadingGet: false,
  loadingAdd: false,
  error: null,
  cardAdded: false,
  madeDefault: null,
  cardDeleted: null,
  subscriptionsUpdated: null,
  currDefault: null,
  changedPaymentmethod: null
}

const paymentMethodsState = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        loading: true,
        loadingGet: true,
        error: null,
        cardAdded: false
      }
    case actions.GET_PAYMENT_METHODS_SUCCESS:
        return {
          ...state,
          loading: false,
          loadingGet: false,
          cardAdded: false,
          paymentMethods: action.payload,
        }
    case actions.GET_PAYMENT_METHODS_FAILURE:
      return {
        ...state,
        loading: false,
        loadingGet: false,
        cardAdded: false,
        error: action.error
      }
    case actions.ADD_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
        loadingAdd: true,
        error: null,
        cardAdded: false
      }
    case actions.ADD_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingAdd: false,
        cardAdded: true,
        paymentMethods: action.payload,
        addedCards: action.payload
      }
    case actions.ADD_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        loading: false,
        loadingAdd: false,
        cardAdded: false,
        error: action.error
      }
    case actions.CHANGE_PAYMENT_METHOD_FOR_ALL_SUBS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        changedPaymentmethod: false,
      }
    case actions.CHANGE_PAYMENT_METHOD_FOR_ALL_SUBS_SUCCESS:
      return {
        ...state,
        loading: false,
        changedPaymentmethod: true,
      }
    case actions.CHANGE_PAYMENT_METHOD_FOR_ALL_SUBS_FAILURE:
      return {
        ...state,
        loading: false,
        changedPaymentmethod: false,
        error: action.error
      }
    case actions.GET_MAKE_DEFAULT_REQUEST:
      return {
        ...state,
        loading: true,
        madeDefault: action.payload
      }
    case actions.GET_MAKE_DEFAULT_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentMethods: action.payload,
      }
    case actions.GET_MAKE_DEFAULT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.GET_UPDATE_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        madeDefault: false
        // subscriptionsUpdated: action.payload
      }
    case actions.GET_UPDATE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case actions.GET_UPDATE_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
      }
    case actions.DELETE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
        cardDeleted: false,
      }
    case actions.DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        cardDeleted: true,
        paymentMethods: action.payload
      }
    case actions.DELETE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        loading: false,
        cardDeleted: null,
        error: action.error
      }
    default:
      return state
  }
}

export default paymentMethodsState


// const comparePaymentMethods = (prevState, newState) => {
//   if (prevState.length !== newState.length) {
//     return { cardDeleted: true };
//   }
//
//   const defaultChanged = prevState.some((method, index) => method.is_default !== newState[index]?.is_default);
//   if (defaultChanged) {
//     return { madeDefault: true };
//   }
//
//   // Add other conditions for updateSubscriptions based on the changes in the payment methods state
//
//   // Condition for updateSubscriptions based on the changes in the payment methods state
//   const subscriptionsUpdated = prevState.some((method, index) => {
//     if (!Array.isArray(method.subscriptions) || !Array.isArray(newState[index]?.subscriptions)) {
//       return false;
//     }
//
//     if (method.subscriptions.length !== newState[index]?.subscriptions.length) {
//       return true;
//     }
//
//     const sortedOldSubs = method.subscriptions.slice().sort();
//     const sortedNewSubs = newState[index]?.subscriptions.slice().sort();
//
//     return sortedOldSubs.some((sub, idx) => sub !== sortedNewSubs[idx]);
//   });
//
//   if (subscriptionsUpdated) {
//     return { subscriptionsUpdated: true };
//   }
//
//   return {};
// };