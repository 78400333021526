/**
 * getMakeDefault Api is used to Make Dafault a Saved Payment Methods List Details from WooCommerce for the user.
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import axios from 'axios'
import * as actions from './actionTypes'
import getPaymentMethods from './getPaymentMethods'
import {toastr} from "react-redux-toastr";
import { toast, ToastContainer } from 'react-toastify';

/**
 * Make Default a saved Payment Method from List details from WooCommerce REST API.
 * @endpoint "https://devc.l4sb.com/wp-json/wc/v3/payment-methods/make-default  Middleware customization and documentation.
 *
 * @return JSON Payment Methods List Object .
 */
const deletePaymentMethod = (id,url,numberOfCards) => async (dispatch, getState) => {

    return new Promise(async (resolve, reject) => {

        dispatch({ type: actions.DELETE_PAYMENT_METHOD_REQUEST });
        try {
            const deleteResponse = await axios.get('/paymentmethods/delete', {
                    params: { url, id }
            });
            dispatch({ type: actions.DELETE_PAYMENT_METHOD_SUCCESS, payload: deleteResponse.data });

            const { cc: newCreditCards, pp: newPaypalPayments } = deleteResponse.data;
            const newCount = newCreditCards.length;

            if (numberOfCards && newCount && numberOfCards !== newCount) {
                toast.success('Successfully Deleted Card', 'Deleted Card!');
                localStorage.setItem('deletePrevious','true');
            } else {
                toast.error('Failed deleting payment method. Please try again!');
                localStorage.removeItem('deletePrevious');
            }

            resolve(true);

        } catch (error) {
            dispatch({ type: actions.DELETE_PAYMENT_METHOD_FAILURE, error });
            toast.error('Failed deleting payment method. Please try again!');
            reject(error);
        }
    });
}

export default deletePaymentMethod;
