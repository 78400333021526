/**
 * Account Settings and Name Initials Displaying Functional Component available on the TopBar
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */
import * as React from 'react';
import {Tooltip,IconButton,Divider,Box, Avatar,Menu, MenuItem, ListItemIcon,CircularProgress } from '@mui/material';
import { Logout , Call} from '@mui/icons-material';
import {useSelector} from "react-redux";

export default function SettingsAvatar() {

    const userDetailsLoad =  useSelector((state) => state.userDetailsState.loading);
    const subscriptionLoad = useSelector((state) => state.subscriptionsState.loading);
    const paymentMethodsLoad =  useSelector((state) => state.paymentMethodsState.loading);
    const ordersLoad = useSelector((state) => state.ordersState.loading);
    const userMeta =  useSelector((state) => state.userDetailsState.userDetails);
    const { id,first_name, last_name} = userMeta;

    const firstInit = first_name && first_name.slice(0,1);
    const lastInit = last_name && last_name.slice(0,1);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (userDetailsLoad === false ) ? (
//&& paymentMethodsLoad === false && subscriptionLoad === false && ordersLoad === false
        <div className={"settingsAvatar"}>
            <Tooltip title="Account settings">
                <Avatar sx={{ width: 64, height: 64,fontSize: '2.25rem',color: '#7d0029',bgcolor: '#f0f0f0' }}>{firstInit}{lastInit}</Avatar>
            </Tooltip>
        </div>
    ) : (
        <div>
            <CircularProgress/>
        </div>
    )
}
