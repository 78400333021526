import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import IconButton from '@mui/material/IconButton';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import InfoModal from '../modal/InfoModal';
import '../formik/formik.css';
import {questionMarkIconStyle, questionMarkIconSx} from "../formik/FormikFields";

const FormikMuiDatePicker = ({ name, label, minDate, maxDate, fieldName, description, link, ...otherProps }) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const onQuestionClick = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    const formattedValue = field.value ? dayjs(field.value, "MM/DD/YYYY") : null;

    const handleChange = (value) => {
        setFieldValue(name, value ? value.format('MM/DD/YYYY') : '');
    };

    return (
        <div className="formFieldInput">
            <label className="formikLabel" htmlFor={name}>
                {label}
                {(fieldName || description) && (
                    <IconButton
                        aria-label="help"
                        size="small"
                        onClick={onQuestionClick}
                        sx={questionMarkIconStyle}
                    >
                        <QuestionMarkIcon fontSize="inherit" sx={questionMarkIconSx} />
                    </IconButton>
                )}
            </label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    {...field}
                    {...otherProps}
                    value={formattedValue || null}
                    onChange={handleChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    renderInput={(params) => (
                        <input
                            {...params.inputProps}
                            className={`formikInput ${meta.touched && meta.error ? 'formikInput-error' : ''}`}
                            type="text"
                        />
                    )}
                />
            </LocalizationProvider>
            {meta.touched && meta.error ? (<div className="error">{meta.error}</div>) : null}
            <InfoModal
                open={isModalOpen}
                handleClose={handleCloseModal}
                fieldName={fieldName}
                description={description}
                link={link}
            />
        </div>
    );
};

export default FormikMuiDatePicker;
