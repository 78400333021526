/**
 * userDetailsState Reducer is used to store all customer Details
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import * as actions from '../actions/actionTypes'

const initState = {
  userDetails: [
    {
      "id": 0,
      "date_created": "",
      "date_created_gmt": "",
      "date_modified": "",
      "date_modified_gmt": "",
      "email": "",
      "first_name": "",
      "last_name": "",
      "role": "",
      "username": "",
      "billing": {
        "first_name": " ",
        "last_name": " ",
        "company": " ",
        "address_1": " ",
        "address_2": " ",
        "city": " ",
        "state": " ",
        "postcode": " ",
        "country": " ",
        "email": " ",
        "phone": " "
      },
      "shipping": {
        "first_name": "",
        "last_name": "",
        "company": "",
        "address_1": "",
        "address_2": "",
        "city": "",
        "state": "",
        "postcode": "",
        "country": ""
      },
      "is_paying_customer": false,
      "avatar_url": "https://secure.gravatar.com/avatar/8eb1b522f60d11fa897de1dc6351b7e8?s=96",
      "meta_data": [],
      "_links": {
        "self": [
          {
            "href": "https://example.com/wp-json/wc/v3/customers/25"
          }
        ],
        "collection": [
          {
            "href": "https://example.com/wp-json/wc/v3/customers"
          }
        ]
      }
    }
  ],
  switchBackUrl: '',
  loading: false,
  error: null
}

const userDetailsState = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case actions.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload
      }
    case actions.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.EDIT_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case actions.EDIT_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload
      }
    case actions.EDIT_CUSTOMER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.EDIT_BILLING_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case actions.EDIT_BILLING_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload
      }
    case actions.EDIT_BILLING_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.EDIT_SHIPPING_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case actions.EDIT_SHIPPING_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload
      }
    case actions.EDIT_SHIPPING_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.GET_SWITCH_BACK_URL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case actions.GET_SWITCH_BACK_URL_SUCCESS:
      return {
        ...state,
        loading: false,
        switchBackUrl: action.payload,
      }
    case actions.GET_SWITCH_BACK_URL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default userDetailsState;
