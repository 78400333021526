/**
 * getCompanies Api is used to GET Help Data from wordpress or WooCommerce
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import axios from 'axios'
import * as actions from './actionTypes'

/**
 * GET Nonced URL details from WooCommerce REST API.
 * @endpoint "/companies"  Middleware customization and documentation.
 * @param id Customer Login userID to get user specific details.
 *
 * @return JSON Orders List Object .
 */
const getCompanies = (id) => async (dispatch, getState) => {

    dispatch({ type: actions.GET_COMPANIES_REQUEST })
    try {
        const response = await axios.get(
            '/companieslist', {
                params: {
                    customer: id,
                }
            }
        )
        const companies = response.data;
        const userId = id;
        dispatch({ type: actions.GET_COMPANIES_SUCCESS, payload: {companies,userId} })
    } catch (error) {
        dispatch({ type: actions.GET_COMPANIES_FAILURE, error })
    }
}

export default getCompanies
