import axios from 'axios';
import * as actions from "./actionTypes";
import {toastr} from "react-redux-toastr";
import { toast, ToastContainer } from 'react-toastify';
import getPaymentMethods from './getPaymentMethods';
import getSubscriptions from './getSubscriptions';

const changePaymentMethodForAllSubs = (userId,expires,paymentProfileId="",_wcsnonce,_wpnonce,gateway,changePaymentMethodURL,changePaymentID,cardType, DataValue = "", DataDescriptor = "", LastFour = "",updateAll, note,navigate) => async (dispatch) => {

    const urlObj = new URL(changePaymentMethodURL);
    const wpHttpRefrer = urlObj.pathname + urlObj.search;

    const data = {
        expires: expires,
        paymentProfileId: paymentProfileId,
        wcsnonce: _wcsnonce,
        wpnonce: _wpnonce,
        gateway: gateway,
        changePaymentMethodURL: changePaymentMethodURL,
        changePaymentID: changePaymentID,
        dataValue: DataValue,
        dataDescriptor: DataDescriptor,
        lastFour: LastFour,
        updateAll: updateAll,
        cardType: cardType
    };
    // Endpoint to your Express server's changePaymentMethod route
    const backendEndpoint = "/paymentmethods/changePaymentMethod";

    dispatch({ type: actions.CHANGE_PAYMENT_METHOD_FOR_ALL_SUBS_REQUEST });

    try {
        const response = await axios.post(backendEndpoint, data);

        if (response.data && response.data.success) {
            // If the backend returned a success message, refresh the window

            switch (note){
                case "changed_payment_method":
                        toast.success('Successfully changed Payment Method');
                case "added_and_updated":
                        toast.success('Successfully added a new card & assigned all subs.');
                case "revalidated_card":
                        toast.success('Successfully Re-entered your credit-card.');
                case "updated_all_subscriptions":
                        toast.success('Successfully updated all subscriptions.');
            }
            dispatch(getPaymentMethods(userId));
            dispatch(getSubscriptions(userId));

            localStorage.setItem('changedPayment','true');
        } else {
            dispatch({ type: actions.CHANGE_PAYMENT_METHOD_FOR_ALL_SUBS_SUCCESS, payload: response.data });
        }
    } catch (error) {
        console.error("Error changing payment method:", error.response ? error.response.data : error.message); // Log detailed error for debugging

        switch (note){
            case "changed_payment_method":
                    toast.error('Error changing Payment Method, try again!')
            case "added_and_updated":
                    toast.error('Error adding a new card, try again!')
            case "revalidated_card":
                    toast.error('Error re-entering your credit-card, try again!')
            case "updated_all_subscriptions":
                    toast.error('Error updating subscriptions, try again!')
        }

        const errorMessage = error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({ type: actions.CHANGE_PAYMENT_METHOD_FOR_ALL_SUBS_FAILURE, payload: { error: errorMessage } });
    }
}

export default changePaymentMethodForAllSubs;

