/**
 * getSubscriptions Api is used to GET Subscriptions List Details from WooCommerce
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import axios from 'axios'
import * as actions from './actionTypes'

/**
 * GET Subscriptions List details from WooCommerce REST API.
 * @endpoint "/subscriptions"  Middleware customization and documentation.
 * @param id Customer Login userID to get user specific details.
 *
 * @return JSON Subscriptions List Object .
 */
const getSubscriptions = (id) => async (dispatch, getState) => {

  dispatch({ type: actions.GET_SUBSCRIPTION_REQUEST })
  try {
    const response = await axios.get(
      '/subscriptionslist', {
        params: {
          customer: id
        }
      }
    )
    dispatch({ type: actions.GET_SUBSCRIPTION_SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: actions.GET_SUBSCRIPTION_FAILURE, error })
  }
}

export default getSubscriptions;
