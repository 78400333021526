import React from 'react';
import "./formik.css"
import { Formik, Form} from 'formik';
import {MyTextInput} from "./FormikFields";
import {useDispatch, useSelector} from "react-redux";
//import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import {editShippingAddress} from "../../actions";
import * as Yup from "yup";
import {Link,useNavigate} from "react-router-dom";

export const ShippingFormValidationSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    lastName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    company: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!'),
    country: Yup.string()
        .required('Required'),
    streetAddress1: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    streetAddress2: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!'),
    city: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    state: Yup.string()
        .required('Required'),
    postcode: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
});

// And now we can use these
const ShippingAddressForm = (props) => {

    const id = props.id;
    const shippingData = props.shippingData;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const addressErrors =  useSelector((state) => state.userDetailsState.error);
    const addressLoad =  useSelector((state) => state.userDetailsState.loading);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    firstName: shippingData.firstName,
                    lastName: shippingData.lastName,
                    company: shippingData.company,
                    country: shippingData.country,
                    streetAddress1: shippingData.address1,
                    streetAddress2: shippingData.address2,
                    city: shippingData.city,
                    state: shippingData.state,
                    postcode: shippingData.postcode
                }}
                validationSchema={ShippingFormValidationSchema}
                onSubmit={ (values, { setSubmitting }) => {
                    dispatch(editShippingAddress(values.lastName,values.firstName,id, values.company,
                        values.streetAddress1,values.streetAddress2,values.city,values.state,values.postcode,values.country));

                    if(addressErrors === null && addressLoad === false){
                        window.location.replace('/edit-address');
                    }
                }}
            >
                {({values,handleChange, handleBlur})=>(
                    <Form>
                        <MyTextInput
                            label="First Name "
                            name="firstName"
                            type="text"
                            placeholder="Enter First Name"
                        /><br/><br/>

                        <MyTextInput
                            label="Last Name "
                            name="lastName"
                            type="text"
                            placeholder="Enter Last Name"
                        /><br/><br/>

                        <MyTextInput
                            label="Company Name (Optional) "
                            name="company"
                            type="text"
                            placeholder="Enter Company Name"
                        /><br/><br/>

                        <MyTextInput
                            label="Country "
                            name="country"
                            type="text"
                            placeholder="Enter Country Name"
                        /><br/><br/>

                        {/*<CountryDropdown*/}
                        {/*    label="Country "*/}
                        {/*    name="country"*/}
                        {/*    value={values.country}*/}
                        {/*    onChange={(_,e)=>handleChange(e)} onBlur={handleBlur}*/}
                        {/*/><br/><br/>*/}

                        <MyTextInput
                            label="Address Line 1 "
                            name="streetAddress1"
                            type="text"
                            placeholder="Enter Street/house no."
                        /><br/><br/>

                        <MyTextInput
                            label="Address Line 2 "
                            name="streetAddress2"
                            type="text"
                            placeholder="Enter Apt/Unit no."
                        /><br/><br/>

                        <MyTextInput
                            label="Town / City "
                            name="city"
                            type="text"
                            placeholder="Enter Town/City "
                        /><br/><br/>

                        <MyTextInput
                            label="State "
                            name="state"
                            type="text"
                            placeholder="Enter State "
                        /><br/><br/>

                        {/*<RegionDropdown*/}
                        {/*    legal="State "*/}
                        {/*    name="state"*/}
                        {/*    country={values.country}*/}
                        {/*    value={values.state}*/}
                        {/*    onChange={(_, e) => handleChange(e)} onBlur={handleBlur}*/}
                        {/*/><br/><br/>*/}

                        <MyTextInput
                            label="Zip Code "
                            name="postcode"
                            type="number"
                            placeholder="Enter Zipcode "
                        /><br/><br/>


                        <div className={"formButtonsDiv"}>
                            <button className={"wooButtons"} type="submit">Submit</button>
                            <Link to={"/edit-address"}>
                                <button className={"wooButtons"} >Cancel</button>
                            </Link>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ShippingAddressForm;