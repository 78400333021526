/**
 * editShippingAddress API is used to EDIT Billing Address
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */
import * as actions from './actionTypes'
import axios from 'axios'

/**
 * EDIT Shipping Address details from WooCommerce REST API.
 * @endpoint "/customers/shippingAddress"  Middleware customization and documentation.
 * @param id Customer Login userID to get user specific details.
 * @param lastName
 * @param firstName
 * @param company
 * @param address1
 * @param address2
 * @param city
 * @param state
 * @param postcode
 * @param country
 * @return JSON User Details Object .
 */
const editShippingAddress = (lastName, firstName, id, company,
  address1, address2, city, state, postcode, country) => async (dispatch, getState) => {
  dispatch({ type: actions.EDIT_SHIPPING_ADDRESS_REQUEST })
  try {
    const response = await axios.put(
      '/customers/shippingAddress', null, {
        params: {
          customerId: id,
          firstName,
          lastName,
          company,
          address1,
          address2,
          city,
          state,
          postcode,
          country
        }
      }
    )
    dispatch({ type: actions.EDIT_SHIPPING_ADDRESS_SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: actions.EDIT_SHIPPING_ADDRESS_FAILURE, error })
  }
}

export default editShippingAddress;