import "./subscription.css";
// import "../../styles/style.scss";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import React, { useRef } from "react";
import {ArrowBack, Call, Email} from "@mui/icons-material";
import parse from 'html-react-parser';
import formatCurrency from "../../functions/formatCurrency";
import { Box, Typography } from '@mui/material';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import VerifiedIcon from '@mui/icons-material/Verified';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PrintIcon from '@mui/icons-material/Print';
import CancelIcon from '@mui/icons-material/Cancel';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {MyExpansionChip,MyChip,getStatusIcon,statusIconMap,getStatusColor,statusColorMap,useStyles} from "../orderList/ordersList";
import { useReactToPrint } from 'react-to-print';
import changePaymentMethodForAllSubs from "../../actions/changePaymentMethodForAllSubs";
import MultiCheckboxModal from "../paymentMethods/components/MultiCheckboxModal";
import {transformCreditCards} from "../paymentMethods/components/addCreditCard";
import Modal from '@mui/material/Modal';
import CancelSubscriptionButton from "../adminRequests/CancelSubscriptionButton";
import CustomModal from "../paymentMethods/components/CustomModal";
import postJiraIssue from "../../actions/postJiraIssue";

export const getDisplayNamesFromCards = (creditCards) => {
    return transformCreditCards(creditCards).map(card => card.displayName);
};

export const sanitizeUrl = (url) => {
    const decodedUrl = decodeURIComponent(url);
    const [baseUrl, queryString] = decodedUrl.split("?");
    const fixedQueryString = queryString ? queryString.replace(/&amp;/g, "&") : "";

    // Check if URL starts with "https://"
    if (baseUrl.startsWith("https://")) {
        return fixedQueryString ? `${baseUrl}?${fixedQueryString}` : baseUrl;
    }
    // Check if URL starts with "/wp-json/"
    else if (baseUrl.startsWith("/wp-json/")) {
        const wordpressDomain = process.env.REACT_APP_WP_SITE_URL;
        return fixedQueryString ? `${wordpressDomain}?${fixedQueryString}` : `${wordpressDomain}${baseUrl}`;
    }
    // Return the original URL if it doesn't match the conditions
    else {
        return decodedUrl;
    }
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,  // Adjust width as required
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const SimpleModal = ({ show, onClose }) => {
    return (
        <Modal
            open={show}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Box sx={style}>
                <Typography id="simple-modal-title" variant="h6" component="h2">
                    Please add a credit card to assign this subscription to a new credit card.
                </Typography>
                <Typography id="simple-modal-description" sx={{ mt: 2 }}>
                    <Link to={"/payment-methods/add-payment-method"}>
                        <button className={"wooButtons"}>
                            Add Credit Card
                        </button>
                    </Link>
                </Typography>
            </Box>
        </Modal>
    );
}

export default function Subscription(props){

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userId = props.id;
    const userEmail = props.details.email;
    const subscriptions  = props.subList;
    const orders  = props.orders;
    const paymentMethods = props.paymentMethods;
    const wcsnonceValue = paymentMethods?.cc?.[0]?._wcsnonce ?? null;
    const firstName = props.details.first_name;
    const lastName = props.details.last_name;
    const usersName = firstName + ' ' + lastName;
    const cancelledSubs = props.cancelledSubs;
    const jiraIssueType = props.jiraIssueType;
    const jiraSearchString = props.jiraSearchString;
    const CancelSubMsg = props.CancelSubMsg;

    const paymentMethodState =  useSelector((state) => state.paymentMethodsState.paymentMethods);
    const {success,message,cc: creditCards, pp: paypalPayments} = paymentMethodState;
    const transformedCardsRef = useRef(transformCreditCards(creditCards || []));
    const displayCardNames = getDisplayNamesFromCards(creditCards);
    const [isCancelled, setIsCancelled] = useState(false);

    const projectKey = process.env.REACT_APP_JIRA_PROJECT_KEY;
    let {subscriptionId} = useParams();
    let subscriptionIdentity = subscriptionId;
    let ids = [];

    const subStatusColorMap = {
        ACTIVE: '#c6e1c6',
        EXPIRED: '#bd94ae',
        PENDING: '#c6e1c6',
        ONHOLD: '#f8dda7',
        CANCELLED: '#e5e5e5'
    };

    const getSubStatusColor = (status) => {
        return subStatusColorMap[status] || 'black'; // return black as default color if status not in map
    };

    const subStatusIconMap = {
        ACTIVE: VerifiedIcon,
        PENDING: AccessTimeFilledIcon,
        EXPIRED: HighlightOffIcon,
        ONHOLD: RemoveCircleIcon,
        CANCELLED: CancelIcon,
    }

    const getSubStatusIcon = (status) => {
        return subStatusIconMap[status] || AcUnitIcon;
    }

    const tableHeadIssueCollector = ({ subscriptionId, userEmail, userId, usersName }) => {
        useEffect(() => {

            const loadScript = (url) => {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = url;
                script.async = true;
                document.body.appendChild(script);
                script.onload = () => {
                    window.ATL_JQ_PAGE_PROPS = $.extend(window.ATL_JQ_PAGE_PROPS, {
                        "triggerFunction": function(showCollectorDialog) {
                            const button = document.getElementById('subscriptionCancellationButton');
                            if (button) {
                                button.addEventListener('click', function(e) {
                                    e.preventDefault();

                                    showCollectorDialog();

                                    // Use setTimeout to allow the form to render
                                    setTimeout(() => {
                                        // Make summary field read-only
                                        const summaryField = document.querySelector('input[name="summary"]');
                                        if (summaryField) {
                                            summaryField.readOnly = true;
                                            summaryField.value = `Subscription ID: ${subscriptionId}, for ${userEmail}-${userId}`;
                                        }
                                        // Make name field read-only
                                        const nameField = document.querySelector('input[name="fullname"]');
                                        if (nameField) {
                                            nameField.readOnly = true;
                                            nameField.value = usersName;
                                        }
                                        // Make email field read-only
                                        const emailField = document.querySelector('input[name="email"]');
                                        if (emailField) {
                                            emailField.readOnly = true;
                                            emailField.value = userEmail;
                                        }
                                    }, 100); // Adjust the timeout as necessary
                                });
                            }
                        },
                        "fieldValues": {
                            "summary": `Subscription ID: ${subscriptionId}, for ${userEmail}-${userId}`,
                            "fullname": usersName,
                            "email": userEmail,
                        }
                    });
                };
            };

            loadScript("https://slingshotit.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/278rlr/b/7/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=fa085b78");
        }, [subscriptionId, userEmail, userId, usersName]);

        return (
            <tr>
                <td className={"tableBodyData"}>Support Request</td>
                <td className={"tableBodyData"}>
                    <button id="subscriptionCancellationButton" className={"wooButtons"}>
                        Cancel Subscription
                    </button>
                </td>
            </tr>
        );
    };

    const tableHeadConfirmCancellation = ({ status, subName }) => {
        const validStatuses = ['ACTIVE', 'ONHOLD', 'PENDINGCANCEL'];
        const SummaryCancelRequest = `Subscription ID: ${subscriptionId}, for ${userEmail}-${userId}`;
        const cancelledSubs = useSelector(state => state.jiraIssuesState.CancelSubs); // Get cancelled subscriptions from Redux state
        const isCancelled = cancelledSubs.includes(subscriptionId);

        // Check if the subscription status is valid to show the cancel button
        if (!validStatuses.includes(status)) {
            return null; // Return null to avoid rendering the button for invalid statuses
        }

        return (
            <tr>
                <td className={"tableBodyData"}>Support Request</td>
                <td className={"tableBodyData"}>
                    {isCancelled ? (
                        <Link to="/admin-requests">
                            <button className="wooButtons">Cancellation Request Sent</button>
                        </Link>
                    ) : (

                        <CustomModal
                            triggerButtonText="Cancel Subscription"
                            questionText={CancelSubMsg}
                            confirmButtonText="Go to Support Request"
                            cancelButtonText="Cancel"
                            onConfirm={() => {
                                // Navigate to the AddSupportRequests page with pre-filled data
                                navigate("/admin-requests/add-support-request", {
                                    state: {
                                        subscriptionId: subscriptionId,
                                        userId: userId,
                                        userEmail: userEmail,
                                        helpType: "Subscriptions", // Set the helpType to "Subscriptions" for cancellation requests
                                        description: `Please cancel my subscription for ${subName} with Subscription ID: ${subscriptionId}.`,
                                        // You can add more fields as needed
                                    }
                                });
                            }}
                        />
                    )}
                </td>
            </tr>
        );
    };

    useEffect(() => {
        if (cancelledSubs.includes(subscriptionId)) {
            setIsCancelled(true);
        }
    }, [cancelledSubs, subscriptionId]);

    const settleLine = () =>{
        return subscriptions.map((a) =>{

            const {id , relatedOrders,line_items, status,start_date_gmt,last_payment_date_gmt,end_date_gmt,total, total_tax,
                payment_method_title, trial_end_date_gmt,actions,  billing:{first_name: billingFirstName, last_name: billingLastName,company: billingCompany,
                    address_1: billingAdd1,address_2: billingAdd2, city: billingCity, state: billingState,
                    postcode: billingPostcode, email: billingEmail, phone: billingPhone}} = a;
            const subscriptionTotal = Number(total.replace(/[^0-9\.]+/g,""));
            const Total = formatCurrency(subscriptionTotal);
            const subscriptionTotalTax = Number(total_tax.replace(/[^0-9\.]+/g,""));
            const TotalTax = formatCurrency(subscriptionTotalTax);
            const subscriptionSubTotal = (subscriptionTotal - subscriptionTotalTax).toFixed(2);
            const subTotal = formatCurrency(subscriptionSubTotal);

            const relatedStatus = status.replace(/-/g, "").toUpperCase();
            const subStatusColor = getSubStatusColor(relatedStatus);
            const subIcon = getSubStatusIcon(relatedStatus);

            let startDate = new Date(start_date_gmt.toString()).toLocaleDateString();
            let lastOrderDate = new Date(last_payment_date_gmt.toString()).toLocaleDateString();
            let trialDate = new Date(trial_end_date_gmt.toString()).toLocaleDateString();

            const subName = line_items.length > 0 ? line_items[0].name : '';

            const paymentMethodAction = () => {

                if (a.hasOwnProperty('actions') && Object.keys(actions).length > 0){

                    // Get the key of the first property in the actions object
                    const firstKey = Object.keys(actions)[0];

                    // Now you can access the 'url' and 'name' properties using the dynamic key
                    const { url, name } = actions[firstKey];

                    const sanitizeUrl = (url) => {
                        const decodedUrl = decodeURIComponent(url);
                        const [baseUrl, queryString] = decodedUrl.split("?");
                        const fixedQueryString = queryString ? queryString.replace(/&amp;/g, "&") : "";

                        // Check if URL starts with "https://"
                        if (baseUrl.startsWith("https://")) {
                            return fixedQueryString ? `${baseUrl}?${fixedQueryString}` : baseUrl;
                        }
                        // Check if URL starts with "/wp-json/"
                        else if (baseUrl.startsWith("/wp-json/")) {
                            const wordpressDomain = process.env.REACT_APP_WP_SITE_URL;

                            return fixedQueryString ? `${wordpressDomain}?${fixedQueryString}` : `${wordpressDomain}${baseUrl}`;
                        }
                        // Return the original URL if it doesn't match the conditions
                        else {
                            return decodedUrl;
                        }
                    };

                    dispatch(changePaymentMethod(wcsnonceValue,sanitizeUrl(url)));
                    // window.location.assign(sanitizeUrl(url));
                }
            }

            const findCardByDisplayName = (displayName, cards) => {
                return cards.find(card =>
                    `Ending in ${card.method.last4} with expiry ${card.expires}` === displayName
                );
            };

            const tableHeadActions = () => {

                const [isModalOpen, setModalOpen] = useState(false);
                const openModal = () => setModalOpen(true);
                const closeModal = () => setModalOpen(false);

                if (displayCardNames.length === 0) {
                    return (
                        <>
                            <SimpleModal show={isModalOpen} onClose={closeModal} />
                            <tr>
                                <td className={"tableBodyData"}>Actions</td>
                                <td className={"tableBodyData"}>
                                    {/* Trigger button to show the SimpleModal */}
                                    <button onClick={openModal} className={"wooButtons"}>
                                        Change Payment Method
                                    </button>
                                </td>
                            </tr>
                        </>
                    );
                }

                if (a.hasOwnProperty('actions') && Object.keys(actions).length > 0) {
                    const firstKey = Object.keys(actions)[0];
                    const { url, name } = actions[firstKey];

                    if (name === "Change payment") {
                        return (
                            <>
                                <tr>
                                    <td className={"tableBodyData"}>Actions</td>
                                    <td className={"tableBodyData"}>
                                        <MultiCheckboxModal
                                            triggerButtonText="Change Payment Method"
                                            questionText="Select a credit card to add to this subscription:"
                                            options={displayCardNames}
                                            updateAllSubscriptionsText="Update all subscriptions to this payment method."
                                            addPaymentMethod={true}
                                            onConfirm={async (selectedOptions, updateAllSubscriptionsFlag) => {
                                                if (actions) {
                                                    const firstKey = Object.keys(actions)[0];
                                                    const { url } = actions[firstKey];
                                                    const changePaymentMethodURL = sanitizeUrl(url);
                                                    const match = changePaymentMethodURL.match(/\/order-pay\/(\d+)\//);
                                                    const changePaymentID = match ? match[1] : null;
                                                    const selectedDisplayName = Object.keys(selectedOptions).find(option => selectedOptions[option]);

                                                    // Find the card details by its display name
                                                    const selectedCard = findCardByDisplayName(selectedDisplayName, creditCards);

                                                    if (selectedCard) {
                                                        const { expires, is_default, method : {gateway, brand, last4 }, actions, paymentProfileId, subscriptions, _wcsnonce, _wpnonce } = selectedCard;
                                                        const note = "changed_payment_method";
                                                        dispatch(changePaymentMethodForAllSubs(userId,expires, paymentProfileId, _wcsnonce, _wpnonce, gateway, changePaymentMethodURL, changePaymentID,brand,"","","",updateAllSubscriptionsFlag,note,navigate));
                                                    }
                                                }
                                            }}
                                        />
                                    </td>
                                </tr>
                            </>
                        );
                    } else {
                        return (
                            <>
                                <tr>
                                    <td className={"tableBodyData"}>Actions</td>
                                    <td className={"tableBodyData"}>
                                        <button onClick={paymentMethodAction} className={"wooButtons"}>
                                            {name}
                                        </button>
                                    </td>
                                </tr>
                            </>
                        );
                    }
                }
            };

            const tableHeadTrialDate = () => {
                return trial_end_date_gmt !== '' ? (
                    <>
                        <tr>
                            <td className={"tableBodyData"}>Trial End Date</td>
                            <td className={"tableBodyData"}>{trialDate}</td>
                        </tr>
                    </>
                ) : (
                    <div hidden={'hidden'}>

                    </div>
                )
            }

            const tableFooterPaymentMethod = () => {
                return payment_method_title !== '' ? (
                    <>
                        <tr>
                            <th className={"tfootTh"} scope={"row"}>Payment method:</th>
                            <td className={"wooTableFootTd"}>
                                Via {payment_method_title}
                            </td>
                        </tr>
                    </>
                ) : (
                    <div hidden={'hidden'}>

                    </div>
                )
            }

            return id.toString() === subscriptionIdentity.toString() ?
                (
                    <div>
                        <table className={"wooDetailsTable"}>
                            <tbody>
                                <tr>
                                    <td className={"subscriptionDetailStatus"}>Status</td>
                                    <td className={"subscriptionDetailStatus"}>
                                        <span><MyExpansionChip label={relatedStatus} icon={subIcon} color={subStatusColor}/></span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"tableBodyData"}>Start Date</td>
                                    <td className={"tableBodyData"}>{startDate}</td>
                                </tr>
                                <tr>
                                    <td className={"tableBodyData"}>Last order date</td>
                                    <td className={"tableBodyData"}>{lastOrderDate}</td>
                                </tr>
                                {tableHeadTrialDate()}
                                {tableHeadActions()}
                                {tableHeadConfirmCancellation({status: relatedStatus, subName})}
                            </tbody>
                        </table>

                        <table className={"wooDetailsTable"}>
                            <thead>
                            <tr>
                                <th className={"tableHeaderData"}>
                                    Service
                                </th>
                                <th className={"tableHeaderDataTotal"}>
                                    Total
                                </th>
                            </tr>
                            </thead>
                            {line_items.map((item)=>{
                                const {name: subName, quantity, meta_data, total:t} = item;
                                const tTotal = formatCurrency(t);
                                console.log(subName);
                                return (
                                    <>
                                        <tbody>
                                        <tr>
                                            <td className={"tableBodyData"}>
                                                <span><strong>{subName}&nbsp;</strong></span>
                                                <strong>x&nbsp;{quantity}</strong>
                                                <ul className={"detailMetaDataList"}>
                                                    {meta_data.map((c)=>{
                                                        let {key,display_key, value} = c;
                                                        return key.charAt(0) !== "_" ? (
                                                            <li className={"metadataTitle"}>
                                                                    <span><strong>{display_key}:</strong>
                                                                        <br/>
                                                                        {parse(value)}
                                                                        <b><br/><br/></b>
                                                                    </span>
                                                            </li>
                                                        ) : (
                                                            <div hidden="hidden">
                                                                Sorry
                                                            </div>
                                                        )
                                                    })}
                                                </ul>
                                            </td>
                                            <td className={"totalTableData"}>
                                                <span>
                                                    <bdi>
                                                        {tTotal}
                                                    </bdi>
                                                 </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </>)
                            })}

                            <tfoot>
                            <tr>
                                <th className={"tfootTh"} scope={"row"}>Subtotal:</th>
                                <td className={"wooTableFootTd"}>
                                    {subTotal}
                                </td>
                            </tr>
                            <tr>
                                <th className={"tfootTh"} scope={"row"}>NMGRT:</th>
                                <td className={"wooTableFootTd"}>
                                    {TotalTax}
                                </td>
                            </tr>
                            {tableFooterPaymentMethod()}
                            <tr>
                                <th className={"tfootTh"} scope={"row"}>Total:</th>
                                <td className={"wooTableFootTd"}>
                                    {Total}
                                </td>
                            </tr>
                            </tfoot>
                        </table>

                        {
                            typeof relatedOrders === 'object' && (
                                orders.some(item => item.id in relatedOrders) ? (
                                    <div>
                                        <header>
                                            <h3 align="left">Related Orders</h3>
                                        </header>
                                        <table className={"wooDetailsTable"}>
                                            <thead>
                                            <tr>
                                                <th className={"tableHeaderData"}>ID</th>
                                                <th className={"tableHeaderData"}>Service(s)</th>
                                                <th className={"tableHeaderData"}>Status</th>
                                                <th className={"tableHeaderData"}>Date Created</th>
                                                <th className={"tableHeaderData"}><span>&nbsp;</span></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {orders.map((item) => {
                                                const {
                                                    id: orderID,
                                                    status,
                                                    date_created,
                                                    line_items,
                                                    payment_url: payOrder,
                                                    needs_payment
                                                } = item;
                                                const dateForOrder = new Date(date_created.toString()).toLocaleDateString();
                                                const relatedStatus = status.replace(/-/g, "").toUpperCase();
                                                const relatedStatusColor = getStatusColor(relatedStatus);
                                                const relatedIcon = getStatusIcon(relatedStatus);

                                                return orderID in relatedOrders ? (
                                                    <tr>
                                                        <td className={"tableBodyData"}>
                                                            <Link to={"/view-order/" + orderID}><span
                                                                className={"idNamelink"}>{orderID}</span></Link>
                                                        </td>
                                                        <td className={"tableBodyData"}>
                                                            {line_items.map((e) => {
                                                                const {name} = e;
                                                                return (
                                                                    <span>{name}<br/></span>
                                                                )
                                                            })}
                                                        </td>
                                                        <td className={"tableBodyData"}>
                                                            <span className={"relatedSubStatusCapsule"}>
                                                              <MyExpansionChip label={relatedStatus} color={relatedStatusColor} icon={relatedIcon}/>
                                                            </span>
                                                        </td>
                                                        <td className={"tableBodyData"}><span>{dateForOrder}</span></td>
                                                        <td className={"tableBodyData"}>
                                                            <Link to={"/view-order/" + orderID}>
                                                                <button className={"wooButtons"}>View</button>
                                                            </Link>
                                                            {needs_payment && (
                                                                <button onClick={() => window.location.assign(payOrder)}
                                                                        className={"wooButtons"}>Pay</button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ) : null;
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div>
                                        <h3 align="left">No Related Orders</h3>
                                    </div>
                                )
                            )
                        }


                        <section className={"wooOrderDetails"}>
                            <h3 align={"left"}>Billing Details</h3>
                            <address className={"orderBillingDetails"}>
                                {billingFirstName} {" "} {billingLastName}
                                <br/>
                                {billingCompany}
                                <br/>
                                {billingAdd1}
                                <br/>
                                {billingAdd2}
                                <br/>
                                {billingCity}, {" "} {billingState} {" "} {billingPostcode}
                                <br/>
                                <Call/>{billingPhone}
                                <br/>
                                <Email/>{billingEmail}
                            </address>
                        </section>
                    </div>) : <span hidden="hidden">Why Not?</span>;
        })
    }

    useEffect(() => {
        subscriptions.map((a)=>{
            return ids.push(a.id);
        });
    });

    return (
        <div className={"pageView"}>
            <div className={"pageWrapper"}>

                <Typography
                    variant="h4"
                    className={"pageTitle"}
                >
                    <div>
                        <button className={"backButton"} onClick={()=> navigate(-1)}>
                            <ArrowBack fontSize={"medium"} />{" "}
                            <span className={"backButtonText"}>Go Back</span>
                        </button>
                    </div>
                    Subscription #{subscriptionIdentity} Details {" "}
                </Typography>

                <div>
                    {settleLine()}
                </div>
                
            </div>
        </div>
    )
}


// <CustomModal
//     triggerButtonText="Cancel Subscription"
//     questionText={CancelSubMsg}
//     confirmButtonText="Submit"
//     cancelButtonText="Cancel"
//     onConfirm={() => {
//         dispatch(postJiraIssue(userId, projectKey, SummaryCancelRequest, jiraIssueType, jiraSearchString, navigate));
//     }}
// />