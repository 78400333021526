import MUIDataTable, { ExpandButton, TableViewCol } from "mui-datatables";
import React, { useState, useEffect } from "react";
import { TableCell, TableRow } from "@mui/material";
import { Paper } from "@mui/material";
import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material/styles";
import generateSortableColumn from "../../components/functions/generateSortableColumn";
import { Box, Typography } from '@mui/material';
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { Link } from "react-router-dom";
import deleteCompanyCpt from "../../actions/deleteCompanyCpt";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../paymentMethods/components/CustomModal";
import UpdateCompanies from "./UpdateCompanies";
import { relatedSubscriptions } from "../orderList/ordersUtils";
import { RelatedOrders } from "../orderList/ordersUtils";
import "./companies.css";

const muiCache = createCache({
    key: 'mui-datatables',
    prepend: true,
});

// Function to decode HTML entities like &apos; or &quot;
export const decodeHtmlEntities = (text) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
}

export default function Companies(props) {

    const dispatch = useDispatch();
    const site_url = process.env.REACT_APP_WP_SITE_URL;
    const [sortedColumn, setSortedColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('none');
    const userId = props.id;
    const companiesListData = props.companies;
    const userDetails = props.details;
    const [nonce, setNonce] = useState(userDetails?._wpnonce || null);
    const [expandedCompanies, setExpandedCompanies] = useState([]);
    const subList = props.subList;
    const ordersList = props.orders;

    useEffect(() => {
        if (userDetails && userDetails._wpnonce) {
            setNonce(userDetails._wpnonce);
        }
    }, [userDetails]);

    const getFixedWidthProps = (width) => {
        return () => ({
            style: { width, maxWidth: width, minWidth: width }
        });
    };

    const deleteCompany = (userId, companyID, nonce) => {
        dispatch(deleteCompanyCpt(userId, companyID, nonce));
    }

    const deleteButton = (userId, companyID, nonce)=>{
        return (
            <div>
                <CustomModal
                    triggerButtonText="Delete"
                    questionText="Are you sure you want to delete this company?"
                    confirmButtonText="Yes"
                    cancelButtonText="Cancel"
                    onConfirm={() => {
                        console.log("Deleting Company with " +companyID);
                        //dispatch(deleteCompanyCpt(userId, companyID, nonce));
                    }}
                />
            </div>
        )
    }

    const removeCompanyButton = (userId, companyID, nonce) => {
        return (
            <CustomModal
                triggerButtonText="Remove"
                questionText={
                    <>
                        This will remove the company from L4SB Self Service Portal Only. Are you sure you want to submit the request? <br/><br/>
                        To dissolve your company please use this <a href="https://www.l4sb.com/services/dissolve-llc/" target="_blank" rel="noopener noreferrer">service</a>.
                    </>
                }
                confirmButtonText="Submit"
                cancelButtonText="Cancel"
                onConfirm={() => {
                    console.log("Deleting Company with " + companyID);
                    dispatch(deleteCompanyCpt(userId, companyID, nonce));
                }}
            />
        );
    };

    const companiesData = (companiesListData) => {
        if (!Array.isArray(companiesListData)) {
            return [];
        }

        return companiesListData.map(company => ([
            decodeHtmlEntities(company.title.rendered),
            // company.acf.base_address.city,
            company.acf.state_of_domestication,
            company.acf.company_formation_date,
            company.acf.boi_reporting_date,
            <div className={"actionButtons"}>
                <Link to={`/companies/update-a-company/${company.id}`}>
                    <button className="wooButtons">Edit</button>
                </Link>
                {removeCompanyButton(userId, company.id,nonce)}
            </div>
        ]));
    };

    let simplifiedCompanies = companiesData(companiesListData);

    const columns = [
        generateSortableColumn("Company Title", 1, {
            filter: true,
            sortThirdClickReset: true,
            sortDescFirst: true,
            setCellProps: () => ({
                style: {
                    ...getFixedWidthProps('25.00%').style,
                    textAlign: 'left'
                }
            }),
        }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("State", 3, { filter: true, sortThirdClickReset: true, sortDescFirst: true, setCellProps: getFixedWidthProps('20.00%') }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("Formation Date", 2, { filter: true, sortThirdClickReset: true, sortDescFirst: true, setCellProps: getFixedWidthProps('20.00%') }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("BOIR Date", 0, { filter: false, sortThirdClickReset: true, sortDescFirst: true, setCellProps: getFixedWidthProps('20.00%') }, true, sortedColumn, setSortedColumn, sortDirection, setSortDirection),
        generateSortableColumn("Actions", 6, { filter: false, sortThirdClickReset: true, setCellProps: getFixedWidthProps('15.00%') }, false, sortedColumn, setSortedColumn, sortDirection, setSortDirection)
    ];

    let act = [];

    const formatAddress = (base_address) => {
        if (!base_address) return <span>Address not provided</span>;
        const { address_1, address_2, city, state, zip_code } = base_address;
        return (
            <div>
                {address_1 && <div>{address_1}</div>}
                {address_2 && <div>{address_2}</div>}
                <div>
                    {city && <span>{city}, </span>}
                    {state && <span>{state} </span>}
                    {zip_code && <span>{zip_code}</span>}
                </div>
            </div>
        );
    };

    const options = {
        filter: true,
        filterType: "dropdown",
        responsive: "vertical",
        selectableRows: "none",
        fixedHeader: true,
        fixedSelectColumn: true,
        selectableRowsOnClick: false,
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        rowsExpanded: expandedCompanies,
        rowsPerPageOptions: [10, 15, 20, 25, 30],
        setRowProps: (row, dataIndex, rowIndex) => {
            if (rowIndex % 2 === 0) {
                return {
                    style: {
                        backgroundColor: 'white',
                        fontSize: '18px',
                    },
                };
            } else {
                return {
                    style: {
                        backgroundColor: '#f0f0f1',
                        fontSize: '18px',
                    },
                };
            }
        },
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const expandedRowId = rowData[0];
            const companyData = companiesListData[rowMeta.dataIndex];
            if (!companyData) {
                return (
                    <TableRow>
                        <TableCell colSpan={colSpan}>
                            <div>No data available for this company.</div>
                        </TableCell>
                    </TableRow>
                );
            }

            const {
                id,
                title: { rendered: titleRendered } = {},
                modified,
                acf: {
                    beneficial_owners,
                    clients = [],
                    needs_cta = true,
                    company_type,
                    base_address = {},
                    boi_reporting_date,
                    mailing_address_same_as_address,
                    mailing_address = null,
                    company_subscriptions: subs,
                    company_orders,
                } = {}
            } = companyData;

            const needsCtaDisplay = needs_cta ? "Yes" : "No";

            const addressForCompany = formatAddress(base_address);
            const mailingAddressForCompany = mailing_address_same_as_address ?
                "Mailing Address same as Address" :
                formatAddress(mailing_address);

            let ownersDetails = [];
            if (beneficial_owners && Array.isArray(beneficial_owners)) {
                ownersDetails = beneficial_owners.map(owner => {
                    const { first_name, last_name, email_id, phone_number, percentage_owned } = owner;
                    return { first_name, last_name, email_id, phone_number, percentage_owned };
                });
            }

            let mailingAddressDetails = mailing_address ? { ...mailing_address } : {};
            const lastModified = new Date(modified.toString()).toLocaleDateString();

            return (
                <TableRow>
                    <TableCell colSpan={colSpan}>
                        <Paper className={"paperAddress"}>
                            <div className={"order-sub-details-address"}>
                                <div className={"orderMetaData"}>
                                    <div className={"serviceName"}>
                                        <Link to={"/companies/update-a-company/" + id} style={{ color: '#536889' }}>
                                            <Typography variant="h5" style={{ fontWeight: 600 }} className={"idNamelink"}>{decodeHtmlEntities(titleRendered)}</Typography>
                                        </Link>
                                    </div>
                                    <div className={"serviceMetaData"}>
                                        <ul className={"metaDataList"}>
                                            <li className={"metadataTitle"}>
                                                <span>
                                                    <strong>Company Type</strong>
                                                    <br />
                                                    {company_type}
                                                </span>
                                            </li>
                                            <li className={"metadataTitle"}>
                                                <span>
                                                    <strong>Needs CTA</strong>
                                                    <br />
                                                    {needsCtaDisplay}
                                                </span>
                                            </li>
                                            <li className={"metadataTitle"}>
                                                <span>
                                                    <strong>Last Modified</strong>
                                                    <br />
                                                    {lastModified}
                                                </span>
                                            </li>
                                            <li className={"metadataTitle"}>
                                                <span>
                                                    <strong>Company Address</strong>
                                                    <br />
                                                    {addressForCompany}
                                                </span>
                                            </li>
                                            <li className={"metadataTitle"}>
                                                <span>
                                                    <strong>Company Mailing Address</strong>
                                                    <br />
                                                    {mailingAddressForCompany}
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {beneficial_owners && beneficial_owners.length > 0 && beneficial_owners.map((owner, index) => {
                                    const {
                                        first_name,
                                        last_name,
                                        address,
                                        email_id,
                                        phone_number,
                                        percentage_owned,
                                        mailing_address_same_as_address,
                                        mailing_address
                                    } = owner;

                                    const addressForOwner = formatAddress(address);
                                    const mailingAddressForOwner = mailing_address_same_as_address ?
                                        "Mailing Address same as Address" :
                                        formatAddress(mailing_address);

                                    return (
                                        <div className={"orderMetaData"}>
                                            <div className={"serviceName"}>
                                                <Typography variant={"h5"} style={{ fontWeight: 600 }}>Beneficial Owner {index + 1}</Typography>
                                            </div>
                                            <div className={"serviceMetaData"}>
                                                <ul className={"metaDataList"}>
                                                    <li className={"metadataTitle"}>
                                                        <span>
                                                            <strong>Name</strong>
                                                            <br />
                                                            {first_name}{" "}{last_name}
                                                        </span>
                                                    </li>
                                                    <li className={"metadataTitle"}>
                                                        <span>
                                                            <strong>Email ID</strong>
                                                            <br />
                                                            {email_id}
                                                        </span>
                                                    </li>
                                                    <li className={"metadataTitle"}>
                                                        <span>
                                                            <strong>Phone Number</strong>
                                                            <br />
                                                            {phone_number}
                                                        </span>
                                                    </li>
                                                    <li className={"metadataTitle"}>
                                                        <span>
                                                            <strong>Percentage Owned</strong>
                                                            <br />
                                                            {percentage_owned}
                                                        </span>
                                                    </li>
                                                    <li className={"metadataTitle"}>
                                                        <span>
                                                            <strong>Owner's Address</strong>
                                                            <br />
                                                            {addressForOwner}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    );
                                })}
                                <RelatedOrders
                                    orderList={ordersList}
                                    relatedOrders={company_orders}
                                />
                                {relatedSubscriptions(subList, subs)}
                            </div>
                        </Paper>
                    </TableCell>
                </TableRow>
            );
        },
        onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
            const expandedRowIndexes = allExpanded.map(row => row.dataIndex);
            sessionStorage.setItem('expandedCompaniesRows', JSON.stringify(expandedRowIndexes));
        },
        textLabels: {
            body: {
                noMatch: simplifiedCompanies.length === 0
                    ? "Your companies will be added here soon. Please check back or add one manually by clicking Add Company."
                    : "Sorry, no matching records found",
            },
        },
    };

    useEffect(() => {
        const storedExpandedCompanies = sessionStorage.getItem("expandedCompaniesRows");
        if (storedExpandedCompanies) {
            setExpandedCompanies(JSON.parse(storedExpandedCompanies));
        }
    }, []);

    return (
        <div className={"pageView"}>
            <div className={"pageWrapper"}>
                <Typography variant={"h4"} className={"pageTitle"}>My Companies List</Typography>
                <CacheProvider value={muiCache}>
                    <MUIDataTable
                        className={"customTableHeader"}
                        title={""}
                        data={simplifiedCompanies}
                        columns={columns}
                        options={options}
                    />
                </CacheProvider>
                <br />
                <Link to={"/companies/add-a-company"}>
                    <button className={"wooButtons"}> Add Company </button>
                </Link>
            </div>
        </div>
    )
}
