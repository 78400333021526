import React, { useState, useEffect} from 'react';
import "./formik.css";
import { Formik, Form} from 'formik';
import * as Yup from 'yup';
import {MyTextInput} from "./FormikFields";
import {useDispatch, useSelector} from "react-redux";
import Autocomplete from 'react-google-autocomplete';

import {editBillingAddress} from "../../actions";
import {Link,useNavigate} from "react-router-dom";
import { Box, Typography } from '@mui/material';

const minLength = 2;
const maxLength = 50;
const tooShortMessage = 'Too Short!';
const tooLongMessage = 'Too Long!';
const requiredMessage = 'Required';

const stringValidation = Yup.string()
    .min(minLength, tooShortMessage)
    .max(maxLength, tooLongMessage);

const requiredStringValidation = stringValidation.required(requiredMessage);

export const FormValidationSchema = Yup.object().shape({
    firstName: requiredStringValidation,
    lastName: requiredStringValidation,
    company: stringValidation,
    country: Yup.string().required(requiredMessage),
    streetAddress1: requiredStringValidation,
    streetAddress2: stringValidation,
    city: requiredStringValidation,
    state: Yup.string().required(requiredMessage),
    postcode: Yup.string()
        .min(minLength, tooShortMessage)
        .required(requiredMessage),
    phone: Yup.string()
        .min(minLength, tooShortMessage)
        .max(10, tooLongMessage)
        .required(requiredMessage),
    email: Yup.string().email('Invalid email').required(requiredMessage),
});

// And now we can use these
const BillingAddressForm = (props) => {

    const id = props.id;
    const billingData = useSelector((state) => state.userDetailsState.userDetails.billing);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const addressErrors =  useSelector((state) => state.userDetailsState.error);
    const addressLoad =  useSelector((state) => state.userDetailsState.loading);
    const [addressInputValue, setAddressInputValue] = useState('');
    const googlePlacesApiKey=process.env.REACT_APP_GOOGLE_PLACES_API_KEY;

    useEffect(()=>{
        if(billingData && billingData.address_1){
            setAddressInputValue(billingData.address_1);
        }
    },[billingData])

    return (
        <>
            <Typography variant="h4" className="pageTitle">Edit Billing Address</Typography>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    firstName: billingData.first_name,
                    lastName: billingData.last_name,
                    company: billingData.company,
                    country: billingData.country,
                    streetAddress1: billingData.address_1,
                    streetAddress2: billingData.address_2,
                    city: billingData.city,
                    state: billingData.state,
                    postcode: billingData.postcode,
                    phone: billingData.phone,
                    email: billingData.email
                }}
                validationSchema={FormValidationSchema}
                onSubmit={ (values, { setSubmitting }) => {
//                    alert(JSON.stringify(values, null, 2));
                    dispatch(editBillingAddress(
                        billingData,
                        values.lastName,
                        values.firstName,
                        values.email,
                        id,
                        values.phone,
                        values.company,
                        values.streetAddress1,
                        values.streetAddress2,
                        values.city,
                        values.state,
                        values.postcode,
                        values.country,
                        navigate));

                }}
            >
                {/*{values,handleChange, handleBlur}*/}
                {formik =>(
                    <Form className={"editBillingAddressForm"}>
                        <MyTextInput
                            label="First Name "
                            name="firstName"
                            type="text"
                            placeholder="Enter First Name"
                            required
                        />

                        <MyTextInput
                            label="Last Name "
                            name="lastName"
                            type="text"
                            placeholder="Enter Last Name"
                            required
                        />

                        <MyTextInput
                            label="Company Name (Optional) "
                            name="company"
                            type="text"
                            placeholder="Enter Company Name"
                        />

                        <div className="formFieldInput">
                            <label className="formikLabel" htmlFor="streetAddress1">Address Line 1</label>
                            <Autocomplete
                                apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
                                className={`formikInput`}
                                onChange={(event) => {
                                    setAddressInputValue(event.target.value);
                                    formik.setFieldValue("streetAddress1", event.target.value);
                                }}
                                onPlaceSelected={(place) => {
                                    const addressComponents = place.address_components;
                                    const streetNumber = addressComponents.find(component => component.types.includes("street_number"))?.long_name || "";
                                    const route = addressComponents.find(component => component.types.includes("route"))?.long_name || "";
                                    const city = addressComponents.find(component => component.types.includes("locality"))?.long_name || "";
                                    const state = addressComponents.find(component => component.types.includes("administrative_area_level_1"))?.short_name || "";
                                    const zip = addressComponents.find(component => component.types.includes("postal_code"))?.long_name || "";
                                    const country = addressComponents.find(component => component.types.includes("country"))?.long_name || "";
                                    const formattedAddress = `${streetNumber} ${route}`;
                                    setAddressInputValue(formattedAddress);
                                    formik.setFieldValue("streetAddress1", formattedAddress);
                                    formik.setFieldValue("city", city);
                                    formik.setFieldValue("state", state);
                                    formik.setFieldValue("postcode", zip);
                                    formik.setFieldValue("country", country);
                                }}
                                options={{
                                    types: ['address'],
                                    componentRestrictions: { country: "us" },
                                }}
                                defaultValue={formik.values.streetAddress1}
                                value={addressInputValue }
                                placeholder="Enter Street/House no."
                                name="streetAddress1"
                                type="input"
                            />
                        </div>
                        {/*<MyTextInput*/}
                        {/*    label="Address Line 1 "*/}
                        {/*    name="streetAddress1"*/}
                        {/*    type="text"*/}
                        {/*    placeholder="Enter Street/House no."*/}
                        {/*/>*/}

                        <MyTextInput
                            label="Address Line 2 "
                            name="streetAddress2"
                            type="text"
                            placeholder="Enter Apt/Unit no."
                        />

                        <MyTextInput
                            label="Town / City "
                            name="city"
                            type="text"
                            placeholder="Enter Town/City "
                            required
                        />

                        <MyTextInput
                            label="State "
                            name="state"
                            type="text"
                            placeholder="Enter State"
                            required
                        />

                        <MyTextInput
                            label="Zip Code "
                            name="postcode"
                            type="number"
                            placeholder="Enter Zipcode "
                            required
                        />

                        <MyTextInput
                            label="Country "
                            name="country"
                            type="text"
                            placeholder="Enter Country Name"
                            required
                        />

                        <MyTextInput
                            label="Phone "
                            name="phone"
                            type="number"
                            placeholder="Enter Phone no. "
                            required
                        />

                        <MyTextInput
                            label="Email Address "
                            name="email"
                            type="email"
                            placeholder="Enter Email ID"
                            required
                        />

                        <div className={"formButtonsDiv"}>
                            <button className={"wooButtons"} type="submit">Submit</button>
                            <Link to={"/edit-address"}>
                                <button className={"wooButtons"} >Cancel</button>
                            </Link>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default BillingAddressForm;


// import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
{/*<CountryDropdown*/}
{/*    label="Country "*/}
{/*    name="country"*/}
{/*    value={values.country}*/}
{/*    onChange={(_,e)=>handleChange(e)} onBlur={handleBlur}*/}
{/*/>*/}