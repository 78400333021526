import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import deletePaymentMethod from "../actions/deletePaymentMethod";

export const deletePreviousPaymentMethod = async (creditCards, expiry, lastFourDigits,dispatch,site_url, id,changedPayment,madeDefault) => {

    let deletionPerformed = false;
    // Loop through the creditCards array
    for (let card of creditCards) {
        if (card.expires === expiry && card.method.last4 === lastFourDigits && card.method.gateway === "first_data_payeezy_gateway_credit_card") {
            // Check if the default action exists
            if (card.actions && card.actions.delete) {
                const deleteURL = card.actions.delete.url;

                // Making the dispatch request
                await dispatch(deletePaymentMethod(id, site_url + deleteURL, creditCards.length));

                deletionPerformed = true;
                break;  // Break out of the loop once a match is found and the dispatch is done
            }
        }
    }
    if (!deletionPerformed && changedPayment === 'true' && madeDefault === 'true') {

        localStorage.removeItem('deletePrevious');
    }
}

