export const getCardWithLatestExpiry = (cards) => {
    if (!cards.length) return null;

    return cards.reduce((latestCard, currentCard) => {
        if (!currentCard.expires || !latestCard.expires) return latestCard;

        const [currentMonth, currentYear] = currentCard.expires.split('/').map(Number);
        const [latestMonth, latestYear] = latestCard.expires.split('/').map(Number);

        if (currentYear > latestYear || (currentYear === latestYear && currentMonth > latestMonth)) {
            return currentCard;
        }
        return latestCard;
    }, cards[0]);
};
