import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import RevalidateCreditCard from './Pages/paymentMethods/components/RevalidateCreditCard';  // Update this import path
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,  // Adjust width as required
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const SimpleModal = ({ show, onClose, id, customerProfileId, billingInfo, changePaymentMethodURL, wcsnonce, onSubmitSuccess }) => {
    return (
        <Modal
            open={show}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Box sx={style}>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography id="simple-modal-title" variant="h6" component="h2">
                    For security purposes please re-enter your credit card information.
                </Typography>
                <Typography id="simple-modal-description" sx={{ mt: 2 }}>
                    <RevalidateCreditCard
                        id={id}
                        customerProfileId={customerProfileId}
                        billingInfo={billingInfo}
                        changePaymentMethodURL={changePaymentMethodURL}
                        wcsnonce={wcsnonce}
                        onSubmitSuccess={onSubmitSuccess}
                    />
                </Typography>
            </Box>
        </Modal>
    );
}

export default SimpleModal;