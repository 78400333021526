import React, { useEffect, useState } from "react";
import '../../Pages/subscriptionList/subcriptionsList.css';
import SwitchRightIcon from '@mui/icons-material/SwitchRight';
import Tooltip from '@mui/material/Tooltip';

export default function generateSortableColumn(
    columnName,
    key,
    extraOptions = {},
    isSortable = true,
    sortedColumn,
    setSortedColumn,
    sortDirection,
    setSortDirection
) {
    return {
        name: columnName,
        options: {
            ...extraOptions,
            customHeadRender: (columnMeta, updateDirection) => {
                let iconRotation = -90;  // default (pointing upwards)

                if (isSortable) {
                    if (sortedColumn === columnMeta.name && sortDirection === 'asc') iconRotation = -90;
                    else if (sortedColumn === columnMeta.name && sortDirection === 'desc') iconRotation = 90;
                }

                const handleSort = () => {
                    if (isSortable) {
                        if (sortedColumn !== columnMeta.name || sortDirection === 'desc' || sortDirection === 'none') {
                            setSortedColumn(columnMeta.name);
                            setSortDirection('asc');
                        } else if (sortDirection === 'asc') {
                            setSortDirection('desc');
                        }
                        updateDirection(key);
                    }
                };

                const columnHeaderContent = (
                    <>
                        {columnMeta.name}
                        {isSortable && <SwitchRightIcon style={{ transform: `rotate(${iconRotation}deg)`, verticalAlign: 'middle' }} />}
                    </>
                );

                return isSortable ? (
                    <Tooltip title="Sort" placement="top">
                        <th
                            key={key}
                            onClick={handleSort}
                            style={{ cursor: "pointer", textAlign: "Center", fontSize: "1.4rem", paddingBottom: "10px", borderBottom: "1px solid #e0e0e0"}}
                        >
                            {columnHeaderContent}
                        </th>
                    </Tooltip>
                ) : (
                    <th
                        key={key}
                        onClick={handleSort}
                        style={{ cursor: "pointer", textAlign: "Center", fontSize: "1.4rem", paddingBottom: "10px", borderBottom: "1px solid #e0e0e0"}}
                    >
                        {columnHeaderContent}
                    </th>
                );
            },
        }
    };
}
