import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import MUIDataTable from "mui-datatables";
import { Typography } from "@mui/material";
import { CacheProvider } from "@emotion/react";
import { Link } from "react-router-dom";
import createCache from "@emotion/cache";
import Tooltip from '@mui/material/Tooltip';
import { TableCell, TableRow, Paper } from "@mui/material";
import generateSortableColumn from "../../components/functions/generateSortableColumn";
import { getStatusColor, getStatusIcon } from "../orderList/ordersUtils";
import ConfirmationModal from "./ConfirmationModal";
import * as actions from "../../actions/actionTypes";

const muiCache = createCache({
    key: 'mui-datatables',
    prepend: true,
});

function AdminRequests(props) {
    const [requests, setRequests] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const dispatch = useDispatch();
    const jiraIssuesList = props.jiraIssuesList || [];
    const issueAdded = props.issueAdded;
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (issueAdded) {
            setModalOpen(true);
        }
    }, [issueAdded]);

    const handleModalClose = () => {
        setModalOpen(false);
        setTimeout(() => {
            dispatch({ type: actions.RESET_ISSUE_ADDED });
        }, 0);
    };

    useEffect(() => {
        const storedExpandedRows = sessionStorage.getItem("expandedSupportRequestRows");

        if (storedExpandedRows) {
            setRows(JSON.parse(storedExpandedRows));
        }
    }, []);

    useEffect(() => {
        if (jiraIssuesList && Array.isArray(jiraIssuesList)) {
            const formattedData = jiraIssuesList.map(issue => {
                if (issue && issue.fields && issue.fields.summary && issue.fields.status) {
                    const issueSummary = issue.fields.summary.split(',')[0];

                    const summaryText = issueSummary.startsWith('Subscription ID:')
                        ? `Cancel ${issueSummary}`
                        : issueSummary;

                    return {
                        id: issue.key,
                        date: new Date(issue.fields.created).toLocaleDateString(),
                        status: issue.fields.status.statusCategory.name.replace(/-/g, "").replace(/\s/g, "").toUpperCase(),
                        summary: summaryText,
                        actions: <div></div> // Placeholder for actions
                    };
                } else {

                    return {
                        id: issue?.key || "Unknown ID",
                        date: "Unknown Date",
                        status: "Unknown Status",
                        summary: "No Summary Available",
                        actions: <div></div>
                    };
                }
            });
            setRequests(formattedData);
        }
    }, [jiraIssuesList]);

    const parseJiraDescription = (description) => {
        if (!description || !description.content) return [];

        return description.content.reduce((acc, paragraph) => {
            paragraph.content.forEach(part => {
                if (part.type === 'text' && part.text.includes(':')) {
                    const [label, value] = part.text.split(':').map(text => text.trim());
                    acc.push({ label, value });
                }
            });
            return acc;
        }, []);
    };

    const data = requests.map(request => {

        const RequestStatusIcon = getStatusIcon(request.status.replace(/-/g, "").toUpperCase());
        const requestStatusColor = getStatusColor(request.status.replace(/-/g, "").toUpperCase());

        const requestStatusTooltip = (
            <Tooltip title={request.status.replace(/-/g, "").toUpperCase()}>
                <RequestStatusIcon style={{ color: requestStatusColor }} />
            </Tooltip>
        );

        const requestIdStatus = (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                <span>{request.id}</span>
                {requestStatusTooltip}
            </div>
        );

        return [request.id,requestIdStatus, request.date, request.summary, request.actions];
    });

    const columns = [
        {
            name: "ID",
            options: {
                display: false,
            }
        },
        generateSortableColumn("Request ID", 0, { filter: false, sortThirdClickReset: true, sortDescFirst: false }),
        generateSortableColumn("Date", 1, { filter: false, sortThirdClickReset: true, align: "center", sortDescFirst: false }),
        generateSortableColumn("Summary", 2, { filter: false, align: "center", sortThirdClickReset: true, sortDescFirst: false }),
        generateSortableColumn("Actions", 3, { filter: false, align: "center" })
    ];

    const options = {
        filterType: "dropdown",
        selectableRows: "none",
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        rowsExpanded: rows,
        rowsPerPageOptions: [10, 15, 20, 25, 30],
        onTableInit: () => {
            const storedExpandedRows = sessionStorage.getItem("expandedSupportRequestRows");
            if (storedExpandedRows) {
                setRows(JSON.parse(storedExpandedRows));
            }
        },
        setRowProps: (row, dataIndex, rowIndex) => {
            return {
                style: {
                    backgroundColor: rowIndex % 2 === 0 ? 'white' : '#f0f0f1',
                    fontSize: '18px'
                },
            };
        },
        renderExpandableRow: (rowData, rowMeta) => {
            const issueKey = rowData[0]?.props?.children?.[0] || rowData[0];
            const issue = jiraIssuesList.find(issue => issue.key === issueKey);
            const colSpan = rowData.length + 1;

            if (!issue) {
                return (
                    <TableRow>
                        <TableCell colSpan={colSpan} style={{ textAlign: 'center' }}>
                            <Typography variant="body2">Issue not found or invalid key.</Typography>
                        </TableCell>
                    </TableRow>
                );
            }

            if (issue.fields && issue.fields.description) {
                const descriptionItems = parseJiraDescription(issue.fields.description);

                return (
                    <TableRow key={issue.id}>
                        <TableCell colSpan={colSpan}>
                            <Paper className={"paperAddress"} style={{ padding: '16px', backgroundColor: '#f9f9f9' }}>
                                <div className={"order-sub-details-address"}>
                                    <div className={"orderMetaData"}>
                                        <div className={"serviceName"}>
                                            <Typography variant="h5" style={{ fontWeight: 600 }}>Request Details:</Typography>
                                        </div>
                                        <div className={"serviceMetaData"}>
                                            <ul className={"metaDataList"}>
                                                {descriptionItems.map(({ label, value }, index) => (
                                                    <li className={"metadataTitle"} key={index}>
                                                <span>
                                                    <strong>{label}</strong>
                                                    <br />
                                                    {value}
                                                </span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        </TableCell>
                    </TableRow>
                );
            } else {
                return (
                    <TableRow>
                        <TableCell colSpan={colSpan} style={{ textAlign: 'center' }}>
                            <Typography variant="body2">No additional details available.</Typography>
                        </TableCell>
                    </TableRow>
                );
            }
        },
        onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
            const expandedRowIndexes = allExpanded.map(row => row.dataIndex);
            sessionStorage.setItem('expandedSupportRequestRows', JSON.stringify(expandedRowIndexes));
        },
    };

    return (
        <div className="pageView">
            <div className="pageWrapper">
                <Typography variant="h4" className="pageTitle">Support Requests</Typography>
                <CacheProvider value={muiCache}>
                    <MUIDataTable
                        className="customTableHeader"
                        title={""}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </CacheProvider>
                <br />
                <ConfirmationModal
                    isOpen={isModalOpen}
                    onClose={handleModalClose}
                    acknoledgementMsg={'Thank you! Your request has been submitted. Someone will be in contact in 1-2 business days.'}
                />
                <Link to={"/admin-requests/add-support-request"}>
                    <button className={"wooButtons"}> Add Support Request </button>
                </Link>
            </div>
        </div>
    );
}

export default AdminRequests;
