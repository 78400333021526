import * as React from 'react';
import "./paymentMethods.css";
// import "../../styles/style.scss";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import MUIDataTable from "mui-datatables"
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Link} from "react-router-dom";
import { FaCcMastercard, FaCcVisa, FaCcDiscover, FaCcAmex, FaCcDinersClub, FaCcJcb, FaRegCreditCard, FaCreditCard } from 'react-icons/fa';
import { Typography } from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import getMakeDefault from "../../actions/getMakeDefault";
import getUpdatedSubscriptions from "../../actions/getUpdatedSubscriptions"
import deletePaymentMethod from "../../actions/deletePaymentMethod";
import {toastr} from 'react-redux-toastr';
import {useEffect} from "react";
import DeleteCardFlow from "./components/deleteCardFlow";
import CustomModal from "./components/CustomModal";
import MultiCheckboxModal from "./components/MultiCheckboxModal";
import changePaymentMethodForAllSubs from "../../actions/changePaymentMethodForAllSubs";
import { useNavigate } from "react-router-dom";
import { getCardType } from './components/addCreditCard';

export default function PaymentMethods(props) {

    const paymentMethods = useSelector((state) => state.paymentMethodsState.paymentMethods);
    const dispatch = useDispatch();
    const paymentLoad =  useSelector((state) => state.paymentMethodsState.loadingGet);
    const madeDefault =  useSelector((state) => state.paymentMethodsState.madeDefault);
    const activeSubs =  props.activeSubs;
    const [value, setValue] = React.useState('1');
    const currDefault = useSelector((state) => state.paymentMethodsState.currDefault);
    const site_url = process.env.REACT_APP_WP_SITE_URL;
    const navigate = useNavigate ();
    const changePaymentMethodURL = useSelector((state) => state.subscriptionsState.changePaymentMethodURL);
    const match = changePaymentMethodURL.match(/\/order-pay\/(\d+)\//);
    const changePaymentID = match ? match[1] : null;

    const [updateSubscriptions, setUpdateSubscriptions] = React.useState(false);

    const { cc: creditCards, pp: paypalPayments } = paymentMethods;
    const userId = props.id;

    let substring = (activeSubs.join());

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDelete = (url) => {
        const numberOfCards = creditCards.length;
        dispatch(deletePaymentMethod(props.id,url,numberOfCards));
    }

    const creditCardColumns = [
        {
            name: "Method",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta, updateDirection) => {
                    return (
                        <th
                            key={0}
                            onClick={() => updateDirection(0)}
                            style={{ cursor: "pointer", textAlign: "center", fontSize: "1.4rem", paddingBottom: "10px",paddingLeft: "40px" ,borderBottom: "1px solid #e0e0e0", minWidth:""}}
                        >
                            {columnMeta.name}
                        </th>
                    )
                },
            }
        },
        {
            name: "Last 4",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta, updateDirection) => {
                    return (
                        <th
                            key={0}
                            onClick={() => updateDirection(0)}
                            style={{ cursor: "pointer", textAlign: "center", fontSize: "1.4rem", paddingBottom: "10px" ,borderBottom: "1px solid #e0e0e0", width:"10%"}}
                        >
                            {columnMeta.name}
                        </th>
                    )
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div style={{textAlign: 'center'}}>{value}</div>;
                },
            }
        },
        {
            name: "Expires",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta, updateDirection) => {
                    return (
                        <th
                            key={0}
                            onClick={() => updateDirection(0)}
                            style={{ cursor: "pointer", textAlign: "center", fontSize: "1.4rem", paddingBottom: "10px" ,borderBottom: "1px solid #e0e0e0", width:"10%"}}
                        >
                            {columnMeta.name}
                        </th>
                    )
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div style={{textAlign: 'center'}}>{value}</div>;
                },
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta, updateDirection) => {
                    return (
                        <th
                            key={0}
                            onClick={() => updateDirection(0)}
                            style={{ cursor: "pointer", textAlign: "center", fontSize: "1.4rem", paddingBottom: "10px" ,borderBottom: "1px solid #e0e0e0"}}
                        >
                            {columnMeta.name}
                        </th>
                    )
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div style={{textAlign: 'center'}}>{value}</div>;
                },
            }
        },
        {
            name: "Subscriptions",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta, updateDirection) => {
                    return (
                        <th
                            key={0}
                            onClick={() => updateDirection(0)}
                            style={{ cursor: "pointer", textAlign: "center", fontSize: "1.4rem", paddingBottom: "10px" ,borderBottom: "1px solid #e0e0e0", width:"50%"}}
                        >
                            {columnMeta.name}
                        </th>
                    )
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div style={{textAlign: 'center'}}>{value}</div>;
                },
            }
        },
        {
            name: "Actions",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta, updateDirection) => {
                    return (
                        <th
                            key={0}
                            onClick={() => updateDirection(0)}
                            style={{ cursor: "pointer", textAlign: "center", fontSize: "1.4rem", paddingBottom: "10px" ,borderBottom: "1px solid #e0e0e0", width:"20%"}}
                        >
                            {columnMeta.name}
                        </th>
                    )
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div style={{textAlign: 'center'}}>{value}</div>;
                },
            }
        }
    ];

    const paypalColumns = [
        {
            name: "Method",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta, updateDirection) => {
                    return (
                        <th
                            key={0}
                            onClick={() => updateDirection(0)}
                            style={{ cursor: "pointer", textAlign: "Center", fontSize: "1.4rem", paddingBottom: "10px" ,borderBottom: "1px solid #e0e0e0"}}
                        >
                            {columnMeta.name}
                        </th>
                    )
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div style={{textAlign: 'center'}}>{value}</div>;
                },
            }
        },
        {
            name: "Actions",
            options: {
                filter: false,
                sortThirdClickReset: true,
                customHeadRender: (columnMeta, updateDirection) => {
                    return (
                        <th
                            key={0}
                            onClick={() => updateDirection(0)}
                            style={{ cursor: "pointer", textAlign: "Center", fontSize: "1.4rem", paddingBottom: "10px" ,borderBottom: "1px solid #e0e0e0"}}
                        >
                            {columnMeta.name}
                        </th>
                    )
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <div style={{textAlign: 'center'}}>{value}</div>;
                },
            }
        }
    ];

    const options = {
        filter: true,
        selectableRows: false,
        selectableRowsOnClick: false,
        rowsPerPageOptions: [10, 15, 20 , 25, 30],
        setRowProps: (row, dataIndex, rowIndex) => {
            if(rowIndex % 2 === 0 ){
                return {
                    style: {
                        backgroundColor: 'white',
                        fontSize: '18px'
                    },
                }
            }
            else{
                return {
                    style: {
                        backgroundColor: '#f0f0f1',
                        fontSize: '18px'
                    }
                }
            }
        },
        setCellProps: () => ({ align: 'right' })
    };

    const hasActiveSubscriptions = () => {
        return creditCards.some(card => Array.isArray(card.subscriptions) && card.subscriptions.length > 0);
    }

    const paymentMethodsList = () => {
        return (
            <div>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Credit Cards" value="1" />
                                <Tab label="Paypal Payments" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <MUIDataTable
                                className="customTableHeader"
                                title={""}
                                data={creditCards && creditCards.map((item,index)=>{
                                    const {expires, is_default, method : {gateway,brand, last4 },actions,paymentProfileId, subscriptions, _wcsnonce, _wpnonce  } = item;

                                    const renderSubscriptions = (subscriptions) => {
                                        if (subscriptions === 'N/A') {
                                            return <div>N/A</div>;
                                        }

                                        return (
                                            <div>
                                                {subscriptions.map((item) => (
                                                    <div className="subIds" key={item}>
                                                        #
                                                        <Link to={`/view-subscription/${item}`} className="idNamelink">{item}</Link>&ensp;
                                                    </div>
                                                ))}
                                            </div>
                                        );
                                    };

                                    const subscriptionIds = renderSubscriptions(subscriptions);

                                    const isDefault = is_default === true ? <div>Default Card</div> : '';

                                    const deleteButton = ()=>{
                                        return actions.hasOwnProperty('delete') ? (
                                            <div>
                                                <CustomModal
                                                    triggerButtonText="Delete"
                                                    questionText="Are you sure you want to delete this payment method?"
                                                    confirmButtonText="Yes"
                                                    cancelButtonText="Cancel"
                                                    onConfirm={() => {
                                                        const {delete: {url: deleteURL} } = actions;
                                                        handleDelete(site_url+deleteURL);
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <div>

                                            </div>
                                        )
                                    }

                                    const makeDefaultButton = () => {
                                        return (actions.hasOwnProperty('default') && gateway !== 'first_data_payeezy_gateway_credit_card') ? (
                                            <div>

                                                <MultiCheckboxModal
                                                    triggerButtonText="Make Default"
                                                    questionText="Are you sure you want to make this a default payment method?"
                                                    updateAllSubscriptionsText={"Update all subscriptions to this default payment method."}
                                                    addPaymentMethod={false}
                                                    onConfirm={async (selected,updateAllSubscriptionsFlag) => {
                                                        const {default: {url: defaultURL} } = actions;
                                                        const defaultIndex = index;
                                                        const regex = /\d+/g;
                                                        const token = defaultURL.match(regex);
                                                        const token_id = token[0];

                                                        try {
                                                            await dispatch(getMakeDefault(props.id,site_url+ '/my-account'+defaultURL));
                                                            const updateAll = updateAllSubscriptionsFlag;
                                                            if (updateAllSubscriptionsFlag == "1") {
                                                                const note = "updated_all_subscriptions";
                                                                dispatch(changePaymentMethodForAllSubs(userId,expires,paymentProfileId,_wcsnonce,_wpnonce,gateway,changePaymentMethodURL,changePaymentID,brand,"","","",updateAll,note,navigate));
                                                        //        await dispatch(getUpdatedSubscriptions(props.id, site_url + '/my-account/payment-methods/?update-subscription-tokens=true&token-id=' + token_id + '&_wcsnonce=' + _wcsnonce ));
                                                            }

                                                        } catch (error){
                                                            console.log("Caught an error"+error);
                                                        }
                                                    }}
                                                />

                                            </div>
                                        ) : (
                                            <div>

                                            </div>
                                        )
                                    }

                                    const ActionColumn = (
                                        <div className={'actionButtons'}>

                                            {deleteButton()}
                                            {makeDefaultButton()}
                                            {/*{updateSubscriptionsModal()}*/}
                                        </div>
                                    );

                                    let name = '';

                                    const brandName = (brand) =>{
                                        switch (brand) {
                                            case 'Mastercard':
                                                return name = <div><FaCcMastercard className={"payIcon"}/></div>;
                                            case 'Visa':
                                                return name = <div><FaCcVisa className={"payIcon"}/></div>;
                                            case 'Discover':
                                                return name = <div><FaCcDiscover className={"payIcon"}/></div>;
                                            case 'Amex' || 'American Express':
                                                return name = <div><FaCcAmex className={"payIcon"}/></div>;
                                            case 'Diners':
                                                return name = <div><FaCcDinersClub className={"payIcon"}/></div>;
                                            case 'Jcb':
                                                return name = <div><FaCcJcb className={"payIcon"}/></div>;
                                            default:
                                                return name = <div><FaRegCreditCard className={"payIcon"}/></div>;
                                        }
                                    }

                                    brandName(brand);

                                    return [name, last4, expires, isDefault,subscriptionIds,ActionColumn];
                                })}
                                columns={creditCardColumns}
                                options={options}
                            /><br/>
                            <Link to={"/payment-methods/add-payment-method"}>
                                <button className={"wooButtons"} > Add Payment Method </button>
                            </Link>
                        </TabPanel>
                        <TabPanel value="2">
                            <MUIDataTable
                                title={""}
                                data={paypalPayments && paypalPayments.map((item) => {
                                    const {id,type,source : {paypal: {payer: {email_address}}}} = item;
                                    const deleteButton = (<div>
                                        <Link to={"/payment-methods"}>
                                            <button className={"wooButtons"}>
                                                Delete
                                            </button>
                                        </Link>
                                    </div>);
                                    return [email_address,deleteButton];
                                })}
                                columns={paypalColumns}
                                options={options}
                            /></TabPanel>
                    </TabContext>
                </Box>

            </div>
        )
    }

    return (
        <div className={"pageView"}>
            <div className={"pageWrapper"}>
                <Typography variant="h4" className={"pageTitle"}>
                    My Payment Methods Lists
                </Typography>
                {/*<h1 className={"pageTitle"}>My Payment Methods Lists</h1>*/}
                {paymentMethodsList()}
            </div>
        </div>
    )

}