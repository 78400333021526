/**
 * ordersState Reducer is used to store all Orders List Details
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import * as actions from '../actions/actionTypes'

const initState = {
  orders: [
    {
      "id": 0,
      "parent_id": 0,
      "number": "",
      "order_key": "",
      "created_via": "",
      "version": "",
      "status": "",
      "currency": "",
      "date_created": "",
      "date_created_gmt": "",
      "date_modified": "",
      "date_modified_gmt": "",
      "discount_total": "0.00",
      "discount_tax": "0.00",
      "shipping_total": "00.00",
      "shipping_tax": "0.00",
      "cart_tax": "0.00",
      "total": "00.00",
      "total_tax": "0.00",
      "prices_include_tax": false,
      "customer_id": 0,
      "customer_ip_address": "",
      "customer_user_agent": "",
      "customer_note": "",
      "billing": {
        "first_name": "",
        "last_name": "",
        "company": "",
        "address_1": "",
        "address_2": "",
        "city": "",
        "state": "",
        "postcode": "",
        "country": "",
        "email": "",
        "phone": ""
      },
      "shipping": {
        "first_name": "",
        "last_name": "",
        "company": "",
        "address_1": "",
        "address_2": "",
        "city": "",
        "state": "",
        "postcode": "",
        "country": ""
      },
      "payment_method": "",
      "payment_method_title": "",
      "transaction_id": "",
      "date_paid": "",
      "date_paid_gmt": "",
      "date_completed": null,
      "date_completed_gmt": null,
      "cart_hash": "",
      "meta_data": [
        {
          "id": 13106,
          "key": "_download_permissions_granted",
          "value": "yes"
        },
        {
          "id": 13109,
          "key": "_order_stock_reduced",
          "value": "yes"
        }
      ],
      "line_items": [
        {
          "id": 11,
          "name": "",
          "product_id": 93,
          "variation_id": 0,
          "quantity": 2,
          "tax_class": "",
          "subtotal": "0.00",
          "subtotal_tax": "0.00",
          "total": "0.00",
          "total_tax": "0.00",
          "taxes": [
            {
              "id": 75,
              "total": "0.00",
              "subtotal": "0.00"
            }
          ],
          "meta_data": [],
          "sku": "",
          "price": 3
        },
        {
          "id": 22,
          "name": "",
          "product_id": 22,
          "variation_id": 23,
          "quantity": 1,
          "tax_class": "",
          "subtotal": "00.00",
          "subtotal_tax": "0.00",
          "total": "00.00",
          "total_tax": "0.00",
          "taxes": [
            {
              "id": 75,
              "total": "0.9",
              "subtotal": "0.9"
            }
          ],
          "meta_data": [
            {
              "id": 2095,
              "key": "",
              "value": ""
            },
            {
              "id": 2096,
              "key": "",
              "value": ""
            }
          ],
          "sku": "Bar3",
          "price": 12
        }
      ],
      "tax_lines": [
        {
          "id": 318,
          "rate_code": "",
          "rate_id": 75,
          "label": "",
          "compound": false,
          "tax_total": "0.00",
          "shipping_tax_total": "0.00",
          "meta_data": []
        }
      ],
      "shipping_lines": [
        {
          "id": 317,
          "method_title": "",
          "method_id": "",
          "total": "00.00",
          "total_tax": "0.00",
          "taxes": [],
          "meta_data": []
        }
      ],
      "fee_lines": [],
      "coupon_lines": [],
      "refunds": [],
      "_links": {
        "self": [
          {
            "href": "https://example.com/wp-json/wc/v3/orders/727"
          }
        ],
        "collection": [
          {
            "href": "https://example.com/wp-json/wc/v3/orders"
          }
        ]
      }
    },
    {
      "id": 0,
      "parent_id": 0,
      "number": "723",
      "order_key": "",
      "created_via": "",
      "version": "",
      "status": "",
      "currency": "",
      "date_created": "",
      "date_created_gmt": "",
      "date_modified": "",
      "date_modified_gmt": "",
      "discount_total": "0.00",
      "discount_tax": "0.00",
      "shipping_total": "00.00",
      "shipping_tax": "0.00",
      "cart_tax": "0.00",
      "total": "00.00",
      "total_tax": "0.00",
      "prices_include_tax": false,
      "customer_id": 26,
      "customer_ip_address": "127.0.0.1",
      "customer_user_agent": "mozilla/5.0 (x11; ubuntu; linux x86_64; rv:52.0) gecko/20100101 firefox/52.0",
      "customer_note": "",
      "billing": {
        "first_name": "",
        "last_name": "",
        "company": "",
        "address_1": "",
        "address_2": "",
        "city": "",
        "state": "",
        "postcode": "",
        "country": "",
        "email": "",
        "phone": ""
      },
      "shipping": {
        "first_name": "",
        "last_name": "",
        "company": "",
        "address_1": "",
        "address_2": "",
        "city": "",
        "state": "",
        "postcode": "",
        "country": ""
      },
      "payment_method": "",
      "payment_method_title": "",
      "transaction_id": "",
      "date_paid": null,
      "date_paid_gmt": null,
      "date_completed": "",
      "date_completed_gmt": "",
      "cart_hash": "",
      "meta_data": [
        {
          "id": 13023,
          "key": "_download_permissions_granted",
          "value": "yes"
        }
      ],
      "line_items": [
        {
          "id": 33,
          "name": "",
          "product_id": 87,
          "variation_id": 0,
          "quantity": 1,
          "tax_class": "",
          "subtotal": "0.00",
          "subtotal_tax": "0.00",
          "total": "0.00",
          "total_tax": "0.00",
          "taxes": [],
          "meta_data": [],
          "sku": "",
          "price": 9
        },
        {
          "id": 313,
          "name": "",
          "product_id": 34,
          "variation_id": 0,
          "quantity": 1,
          "tax_class": "",
          "subtotal": "00.00",
          "subtotal_tax": "0.00",
          "total": "00.00",
          "total_tax": "0.00",
          "taxes": [],
          "meta_data": [],
          "sku": "",
          "price": 20
        }
      ],
      "tax_lines": [],
      "shipping_lines": [
        {
          "id": 312,
          "method_title": "",
          "method_id": "",
          "total": "00.00",
          "total_tax": "0.00",
          "taxes": [],
          "meta_data": [
            {
              "id": 2057,
              "key": "",
              "value": ""
            }
          ]
        }
      ],
      "fee_lines": [],
      "coupon_lines": [],
      "refunds": [
        {
          "id": 726,
          "refund": "",
          "total": "00.00"
        },
        {
          "id": 724,
          "refund": "",
          "total": "0.00"
        }
      ],
      "_links": {
        "self": [
          {
            "href": "https://example.com/wp-json/wc/v3/orders/723"
          }
        ],
        "collection": [
          {
            "href": "https://example.com/wp-json/wc/v3/orders"
          }
        ],
        "customer": [
          {
            "href": "https://example.com/wp-json/wc/v3/customers/26"
          }
        ]
      }
    }
  ],
  loading: false,
  error: null
}

const ordersState = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case actions.GET_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload
      }
    case actions.GET_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default ordersState
