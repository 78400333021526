/**
 * productsState Reducer is used to store all Products List Details
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import * as actions from '../actions/actionTypes'

const initState = {
  products: [
    {
      "id": 799,
      "name": "Ship Your Idea",
      "slug": "ship-your-idea-22",
      "permalink": "https://example.com/product/ship-your-idea-22/",
      "date_created": "2017-03-23T17:03:12",
      "date_created_gmt": "2017-03-23T20:03:12",
      "date_modified": "2017-03-23T17:03:12",
      "date_modified_gmt": "2017-03-23T20:03:12",
      "type": "variable",
      "status": "publish",
      "featured": false,
      "catalog_visibility": "visible",
      "description": "<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>\n",
      "short_description": "<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>\n",
      "sku": "",
      "price": "",
      "regular_price": "",
      "sale_price": "",
      "date_on_sale_from": null,
      "date_on_sale_from_gmt": null,
      "date_on_sale_to": null,
      "date_on_sale_to_gmt": null,
      "price_html": "",
      "on_sale": false,
      "purchasable": false,
      "total_sales": 0,
      "virtual": false,
      "downloadable": false,
      "downloads": [],
      "download_limit": -1,
      "download_expiry": -1,
      "external_url": "",
      "button_text": "",
      "tax_status": "taxable",
      "tax_class": "",
      "manage_stock": false,
      "stock_quantity": null,
      "stock_status": "instock",
      "backorders": "no",
      "backorders_allowed": false,
      "backordered": false,
      "sold_individually": false,
      "weight": "",
      "dimensions": {
        "length": "",
        "width": "",
        "height": ""
      },
      "shipping_required": true,
      "shipping_taxable": true,
      "shipping_class": "",
      "shipping_class_id": 0,
      "reviews_allowed": true,
      "average_rating": "0.00",
      "rating_count": 0,
      "related_ids": [
        31,
        22,
        369,
        414,
        56
      ],
      "upsell_ids": [],
      "cross_sell_ids": [],
      "parent_id": 0,
      "purchase_note": "",
      "categories": [
        {
          "id": 9,
          "name": "Clothing",
          "slug": "clothing"
        },
        {
          "id": 14,
          "name": "T-shirts",
          "slug": "t-shirts"
        }
      ],
      "tags": [],
      "images": [
        {
          "id": 795,
          "date_created": "2017-03-23T14:03:08",
          "date_created_gmt": "2017-03-23T20:03:08",
          "date_modified": "2017-03-23T14:03:08",
          "date_modified_gmt": "2017-03-23T20:03:08",
          "src": "https://example.com/wp-content/uploads/2017/03/T_4_front-11.jpg",
          "name": "",
          "alt": ""
        },
        {
          "id": 796,
          "date_created": "2017-03-23T14:03:09",
          "date_created_gmt": "2017-03-23T20:03:09",
          "date_modified": "2017-03-23T14:03:09",
          "date_modified_gmt": "2017-03-23T20:03:09",
          "src": "https://example.com/wp-content/uploads/2017/03/T_4_back-10.jpg",
          "name": "",
          "alt": ""
        },
        {
          "id": 797,
          "date_created": "2017-03-23T14:03:10",
          "date_created_gmt": "2017-03-23T20:03:10",
          "date_modified": "2017-03-23T14:03:10",
          "date_modified_gmt": "2017-03-23T20:03:10",
          "src": "https://example.com/wp-content/uploads/2017/03/T_3_front-10.jpg",
          "name": "",
          "alt": ""
        },
        {
          "id": 798,
          "date_created": "2017-03-23T14:03:11",
          "date_created_gmt": "2017-03-23T20:03:11",
          "date_modified": "2017-03-23T14:03:11",
          "date_modified_gmt": "2017-03-23T20:03:11",
          "src": "https://example.com/wp-content/uploads/2017/03/T_3_back-10.jpg",
          "name": "",
          "alt": ""
        }
      ],
      "attributes": [
        {
          "id": 6,
          "name": "Color",
          "position": 0,
          "visible": false,
          "variation": true,
          "options": [
            "Black",
            "Green"
          ]
        },
        {
          "id": 0,
          "name": "Size",
          "position": 0,
          "visible": true,
          "variation": true,
          "options": [
            "S",
            "M"
          ]
        }
      ],
      "default_attributes": [],
      "variations": [],
      "grouped_products": [],
      "menu_order": 0,
      "meta_data": [],
      "_links": {
        "self": [
          {
            "href": "https://example.com/wp-json/wc/v3/products/799"
          }
        ],
        "collection": [
          {
            "href": "https://example.com/wp-json/wc/v3/products"
          }
        ]
      }
    },
    {
      "id": 794,
      "name": "Premium Quality",
      "slug": "premium-quality-19",
      "permalink": "https://example.com/product/premium-quality-19/",
      "date_created": "2017-03-23T17:01:14",
      "date_created_gmt": "2017-03-23T20:01:14",
      "date_modified": "2017-03-23T17:01:14",
      "date_modified_gmt": "2017-03-23T20:01:14",
      "type": "simple",
      "status": "publish",
      "featured": false,
      "catalog_visibility": "visible",
      "description": "<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>\n",
      "short_description": "<p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>\n",
      "sku": "",
      "price": "21.99",
      "regular_price": "21.99",
      "sale_price": "",
      "date_on_sale_from": null,
      "date_on_sale_from_gmt": null,
      "date_on_sale_to": null,
      "date_on_sale_to_gmt": null,
      "price_html": "<span class=\"woocommerce-Price-amount amount\"><span class=\"woocommerce-Price-currencySymbol\">&#36;</span>21.99</span>",
      "on_sale": false,
      "purchasable": true,
      "total_sales": 0,
      "virtual": false,
      "downloadable": false,
      "downloads": [],
      "download_limit": -1,
      "download_expiry": -1,
      "external_url": "",
      "button_text": "",
      "tax_status": "taxable",
      "tax_class": "",
      "manage_stock": false,
      "stock_quantity": null,
      "stock_status": "instock",
      "backorders": "no",
      "backorders_allowed": false,
      "backordered": false,
      "sold_individually": false,
      "weight": "",
      "dimensions": {
        "length": "",
        "width": "",
        "height": ""
      },
      "shipping_required": true,
      "shipping_taxable": true,
      "shipping_class": "",
      "shipping_class_id": 0,
      "reviews_allowed": true,
      "average_rating": "0.00",
      "rating_count": 0,
      "related_ids": [
        463,
        47,
        31,
        387,
        458
      ],
      "upsell_ids": [],
      "cross_sell_ids": [],
      "parent_id": 0,
      "purchase_note": "",
      "categories": [
        {
          "id": 9,
          "name": "Clothing",
          "slug": "clothing"
        },
        {
          "id": 14,
          "name": "T-shirts",
          "slug": "t-shirts"
        }
      ],
      "tags": [],
      "images": [
        {
          "id": 792,
          "date_created": "2017-03-23T14:01:13",
          "date_created_gmt": "2017-03-23T20:01:13",
          "date_modified": "2017-03-23T14:01:13",
          "date_modified_gmt": "2017-03-23T20:01:13",
          "src": "https://example.com/wp-content/uploads/2017/03/T_2_front-4.jpg",
          "name": "",
          "alt": ""
        },
        {
          "id": 793,
          "date_created": "2017-03-23T14:01:14",
          "date_created_gmt": "2017-03-23T20:01:14",
          "date_modified": "2017-03-23T14:01:14",
          "date_modified_gmt": "2017-03-23T20:01:14",
          "src": "https://example.com/wp-content/uploads/2017/03/T_2_back-2.jpg",
          "name": "",
          "alt": ""
        }
      ],
      "attributes": [],
      "default_attributes": [
        {
          "id": 6,
          "name": "Color",
          "option": "black"
        },
        {
          "id": 0,
          "name": "Size",
          "option": "S"
        }
      ],
      "variations": [],
      "grouped_products": [],
      "menu_order": 0,
      "meta_data": [],
      "_links": {
        "self": [
          {
            "href": "https://example.com/wp-json/wc/v3/products/794"
          }
        ],
        "collection": [
          {
            "href": "https://example.com/wp-json/wc/v3/products"
          }
        ]
      }
    }
  ],
  loading: false,
  error: null
}

const productsState = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case actions.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload
      }
    case actions.GET_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state
  }
}

export default productsState
