import './formik.css'
import React from 'react';
import { Formik, Form} from 'formik';
import * as Yup from 'yup';
import {MyTextInput} from "./FormikFields";
import {useDispatch, useSelector} from "react-redux";
import {editUserDetails} from "../../actions/getUserDetails";
import {Link, useNavigate} from "react-router-dom";

const FormValidationSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    lastName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
});

// And now we can use these
const Profile = (props) => {

    const id = props.id;
    const userData = props.userData;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userDetailProfileError =  useSelector((state) => state.userDetailsState.error);
    const userDetailProfileLoad =  useSelector((state) => state.userDetailsState.loading);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    email: userData.email,
                }}
                validationSchema={FormValidationSchema}
                onSubmit={(values, { setSubmitting }) => {

                    dispatch(editUserDetails(id,values.firstName,values.lastName,values.email))
                    if(userDetailProfileError === null && userDetailProfileLoad === false){

                        window.location.replace('/myProfile');
                    }
                }}
            >
                <Form>
                    <MyTextInput
                        label="First Name "
                        name="firstName"
                        type="text"
                        placeholder="Enter First Name"
                    /><br/><br/>

                    <MyTextInput
                        label="Last Name "
                        name="lastName"
                        type="text"
                        placeholder="Enter Last Name"
                    /><br/><br/>

                    <MyTextInput
                        label="Email "
                        name="email"
                        type="text"
                        placeholder="Enter Email ID"
                    /><br/><br/>

                    <div className={"formButtonsDiv"}>
                        <button className={"wooButtons"} type="submit">Submit</button>
                        <Link to={"/myProfile"}>
                            <button className={"wooButtons"} >Cancel</button>
                        </Link>
                    </div>
                </Form>
            </Formik>
        </>
    );
};

export default Profile;