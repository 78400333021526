/**
 * @file addPaymentMethod is used as a page for user to add a payment method
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */
// import "../../styles/style.scss"
import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {ArrowBack} from "@mui/icons-material";
import SignupForm from "../../components/formik/AddPayment";
import CreditCardForm from "./components/addCreditCard";
import { Box, Typography } from '@mui/material';

export default function AddPaymentMethod(props){
    const navigate = useNavigate();
    const id = props.id;
    const paymentMethods = props.paymentMethods;
    const customerProfileId = props.customerProfileId;
    const billingInfo = props.billingInfo;
    const changePaymentMethodURL = props.changePaymentMethodURL;
    const wcsnonce = props.wcsnonce;
    return (
        <div className={"pageView"}>
            <div className={"pageWrapper"}>
                <Typography variant="h4" className={"pageTitle"}>
                    <div>
                        <button className={"backButton"} onClick={()=> navigate(-1)}>
                            <ArrowBack fontSize={"medium"} />{" "}
                            <span className={"backButtonText"}>Go Back</span>
                        </button>
                    </div>
                    Add Payment Method
                </Typography>

                <CreditCardForm id={id} paymentMethods={paymentMethods} customerProfileId={customerProfileId} billingInfo={billingInfo} changePaymentMethodURL={changePaymentMethodURL} wcsnonce={wcsnonce}/>
            </div>
        </div>
    )
}