import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { styled } from '@mui/material/styles';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const StyledDeleteForeverIcon = styled(DeleteForeverIcon)({
    color: '#0d2959',
    cursor: 'pointer',
    fontSize: '1.5em !important', // Adjust the size as needed
    "&:hover": {
        color: "#ebe9eb",
        backgroundColor: "#0d2959",
    },
    // Add other constant styles here
});


export default function CustomModal({
                                        triggerButtonText = "Open", // default text
                                        questionText = "Are you sure?",
                                        confirmButtonText = "Yes",
                                        cancelButtonText = "Cancel",
                                        onConfirm, // callback function when user confirms
                                        iconButton = false,
                                    }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleConfirmation = () => {
        if (onConfirm) onConfirm(); // call the callback if provided
        handleClose();
    };

    const renderTriggerButton = () => {
        if (iconButton) {
            return (
                <Tooltip title="Remove Owner" placement="top">
                    <IconButton onClick={handleOpen} aria-label="delete">
                        <StyledDeleteForeverIcon />
                    </IconButton>
                </Tooltip>
            );
        } else {
            return (
                <button onClick={handleOpen} className={"wooButtons"} type="button">
                    {triggerButtonText}
                </button>
            );
        }
    };

    return (
        <div>
            {renderTriggerButton()}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {questionText}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <button onClick={handleConfirmation} className={"wooButtons"} type="button">
                            {confirmButtonText}
                        </button>
                        <button onClick={handleClose} className={"wooButtons"} type="button">
                            {cancelButtonText}
                        </button>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}
