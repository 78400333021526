import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/loading";
import {CircularProgress } from '@mui/material';

const ProtectedComponent = ({ component, ...propsForComponent}) => {
    const Cp = withAuthenticationRequired(component,{
        onRedirecting: () => <Loading/>,
        loginOptions: {
            appState: {
                queryParams: new URLSearchParams(window.location.search).toString(),
            },
        },
    });
    return <Cp {...propsForComponent} />
}


export default ProtectedComponent;
