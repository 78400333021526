/**
 * getMakeDefault Api is used to Make Dafault a Saved Payment Methods List Details from WooCommerce for the user.
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import axios from 'axios'
import * as actions from './actionTypes'
import getPaymentMethods from './getPaymentMethods'
import {toastr} from "react-redux-toastr";
import { toast, ToastContainer } from 'react-toastify';

/**
 * Make Default a saved Payment Method from List details from WooCommerce REST API.
 * @endpoint "https://devc.l4sb.com/wp-json/wc/v3/paymentMethods/make-default  Middleware customization and documentation.
 *
 * @return JSON Payment Methods List Object .
 */
const getMakeDefault = (id,url) => async (dispatch, getState) => {

    return new Promise(async (resolve, reject) => {

        dispatch({ type: actions.GET_MAKE_DEFAULT_REQUEST })
        try {
            const response = await axios.get(
                '/paymentmethods/makedefault', {
                    params: {
                        url,
                        id
                    }
                }
            )
            dispatch({ type: actions.GET_MAKE_DEFAULT_SUCCESS, payload: response.data })

            if(response.status === 200){
                toast.success('Successfully Made New default Payment method!');
                localStorage.setItem('madeDefault','true');
                resolve(response.data);
                // toastr.success('Successfully Made Default', 'New default Payment method!');
            }else {
                // toastr.error('Failed making default payment method. Please try again!');
                toast.error('Failed making default payment method. Please try again!');
                reject(new Error('Failed making default payment method'));
            }

        } catch (error) {
            dispatch({ type: actions.GET_MAKE_DEFAULT_FAILURE, error })
            reject(error);
        }

    });
}

export default getMakeDefault
