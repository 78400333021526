/**
 * getHelpData Api is used to GET Help Data from wordpress or WooCommerce
 * @author Akshay Bhoite
 * @category File
 * @version 0.0.1
 */

import axios from 'axios'
import * as actions from './actionTypes'

/**
 * GET Nonced URL details from WooCommerce REST API.
 * @endpoint "/nonce-url"  Middleware customization and documentation.
 * @param id Customer Login userID to get user specific details.
 *
 * @return JSON Orders List Object .
 */
const getHelpData = (id) => async (dispatch, getState) => {

    dispatch({ type: actions.GET_HELP_DATA_REQUEST })
    try {
        const response = await axios.get(
            '/help-data', {
                params: {
                    customer: id,
                }
            }
        )
        dispatch({ type: actions.GET_HELP_DATA_SUCCESS, payload: response.data })
    } catch (error) {
        dispatch({ type: actions.GET_HELP_DATA_FAILURE, error })
    }
}

export default getHelpData;
